import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useIsFilterActive, useToggleFilter, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { COMPANY_TYPE_OPTIONS } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { useToggleLayerTab } from '../../hooks/contexts/useTabsContext';
const CompaniesSidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const toggleLayerTab = useToggleLayerTab();
    const { t } = useTranslation();
    const typeSelectedOption = extraFilters?.[filtersKey.COMPANIES]?.type || '';
    const handleTypeChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.COMPANIES}.type`, value);
        if (!isFilterActive(filtersKey.COMPANIES)) {
            toggleFilter(filtersKey.COMPANIES, true);
            toggleLayerTab({ tabId: filtersKey.COMPANIES });
        }
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.layers.companies`)} type`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('global.select.type.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('global.select.none.placeholder')} onChange={handleTypeChange} value={typeSelectedOption}>
          {COMPANY_TYPE_OPTIONS.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default CompaniesSidebarContent;
