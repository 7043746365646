import { snakeCase } from 'lodash';
import moment from 'moment';
const URL_PAPPERS_SIREN = 'https://www.pappers.fr/entreprise/xx-';
const URL_PAPPERS_NAME = 'https://www.pappers.fr/dirigeant/';
const URL_LINKEDIN_NAME = 'https://www.linkedin.com/search/results/people/';
export const getPappersLinkCompany = (siren) => {
    return URL_PAPPERS_SIREN + siren;
};
export const getPappersLinkManager = (name, firstname, date) => {
    return (URL_PAPPERS_NAME +
        firstname.replace(' ', '-') +
        '_' +
        snakeCase(name) +
        '_' +
        moment(date).format('YYYY-MM'));
};
export const getLinkedinLinkManager = (name, firstname) => {
    return (URL_LINKEDIN_NAME +
        '?firstName=' +
        firstname.replace(' ', '-') +
        '&lastName=' +
        snakeCase(name));
};
