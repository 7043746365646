import * as React from 'react';
import useUserDrawing from 'hooks/crm/useUserDrawing';
import LegendAccordion from './LegendAccordion';
import LegendLayers from './LegendLayers';
const DrawingLegendItem = ({ layerKey, legends, legendName, filtersConfig, handleClose, }) => {
    const { userDrawings } = useUserDrawing();
    return (<LegendAccordion id={layerKey} legendLabel={`${legendName} (${userDrawings?.length ?? 0})`} handleClose={handleClose} canToggle filtersConfig={filtersConfig} legends={legends}>
      <LegendLayers legends={legends}/>
    </LegendAccordion>);
};
export default DrawingLegendItem;
