import * as React from 'react';
import { memo } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import NewsList from 'components/news/List';
import { FiltersAccordionProvider } from 'context/FiltersAccordionContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMenuItemSelected, useMenuOpened, } from 'hooks/contexts/useMenuContext';
import FiltersSideBar from './FiltersSideBar/FiltersSideBar';
import { DashboardSideBar } from './DashboardSideBar';
import { PlotSearchSideBar } from './PlotSearchSideBar';
import { menuItems } from './utils';
import { VerticalMenu } from './VerticalMenu';
const Sidebar = () => {
    const menuItemSelected = useMenuItemSelected();
    const menuOpened = useMenuOpened();
    const selectedTown = useSelectedTown();
    return (<>
      <VerticalMenu />

      <Stack className="no-print" zIndex={10} boxShadow="lg" width={menuOpened ? '20%' : 0} minWidth={menuOpened ? '260px' : 0} maxWidth={menuOpened ? '290px' : 0} transition="width 0.5s" backgroundColor="colorMode.background900" spacing={0} data-cy={`vertical_menu_${menuOpened ? 'opened' : 'closed'}`}>
        <DashboardSideBar isOpen={menuOpened && menuItemSelected === menuItems.DASHBOARD}/>

        <FiltersAccordionProvider>
          <FiltersSideBar isOpen={menuOpened && menuItemSelected === menuItems.LAYERS_TREE}/>
        </FiltersAccordionProvider>

        <PlotSearchSideBar isOpen={selectedTown &&
            menuOpened &&
            menuItemSelected === menuItems.SEARCH_PLOTS}/>

        {/* News List */}
        {menuOpened && menuItemSelected === menuItems.NEWS && (<Box overflowY="scroll" backgroundColor="colorMode.background800">
            <NewsList />
          </Box>)}
      </Stack>
    </>);
};
export default memo(Sidebar);
