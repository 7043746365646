import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDrawingMarkerIcon } from 'components/layers/UserDrawingsLayer';
import { useActiveUserDrawing, useDrawingMode, useSetActiveUserDrawing, useToggleDrawingMode, } from 'hooks/contexts/useLocationContext';
import useUserDrawing from 'hooks/crm/useUserDrawing';
import { isFreeDrawing, pointExists } from 'utils/drawingUtils';
import { getContentAdvancedMarker, swktToDrawing } from 'utils/map';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from './filters';
const DRAW_COLOR = '#FF6510';
const DRAW_OPTIONS = {
    strokeColor: DRAW_COLOR,
    strokeOpacity: 1,
    strokeWeight: 3,
    fillOpacity: 0.3,
    fillColor: DRAW_COLOR,
    editable: true,
    draggable: true,
    zIndex: 10000,
    cursor: 'grab',
};
const getMarkerOptions = (map, label = '', marker = false) => {
    let labelMarker = '';
    let image;
    if (label) {
        labelMarker = label;
    }
    if (marker) {
        image = getDrawingMarkerIcon(DRAW_COLOR);
    }
    const content = getContentAdvancedMarker({
        label: labelMarker,
        labelClass: 'user-drawing-text user-drawing-text--editing',
        image: image,
    });
    return {
        gmpDraggable: true,
        zIndex: 10000,
        map: map,
        title: label,
        content: content,
    };
};
export const useDrawing = (map) => {
    const currentDrawing = useRef(null);
    const activeListener = useRef(null);
    const validatedPoints = useRef([]);
    const clickListener = useRef(null);
    const mouseMoveListener = useRef(null);
    const drawingMode = useDrawingMode();
    const toggleDrawingMode = useToggleDrawingMode();
    const activeUserDrawing = useActiveUserDrawing();
    const setActiveUserDrawing = useSetActiveUserDrawing();
    const toggleFilter = useToggleFilter();
    const onRemoveDrawing = useCallback(() => {
        setActiveUserDrawing(null);
        toggleDrawingMode(null);
    }, [setActiveUserDrawing, toggleDrawingMode]);
    const onSaveDrawing = useCallback(() => {
        toggleDrawingMode(null);
        toggleFilter(filtersKey.USER_DRAWINGS, true);
    }, [toggleDrawingMode, toggleFilter]);
    const { updateUserDrawing, createUserDrawing, removeUserDrawing } = useUserDrawing(onSaveDrawing, onRemoveDrawing);
    const [hasCurrentDrawing, setHasCurrentDrawing] = useState(false);
    const [clickedUserDrawing, setClickedUserDrawing] = useState(false);
    const changeListener = useRef(null);
    const { t } = useTranslation();
    useEffect(() => {
        removeCurrentDrawing();
        if (!activeUserDrawing) {
            if (changeListener.current) {
                google.maps.event.removeListener(changeListener.current);
                changeListener.current = null;
            }
            return;
        }
        if (drawingMode !== 'free_edit') {
            // We just clicked on a drawing, we set it as active and toggle edit mode
            setClickedUserDrawing(true);
            toggleDrawingMode('free_edit');
        }
        // Avec map.getDiv().classList ne fonctionne pas :(
        document.getElementById('map').classList.add('crosshair-cursor');
        // When active user drawing is set, and we're in free edit mode, we set it as current drawing
        const newDrawing = swktToDrawing(activeUserDrawing.swkt, DRAW_OPTIONS, getMarkerOptions(map, activeUserDrawing.note, activeUserDrawing.showTextMarker));
        setCurrentDrawing(newDrawing);
    }, [activeUserDrawing]);
    useEffect(() => {
        cleanAll();
        if (!isFreeDrawing(drawingMode) || drawingMode === null) {
            return;
        }
        startDrawing(drawingMode);
    }, [drawingMode]);
    useEffect(() => {
        return () => {
            cleanAll();
        };
    }, []);
    const setCurrentDrawing = (drawing, listener = null) => {
        setHasCurrentDrawing(true);
        currentDrawing.current = drawing;
        activeListener.current = listener;
        if (changeListener.current) {
            google.maps.event.removeListener(changeListener.current);
            changeListener.current = null;
        }
        if (drawing instanceof google.maps.marker.AdvancedMarkerElement) {
            drawing.map = map;
        }
        else if (drawing instanceof google.maps.Polygon ||
            drawing instanceof google.maps.Polyline) {
            drawing.setMap(map);
        }
        // changeListener.current = google.maps.event.addListener(drawing, 'mouseup', () => {
        //   console.log('ici')
        //   saveUpdateDrawing(drawing)
        // })
    };
    const removeCurrentDrawing = () => {
        setHasCurrentDrawing(false);
        activeListener.current?.remove();
        activeListener.current = null;
        if (currentDrawing.current instanceof google.maps.marker.AdvancedMarkerElement) {
            currentDrawing.current.map = null;
        }
        else {
            currentDrawing.current?.setMap(null);
        }
        currentDrawing.current = null;
    };
    const startDrawing = (mode) => {
        if (!map || mode === 'free_edit') {
            return;
        }
        // Avec map.getDiv().classList ne fonctionne pas :(
        document.getElementById('map').classList.add('crosshair-cursor');
        clickListener.current = map.addListener('click', (event) => {
            const latLng = event.latLng;
            const drawing = currentDrawing.current;
            // drawing started
            if (!drawing) {
                createDrawing(latLng, mode);
                return;
            }
            if (drawing instanceof google.maps.marker.AdvancedMarkerElement) {
                return;
            }
            // new point added
            if (!pointExists(validatedPoints.current, latLng)) {
                validatedPoints.current.push(latLng);
                addPoint(latLng, drawing);
                return;
            }
            // drawing completed
            saveDrawing(mode, drawing);
        });
        mouseMoveListener.current = map.addListener('mousemove', (event) => {
            if (!currentDrawing?.current) {
                return;
            }
            draw(mode, event.latLng);
        });
    };
    const createDrawing = (latLng, mode) => {
        const startingPoint = {
            lat: latLng.lat(),
            lng: latLng.lng(),
        };
        let newDrawing = null;
        validatedPoints.current = [latLng];
        if (mode === 'free_polygon') {
            newDrawing = new google.maps.Polygon({
                paths: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        else if (mode === 'free_point') {
            newDrawing = new google.maps.marker.AdvancedMarkerElement({
                position: latLng,
                ...getMarkerOptions(map, '', true),
            });
        }
        else if (mode === 'free_text') {
            newDrawing = new google.maps.marker.AdvancedMarkerElement({
                position: latLng,
                ...getMarkerOptions(map, t('toolbar.free_draw.actions.placeholder'), false),
            });
        }
        else {
            newDrawing = new google.maps.Polyline({
                path: [startingPoint],
                ...DRAW_OPTIONS,
            });
        }
        const listener = newDrawing instanceof google.maps.marker.AdvancedMarkerElement
            ? null
            : newDrawing.addListener('click', (event) => {
                // new point clicked
                if (!pointExists(validatedPoints.current, event.latLng)) {
                    validatedPoints.current.push(event.latLng);
                    addPoint(event.latLng, newDrawing);
                    return;
                }
                // drawing completed
                saveDrawing(mode, newDrawing);
            });
        setCurrentDrawing(newDrawing, listener);
        if (mode === 'free_point') {
            saveDrawing(mode, newDrawing);
        }
    };
    const draw = (mode, position) => {
        if (!currentDrawing.current ||
            currentDrawing.current instanceof google.maps.marker.AdvancedMarkerElement) {
            return;
        }
        const currentPath = currentDrawing.current.getPath();
        if (currentPath.getLength() > validatedPoints.current.length) {
            currentPath.pop();
        }
        addPoint(position, currentDrawing.current);
    };
    const saveDrawing = (mode, drawing) => {
        // Do nothing if drawing is empty or has only one point
        if ((drawing instanceof google.maps.Polyline ||
            drawing instanceof google.maps.Polygon) &&
            drawing.getPath().getLength() < 2) {
            return;
        }
        stopDrawing();
        // Avec map.getDiv().classList ne fonctionne pas :(
        document.getElementById('map').classList.remove('crosshair-cursor');
        const showTextMarker = drawing instanceof google.maps.marker.AdvancedMarkerElement &&
            hasMarker(drawing.content);
        if (activeUserDrawing && mode === 'free_edit') {
            updateUserDrawing({ id: activeUserDrawing.id, drawing, showTextMarker });
        }
        else {
            createUserDrawing({
                drawing,
                showTextMarker,
            });
        }
    };
    const addPoint = (latLng, drawing) => {
        const path = drawing.getPath();
        path.push(latLng);
    };
    const stopDrawing = useCallback(() => {
        if (clickListener.current) {
            google.maps.event.removeListener(clickListener.current);
            clickListener.current = null;
        }
        if (mouseMoveListener.current) {
            google.maps.event.removeListener(mouseMoveListener.current);
            mouseMoveListener.current = null;
        }
        if (!map) {
            return;
        }
    }, [map]);
    const cleanAll = () => {
        validatedPoints.current = [];
        if (!clickedUserDrawing) {
            setActiveUserDrawing(null);
            removeCurrentDrawing();
        }
        stopDrawing();
        setClickedUserDrawing(false);
    };
    const saveActiveDrawing = () => {
        if (!currentDrawing.current) {
            return;
        }
        saveDrawing(drawingMode, currentDrawing.current);
    };
    const updateDrawingText = (text, marker) => {
        if (!currentDrawing.current ||
            !(currentDrawing.current instanceof
                google.maps.marker.AdvancedMarkerElement)) {
            return;
        }
        const option = getMarkerOptions(map, text, marker);
        currentDrawing.current.title = text;
        currentDrawing.current.content = option.content;
    };
    const deleteActiveDrawing = () => {
        if (!activeUserDrawing) {
            return;
        }
        removeUserDrawing(activeUserDrawing.id);
    };
    const hasMarker = (content) => {
        const regex = new RegExp('<svg');
        return regex.test(content.innerHTML);
    };
    return {
        drawingMode,
        hasCurrentDrawing,
        saveActiveDrawing,
        deleteActiveDrawing,
        updateDrawingText,
        isFreeDrawingActive: isFreeDrawing(drawingMode),
        toggleDrawingMode,
        activeUserDrawing,
    };
};
