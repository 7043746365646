import * as React from 'react';
import { memo } from 'react';
import { Translation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_PLOT_TAB, } from 'analytics/useAnalytics';
import PlotButton from 'components/buttons/PlotButton';
import PlotsExportButton from 'components/buttons/PlotsExportButton';
import Table from 'components/table/Table';
import { PLOT_TAB_TYPE } from 'context/TabsContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useTabs } from 'hooks/contexts/useTabsContext';
import useUserPlotsList from 'hooks/crm/useUserPlotsList';
import usePlotsSearchDetails from 'hooks/plotsSearch/usePlotsSearchDetails';
import useCustomToast from 'hooks/useCustomToast';
import { filtersKey } from 'utils/filters';
import { formatNumberToLocale } from 'utils/formatNumber';
import { getPlotStatusLabel } from 'utils/personalization';
import { arrayHasSome } from 'utils/search';
import SelectRows from '../utils/SelectRows';
const initialSort = [
    {
        id: 'number',
        desc: false,
    },
];
const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.plots_search.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const SearchResultsPanel = () => {
    const { t, i18n } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { data, isLoading } = usePlotsSearchDetails();
    const { getInfoToast } = useCustomToast();
    const toggleFilter = useToggleFilter();
    const tabs = useTabs();
    const { addPlots } = useUserPlotsList();
    const plotTabsId = tabs
        .filter((tab) => tab.type === PLOT_TAB_TYPE)
        .map((tab) => tab?.id);
    // If we only have 1 result open directly plot in its own panel and close search results
    React.useEffect(() => {
        if (data?.length === 1 && !isLoading && plotTabsId?.includes(data[0]?.id)) {
            toggleFilter(filtersKey.PLOTS_SEARCH, false);
        }
    }, [data, isLoading, plotTabsId]);
    const addSelectedPlots = (dataFiltered) => {
        if (Object.keys(dataFiltered).length) {
            if (Object.keys(dataFiltered).length > 1500) {
                getInfoToast({
                    title: t('tabs.extra_tabs.plots_search.max_add', { count: 1500 }),
                    position: 'top',
                    id: 'plot_search_add',
                });
            }
            else {
                const dataFormatted = Object.values(dataFiltered).map((plot) => {
                    return { plotId: plot.id };
                });
                addPlots({ plots: dataFormatted });
            }
        }
    };
    const columns = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'number',
                    header: ({ table }) => {
                        return (<>
                <Stack direction="row">
                  <SelectRows {...{
                            checked: table
                                .getFilteredRowModel()
                                .rows.every((row) => row.getIsSelected()),
                            toggleRow: table.getToggleAllRowsSelectedHandler(),
                        }}/>
                  <Text as="span" variant="primary">
                    {getColumnHeader('cadastre')}
                  </Text>
                </Stack>
              </>);
                    },
                    accessorFn: (row) => {
                        return `${row.prefix} ${row.section} ${row.number}`;
                    },
                    cell: (info) => (<HStack>
              <SelectRows id="delete_plot_select" {...{
                        checked: info.row.getIsSelected(),
                        disabled: !info.row.getCanSelect(),
                        toggleRow: info.row.getToggleSelectedHandler(),
                    }}/>
              <PlotButton id={'plot_search_result'} lat={info.row.original.lat} lng={info.row.original.lng} townId={info.row.original.townId} variant="link-primary" plotId={info.row.original.id} onOpenPlot={() => {
                            trackEvent({
                                category: ANALYTICS_PLOT_TAB,
                                action: 'Afficher les parcelles Cadastre',
                            });
                        }}>
                {info.getValue()}
              </PlotButton>
            </HStack>),
                    filterFn: arrayHasSome,
                },
                {
                    accessorKey: 'surface',
                    header: () => getColumnHeader('surface'),
                    cell: (info) => formatNumberToLocale(info.getValue(), i18n.language),
                    filterFn: 'inNumberRange',
                },
                {
                    accessorKey: 'subZone',
                    header: () => getColumnHeader('sub_zone'),
                    accessorFn: (row) => {
                        if (!row.subZone)
                            return t('table.empty_column');
                        return row.subZone;
                    },
                    cell: ({ row }) => row.original.subZone,
                    filterFn: arrayHasSome,
                },
                {
                    accessorKey: 'status',
                    header: () => getColumnHeader('status'),
                    accessorFn: (row) => {
                        if (!row.status)
                            return t('table.empty_column');
                        return getPlotStatusLabel(row.status);
                    },
                    cell: ({ row }) => getPlotStatusLabel(row.original.status),
                    filterFn: arrayHasSome,
                },
                {
                    accessorKey: 'town',
                    header: () => getColumnHeader('town'),
                    accessorFn: (row) => {
                        if (!row.town)
                            return t('table.empty_column');
                        return row.town;
                    },
                    cell: ({ row }) => row.original.town,
                    filterFn: arrayHasSome,
                },
            ],
        },
    ];
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="plots-search-results" columns={columns} data={data || []} initialSort={initialSort} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }} analyticsAction="Résultat de recherche" isLoading={isLoading} renderExportButton={(dataFiltered, dataSelected, isDisabled) => (<>
            <PlotsExportButton isDisabled={isDisabled} resultsLength={dataFiltered?.length || 0} analyticDetail={'Résultat de recherche Excel'} analyticOrigin={ANALYTICS_CATEGORY_DOWNLOAD} filteredPlotsIds={dataFiltered.map((plot) => plot.id)}/>
            <Button leftIcon={<AddIcon />} size="sm" variant="primary" isDisabled={dataSelected?.length === 0 || isLoading} onClick={() => addSelectedPlots(dataSelected)}>
              {t('global.add_plot')}
            </Button>
          </>)}/>
    </Stack>);
};
export default memo(SearchResultsPanel);
