import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOBaseFilter } from 'types/api';
const IOBuildingPermitLayer = iots.type({
    layers: iots.array(IOBaseFilter),
});
const IOBuildingPermitLayers = iots.record(iots.string, IOBuildingPermitLayer);
export const fetchBuildingPermit = (townId) => () => get(iots.array(IOBuildingPermitLayers), `legacy/${townId}/building-permit-new-market-layers`);
export const QUERY_KEY = 'building-permit-layers';
export const getQueryKey = (townId) => [QUERY_KEY, townId];
const usePlotBuildingPermitLayers = (townId) => {
    const { data: buildingPermitLayers, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(townId),
        queryFn: fetchBuildingPermit(townId),
        enabled: !!townId,
    });
    return { buildingPermitLayers, isLoading };
};
export default usePlotBuildingPermitLayers;
