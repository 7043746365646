import * as React from 'react';
import { Button, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
export const IOBankruptCompaniesOwned = iots.type({
    siren: iots.string,
    countOwnedPlots: iots.number,
});
const BankruptCompaniesOwnedPlotsButton = ({ data }) => {
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const countOwnedPlots = data.countOwnedPlots;
    const handleClick = () => {
        updateExtraFilter(filtersKey.COMPANY_OWNED_PLOTS, {
            sirens: [data.siren],
        });
        toggleLayerTab({ tabId: filtersKey.COMPANY_OWNED_PLOTS });
    };
    return (<>
      {countOwnedPlots === 0 || data.siren === '' ? (<Text align="right">{countOwnedPlots}</Text>) : (<Button size="sm" width="full" justifyContent="flex-end" padding={0} minWidth={0} onClick={handleClick} variant="link-primary">
          {countOwnedPlots}
        </Button>)}
    </>);
};
export default BankruptCompaniesOwnedPlotsButton;
