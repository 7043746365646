import * as React from 'react';
import { useState } from 'react';
import { createContext } from 'use-context-selector';
import { localStorageSetGet } from 'utils/localStorage';
export const TABS_VIEW_FULL = 'full';
export const TABS_VIEW_HALF = 'half';
export const TABS_VIEW_CLOSED = 'closed';
export const TABS_VIEW_FULL_VERTICAL = 'full_vertical';
export const TABS_VIEW_HALF_VERTICAL = 'half_vertical';
export const TABS_VIEW_CLOSED_VERTICAL = 'closed_vertical';
const TABS_VIEWS = [
    TABS_VIEW_CLOSED,
    TABS_VIEW_HALF,
    TABS_VIEW_FULL,
    TABS_VIEW_FULL_VERTICAL,
    TABS_VIEW_HALF_VERTICAL,
    TABS_VIEW_CLOSED_VERTICAL,
];
export const STREET_VIEW_MODE_FULL = 'full';
export const STREET_VIEW_MODE_FIFTY = 'fifty';
const STREET_VIEWS = [STREET_VIEW_MODE_FULL, STREET_VIEW_MODE_FIFTY];
export const LayoutContext = createContext(null);
export const LayoutProvider = ({ children }) => {
    const localStorage = localStorageSetGet('kelfoncier-app-display');
    const defaultLocalStorage = () => {
        return 'vertical';
        if (!localStorage.get() ||
            (localStorage.get() !== 'horizontal' && localStorage.get() !== 'vertical')) {
            return 'vertical';
        }
        return localStorage.get();
    };
    // StreetView Mode
    const [streetViewMode, setStreetViewMode] = useState(null);
    const toggleStreetView = () => {
        setStreetViewMode(streetViewMode === STREET_VIEW_MODE_FIFTY
            ? STREET_VIEW_MODE_FULL
            : STREET_VIEW_MODE_FIFTY);
    };
    // Layout Type (Vertical or Horizontal)
    const [layout, setLayout] = React.useState(defaultLocalStorage());
    // Tabs Position (deployed / half/ reduced)
    const [tabsView, setTabsView] = useState(layout === 'vertical' ? TABS_VIEW_HALF_VERTICAL : TABS_VIEW_HALF);
    const switchDisplay = () => {
        const newLayout = layout == 'vertical' ? 'horizontal' : 'vertical';
        setLayout(newLayout);
        localStorage.set(newLayout);
        newLayout === 'vertical'
            ? setTabsView(TABS_VIEW_HALF_VERTICAL)
            : setTabsView(TABS_VIEW_HALF);
    };
    const setLayoutFull = () => {
        setTabsView(layout === 'vertical' ? TABS_VIEW_FULL_VERTICAL : TABS_VIEW_FULL);
    };
    const setLayoutHalf = () => {
        setTabsView(layout === 'vertical' ? TABS_VIEW_HALF_VERTICAL : TABS_VIEW_HALF);
    };
    const closeLayout = () => {
        setTabsView(layout === 'vertical' ? TABS_VIEW_CLOSED_VERTICAL : TABS_VIEW_CLOSED);
    };
    const isLayoutClosed = React.useMemo(() => [TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_CLOSED]?.includes(tabsView), [tabsView]);
    const isLayoutFull = React.useMemo(() => [TABS_VIEW_FULL_VERTICAL, TABS_VIEW_FULL]?.includes(tabsView), [tabsView]);
    const deployLayout = () => {
        if (isLayoutClosed)
            setLayoutHalf();
        else
            setLayoutFull();
    };
    const reduceLayout = () => {
        if (isLayoutFull)
            setLayoutHalf();
        else
            closeLayout();
    };
    const toggleLayout = () => {
        if (isLayoutFull)
            reduceLayout();
        else
            deployLayout();
    };
    // Tabs Panel Dimensions
    const tabsHeight = React.useMemo(() => {
        if (layout === 'vertical') {
            return 'calc(100vh - 115px)';
        }
        return tabsView === TABS_VIEW_FULL
            ? 'calc(100vh - 115px)'
            : tabsView === TABS_VIEW_CLOSED
                ? '0vh'
                : '50vh';
    }, [tabsView, layout]);
    const tabsWidth = React.useMemo(() => {
        if (layout === 'horizontal') {
            return '100%';
        }
        else {
            if (tabsView === TABS_VIEW_CLOSED_VERTICAL) {
                return '88px';
            }
            if (tabsView === TABS_VIEW_HALF_VERTICAL) {
                return '43%';
            }
            return '100%';
        }
    }, [tabsView, layout]);
    // Map Panel Dimensions
    const mapHeight = React.useMemo(() => {
        if (layout === 'vertical') {
            return '100%';
        }
        return tabsView === TABS_VIEW_FULL
            ? '100%'
            : tabsView === TABS_VIEW_CLOSED
                ? '100%'
                : '70%';
    }, [tabsView, layout]);
    const mapWidth = React.useMemo(() => {
        if (layout === 'horizontal') {
            return '100%';
        }
        if (tabsWidth) {
            if (tabsWidth.match('px')) {
                return 'calc(100% - ' + tabsWidth + ')';
            }
            return `${100 - +tabsWidth?.replace('%', '')}%`;
        }
        return '100%';
    }, [layout, tabsWidth]);
    return (<LayoutContext.Provider value={{
            layout,
            switchDisplay,
            tabsView,
            setTabsView,
            tabsWidth,
            tabsHeight,
            mapHeight,
            mapWidth,
            deployLayout,
            reduceLayout,
            setLayoutHalf,
            closeLayout,
            toggleLayout,
            streetViewMode,
            setStreetViewMode,
            toggleStreetView,
            isNarrowView: layout === 'vertical' && tabsView === TABS_VIEW_HALF_VERTICAL,
        }}>
      {children}
    </LayoutContext.Provider>);
};
export default LayoutContext;
