import * as React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading, HStack, Icon, Image, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Text, } from '@chakra-ui/react';
import { t } from 'i18next';
import { getDocumentAndOpen } from 'api/client';
import Table from 'components/table/Table';
import { columns as historyColumns } from 'components/tabs/plots/PlotSections/crm/HistoryList';
import { useChangeLayout } from 'hooks/contexts/useLayoutContext';
import { useSetLocatedProject } from 'hooks/contexts/useLocationContext';
import useTeamProject from 'hooks/crm/useTeamProject';
import useTeamProjectContacts from 'hooks/crm/useTeamProjectContacts';
import useTeamProjectFiles from 'hooks/crm/useTeamProjectFiles';
import useTeamProjectHistory from 'hooks/crm/useTeamProjectHistory';
import useTeamProjectNotes from 'hooks/crm/useTeamProjectNotes';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import useTeamImages from 'hooks/useTeamImages';
import FileIcon from 'images/icons/File';
import PlotIcon from 'images/icons/Plot';
import PwdIcon from 'images/icons/Pwd';
import SurfaceIcon from 'images/icons/Surface';
import { filtersKey } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { getPlotStatusLabel, getProjectStatusLabel, } from 'utils/personalization';
import { numberWithSpaces } from 'utils/text';
const getColumnHeader = (table, column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.project.columns.${table}.${column}`)}</Text>)}
  </Translation>);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'addedAt',
                header: () => getColumnHeader('plots', 'added_at'),
                cell: ({ getValue }) => formatDate(getValue(), 'extended'),
            },
            {
                accessorKey: 'plotNumber',
                header: () => getColumnHeader('plots', 'plots'),
                cell: ({ getValue }) => {
                    return getValue();
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'address',
                header: () => getColumnHeader('plots', 'address'),
                cell: ({ getValue }) => {
                    return getValue();
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'surface',
                header: () => getColumnHeader('plots', 'surface'),
                cell: ({ getValue }) => {
                    return getValue();
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'zone',
                header: () => getColumnHeader('plots', 'zone'),
                cell: ({ getValue }) => {
                    return getValue();
                },
                filterFn: 'arrIncludesSome',
            },
            {
                id: 'plotStatus',
                accessorFn: (row) => getPlotStatusLabel(row.status),
                header: () => getColumnHeader('plots', 'status'),
                cell: (info) => {
                    return getPlotStatusLabel(info.row.original.status);
                },
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const CONTACTS_COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'titleStr',
                accessorFn: (row) => {
                    if (!row.titleStr)
                        return t('table.empty_column');
                    return row.titleStr;
                },
                header: () => getColumnHeader('contacts', 'titleStr'),
                cell: (info) => (<Text align="center">{info.row.original.titleStr}</Text>),
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'lastname',
                header: () => getColumnHeader('contacts', 'lastname'),
                accessorFn: (row) => {
                    if (!row.lastname)
                        return t('table.empty_column');
                    return row.lastname;
                },
                cell: (info) => {
                    return info.row.original.lastname;
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'firstname',
                header: () => getColumnHeader('contacts', 'firstname'),
                accessorFn: (row) => {
                    if (!row.firstname)
                        return t('table.empty_column');
                    return row.firstname;
                },
                cell: (info) => {
                    return info.row.original.firstname;
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'comment',
                header: () => getColumnHeader('contacts', 'comment'),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                enableSorting: false,
            },
        ],
    },
];
const TeamProjectModal = ({ project, triggerItem = (onOpen) => (<Button variant="link-primary" onClick={onOpen}>
      {project?.label}
    </Button>), isOpen, onOpen, onClose, }) => {
    const { t } = useTranslation();
    const navigateToTown = useNavigateToTown();
    const setLocatedProject = useSetLocatedProject();
    const { hasDashboardItem } = useSiteConfiguration();
    const { teamProject, isLoadingTeamProject } = useTeamProject(isOpen, project.projectTownId, project.projectId);
    const { reduceLayout } = useChangeLayout();
    const { documents, photos } = useTeamProjectFiles(isOpen, project.projectTownId, project.projectId);
    const { data: teamProjectHistory, isInitialLoading: isHistoryLoading } = useTeamProjectHistory(isOpen, project.projectId);
    const { data: teamProjectNotes } = useTeamProjectNotes(isOpen, project.projectId);
    const { data: photosFiles, isLoading: isPhotoFilesLoading } = useTeamImages(project?.userEmail, photos && photos.map((photo) => photo.id));
    const { teamProjectContacts, isLoadingTeamProjectContacts } = useTeamProjectContacts(isOpen, project.projectId);
    const locateProject = () => {
        navigateToTown({
            lat: project.lat.toString(),
            lng: project.lng.toString(),
            centerMapOnLat: project.lat.toString(),
            centerMapOnLng: project.lng.toString(),
        });
        reduceLayout();
        setLocatedProject(project.projectId);
        onClose();
    };
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" variant="h3">
              {t('modals.team_project.title')} {teamProject?.username}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={5}>
              <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4}>
                <Box>
                  <Text as="div" textStyle="paragraph" display="flex" fontWeight="bold">
                    {t(`tabs.project.name`)}
                  </Text>
                  <Text textStyle="paragraph" fontWeight={500} data-cy="team_project_label">
                    {teamProject?.label}
                  </Text>
                </Box>
                <Box>
                  <Text as="div" textStyle="paragraph" display="flex" fontWeight="bold">
                    {t(`tabs.project.status`)}
                  </Text>
                  <Text textStyle="paragraph" fontWeight={500} data-cy="team_project_status">
                    {getProjectStatusLabel(teamProject?.status)}
                  </Text>
                </Box>
                <Box>
                  {teamProject?.rightToBuild !== 0 && (<>
                      <Text as="div" textStyle="paragraph" display="flex" fontWeight="bold">
                        {t(`tabs.project.rightToBuild`)}
                      </Text>
                      <Text textStyle="paragraph" fontWeight={500} data-cy="team_project_rightToBuild">
                        {numberWithSpaces(teamProject?.rightToBuild)}
                      </Text>
                    </>)}
                </Box>
                <Box>
                  {teamProject?.lots !== 0 && (<>
                      <Text as="div" textStyle="paragraph" display="flex" fontWeight="bold">
                        {t(`tabs.project.lots`)}
                      </Text>
                      <Text textStyle="paragraph" fontWeight={500} data-cy="team_project_lots">
                        {numberWithSpaces(teamProject?.lots)}
                      </Text>
                    </>)}
                </Box>
                <Box>
                  {teamProject?.turnover !== 0 && (<>
                      <Text as="div" textStyle="paragraph" display="flex" fontWeight="bold">
                        {t(`tabs.project.turnover`)}
                      </Text>
                      <Text textStyle="paragraph" fontWeight={500} data-cy="team_project_turnover">
                        {numberWithSpaces(teamProject?.turnover)}
                      </Text>
                    </>)}
                </Box>
              </SimpleGrid>
              <Box mt={2}>
                <Button variant="outline" onClick={locateProject}>
                  {t(`tabs.project.actions.locate_project`)}
                </Button>
              </Box>
              <SimpleGrid columns={{ sm: 2 }} spacing={4} mt={5}>
                <Box display="flex" alignItems="center">
                  <Icon as={PlotIcon} fill="colorMode.font900" width="20px" height="20px"/>
                  {!isLoadingTeamProject && (<Text as="span" marginLeft={2} data-cy="plot_form_plot_number">
                      <strong>
                        {numberWithSpaces(teamProject?.plots.length)}
                      </strong>{' '}
                      {t('tabs.project.plot', {
                count: teamProject?.plots.length,
            })}
                    </Text>)}
                </Box>
                <Box display="flex" alignItems="center">
                  <Icon as={SurfaceIcon} fill="colorMode.font900" width="20px" height="20px"/>
                  {!isLoadingTeamProject && (<Text as="span" marginLeft={2} fontWeight="bold">
                      {numberWithSpaces(teamProject?.groundSurface)} m²
                    </Text>)}
                </Box>
              </SimpleGrid>
            </Box>

            {hasDashboardItem(filtersKey.USER_PLOTS) && (<Box width="full" mb={5}>
                <Heading as="h4" variant="h4" pb={2} pt={2}>
                  {t('tabs.project.plots')}
                </Heading>

                <Table id={`project-${project.projectId}-plots`} columns={COLUMNS} data={teamProject?.plots || []} isLoading={isLoadingTeamProject} initialSort={[{ id: 'addedAt', desc: true }]} initialColumnVisibility={{
                addedAt: false,
            }} withExport={false}/>
              </Box>)}

            <Box mb={5}>
              <Heading as="h4" variant="h4" pb={2} pt={2}>
                {t('tabs.project.team_contacts')}
              </Heading>

              <Table id={`table-team-projects-${project?.projectId}-contacts`} columns={CONTACTS_COLUMNS} data={teamProjectContacts || []} isLoading={isLoadingTeamProjectContacts} withExport={false}/>
            </Box>

            <Box mb={5}>
              <Heading as="h4" variant="h4" pb={2} pt={2}>
                {t('tabs.document.title')}
              </Heading>
              {documents?.length > 0 ? (<Box width="full">
                  {documents.map((document) => {
                return (<HStack key={document.id} width="full" justifyContent="space-between" backgroundColor="colorMode.base" borderRadius="md" padding={4} marginTop={2} data-cy="plot_crm_document_file">
                        <HStack alignItems="center">
                          <Icon as={FileIcon} fill="red.500"/>
                          <Text textStyle="paragraph" fontWeight={500} color="colorMode.font900" data-cy="document_plot_name">
                            {document.name}
                          </Text>
                          {project.projectId &&
                        document.plotId != 0 &&
                        document.plotNumber && (<Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                                ({document.plotNumber})
                              </Text>)}
                        </HStack>
                        <HStack>
                          <Button size="sm" color="colorMode.font800" leftIcon={<PwdIcon fill="currentColor"/>} onClick={() => getDocumentAndOpen(document.url, document.name)}>
                            {t('tabs.document.view_btn')}
                          </Button>
                        </HStack>
                      </HStack>);
            })}
                </Box>) : (<Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                  {t('modals.team_plot.no_documents')}
                </Text>)}
            </Box>

            <Box mb={5}>
              <Heading as="h4" variant="h4" pb={2} pt={2}>
                {t('tabs.photo.title')}
              </Heading>
              {photos?.length > 0 ? (<Box display="flex" flexWrap="wrap" width="full">
                  {photos?.length > 0 &&
                photos.map((photo) => {
                    return isPhotoFilesLoading ? (<Spinner key="photo"/>) : (<Link key={photo.id} display="block" href={photosFiles?.[photo.id]} isExternal download={photo.name} maxWidth="120px" marginRight={2}>
                          <Flex direction="column" key={photo.id} borderRadius="lg" overflow="hidden" position="relative">
                            <Image src={photosFiles?.[photo.id]} alt={photo.name} height="120px" border="1px solid gray" borderTopEndRadius="lg" borderTopLeftRadius="lg" objectFit="cover"/>

                            <Box padding={3} backgroundColor="colorMode.base">
                              <Text textStyle="small" noOfLines={1} fontWeight={500}>
                                {photo.name}
                              </Text>
                            </Box>
                          </Flex>
                        </Link>);
                })}
                </Box>) : (<Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                  {t('modals.team_plot.no_photos')}
                </Text>)}
            </Box>

            <Box width="full" mb={5}>
              <Heading as="h4" variant="h4" pb={2} pt={2}>
                {t('tabs.project.historic')}
              </Heading>

              <Table id={`table-project-${project.projectId}-history`} columns={historyColumns} data={teamProjectHistory || []} isLoading={isHistoryLoading} initialSort={[{ id: 'date', desc: true }]} withExport={false}/>
            </Box>

            {teamProjectNotes && (<Box>
                <Heading as="h4" variant="h4" pb={2} pt={2}>
                  {t('modals.team_plot.notes')}
                </Heading>
                {teamProjectNotes.length <= 0 && (<Text textStyle="paragraph" fontWeight={500} color="colorMode.font900">
                    {t('modals.team_plot.no_notes')}
                  </Text>)}
                {teamProjectNotes.map((note) => {
                return (<Box key={note.id} backgroundColor="colorMode.base" padding={2} width="full" borderRadius="md">
                      <Text textStyle="paragraph">
                        {t('tabs.plots.section.my_crm.note.from')}{' '}
                        {formatDate(note.createdAt, 'extended')}
                        {' : '}
                        <Text as="span" fontWeight={500}>
                          {note.note}
                        </Text>
                      </Text>
                    </Box>);
            })}
              </Box>)}
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              {t('modals.team_project.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default TeamProjectModal;
