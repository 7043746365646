import { useCallback, useMemo } from 'react';
import { tableFiltersKey } from 'context/FiltersContext';
import { useTableFilters } from 'hooks/contexts/useFiltersContext';
import useUserPlotsList from 'hooks/crm/useUserPlotsList';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getPlotStatusColor, getPlotStatusLabel, getPlotStatusOrder, hasPlotStatus, } from 'utils/personalization';
const useUserPlotsLayer = () => {
    const { data: userPlots } = useUserPlotsList();
    const tableFilters = useTableFilters();
    const statusFilter = tableFilters[tableFiltersKey.PLOT_STATUS];
    const openPlotFromMap = useOpenPlotFromMap();
    const plotLayerItems = useMemo(() => {
        if (!userPlots) {
            return [];
        }
        const items = {};
        userPlots?.forEach((plot) => {
            if (statusFilter && !statusFilter.includes(plot.plotStatus)) {
                return;
            }
            items[plot.plotId] = {
                id: plot.plotId,
                layerKey: filtersKey.USER_PLOTS,
                type: VECTOR_TYPES.polygon,
                swkt: plot.swkt,
                opacity: 0.5,
                color: getPlotStatusColor(plot.plotStatus),
            };
            if (hasPlotStatus(plot.plotStatus)) {
                items[plot.plotId] = {
                    ...items[plot.plotId],
                    legend: getPlotStatusLabel(plot.plotStatus),
                    legendOrder: getPlotStatusOrder(plot.plotStatus),
                };
            }
        });
        return Object.values(items);
    }, [userPlots, statusFilter]);
    const handleClick = useCallback((item) => {
        // On ne gère pas le cas où il n'y a aucune ville de sélectionné
        // car cela arrive uniquement sur la première carte
        openPlotFromMap(item);
    }, []);
    createLayersHook({
        providedData: plotLayerItems,
        onClick: handleClick,
    })(filtersKey.USER_PLOTS);
};
const UserPlotsLayer = () => {
    useUserPlotsLayer();
    return null;
};
export default UserPlotsLayer;
