import * as React from 'react';
import { useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import Table from 'components/table/Table';
import SelectRows from 'components/tabs/utils/SelectRows';
import useContactList from 'hooks/crm/useContactList';
import { getContactTypeFromCode } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { inDateRange } from 'utils/search';
import { STEP_UPLOAD_WORD } from '../ImportPublipostageForm';
const getColumnHeader = (column) => (<Translation>
    {(t) => <Text align="center">{t(`tabs.contact.columns.${column}`)}</Text>}
  </Translation>);
const initialSort = [
    {
        id: 'updatedAt',
        desc: true,
    },
];
const ContactListPublipostage = ({ children, setStep, setContactIds, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { contacts, isInitialLoading: isLoading } = useContactList();
    const [initialSelect, setInitialSelect] = useState(false);
    const [contactIdsSelected, setContactIdsSelected] = useState([]);
    const [, setNoContactSelected] = useState(true);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoContactSelected(isNoneSelected);
        setInitialSelect(false);
        let contactIds = rows.map((row) => {
            return row.id;
        });
        if (isNoneSelected) {
            contactIds = [];
        }
        setContactIds(contactIds);
        setContactIdsSelected(contactIds);
        if (isAllSelected) {
            setNoContactSelected(!isAllSelected);
        }
    };
    const checkIncompleteContactSelected = () => {
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Sélection des contacts',
        });
        setStep(STEP_UPLOAD_WORD);
    };
    const columns = React.useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'lastname',
                        header: ({ table }) => {
                            return (<Stack direction="row">
                  <SelectRows {...{
                                checked: table
                                    .getFilteredRowModel()
                                    .rows.every((row) => row.getIsSelected()),
                                toggleRow: table.getToggleAllRowsSelectedHandler(),
                                setNoItemIsSelected: setNoContactSelected,
                            }}/>
                  {getColumnHeader('lastname')}
                </Stack>);
                        },
                        cell: (info) => {
                            return (<Stack direction="row">
                  <SelectRows {...{
                                checked: info.row.getIsSelected(),
                                disabled: !info.row.getCanSelect(),
                                toggleRow: info.row.getToggleSelectedHandler(),
                            }}/>
                  <Text>{info.getValue()}</Text>
                </Stack>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'firstname',
                        header: () => getColumnHeader('firstname'),
                        accessorFn: (row) => {
                            if (!row.firstname)
                                return t('table.empty_column');
                            return row.firstname;
                        },
                        cell: ({ row }) => row.original.firstname,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'titleStr',
                        header: () => getColumnHeader('title'),
                        accessorFn: (row) => {
                            if (!row.titleStr)
                                return t('table.empty_column');
                            return row.titleStr;
                        },
                        cell: ({ row }) => (<Text align="center">{row.original.titleStr}</Text>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'company',
                        header: () => getColumnHeader('company'),
                        accessorFn: (row) => {
                            if (!row.company)
                                return t('table.empty_column');
                            return row.company;
                        },
                        cell: ({ row }) => row.original.company,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'mobile',
                        header: () => getColumnHeader('mobile'),
                        accessorFn: (row) => {
                            if (!row.mobile)
                                return t('table.empty_column');
                            return row.mobile;
                        },
                        cell: ({ row }) => row.original.mobile,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'landline',
                        header: () => getColumnHeader('landline'),
                        accessorFn: (row) => {
                            if (!row.landline)
                                return t('table.empty_column');
                            return row.landline;
                        },
                        cell: ({ row }) => row.original.landline,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'primaryEmail',
                        header: () => getColumnHeader('primary_email'),
                        accessorFn: (row) => {
                            if (!row.primaryEmail)
                                return t('table.empty_column');
                            return row.primaryEmail;
                        },
                        cell: ({ row }) => (<Link variant="primary" href={`mailto:${row.original.primaryEmail}`} fontWeight={500}>
                {row.original.primaryEmail}
              </Link>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'address',
                        header: () => getColumnHeader('address'),
                        accessorFn: (row) => {
                            if (!row.address)
                                return t('table.empty_column');
                            return row.address;
                        },
                        cell: ({ row }) => row.original.address,
                        filterFn: 'arrIncludesSome',
                        meta: {
                            minW: '150px',
                        },
                    },
                    {
                        accessorKey: 'postalCode',
                        header: () => getColumnHeader('postal_code'),
                        accessorFn: (row) => {
                            if (!row.postalCode)
                                return t('table.empty_column');
                            return row.postalCode;
                        },
                        cell: ({ row }) => row.original.postalCode,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        header: () => getColumnHeader('town'),
                        accessorFn: (row) => {
                            if (!row.town)
                                return t('table.empty_column');
                            return row.town;
                        },
                        cell: ({ row }) => row.original.town,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'type',
                        accessorFn: (row) => {
                            return row.type
                                .map((type) => {
                                return getContactTypeFromCode(type);
                            })
                                .join(', ');
                        },
                        header: () => getColumnHeader('type'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'vendor',
                        accessorFn: (row) => row.vendor === 1 ? t('global.yes') : t('global.no'),
                        header: () => getColumnHeader('vendor'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'npr',
                        accessorFn: (row) => row.npr === 1 ? t('global.yes') : t('global.no'),
                        header: () => getColumnHeader('npr'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'npai',
                        accessorFn: (row) => (row.npai ? t('global.yes') : t('global.no')),
                        header: () => getColumnHeader('npai'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'updatedAt',
                        header: () => getColumnHeader('updated_at'),
                        cell: (info) => formatDate(info.getValue(), 'extendedToShort'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                ],
            },
        ];
    }, [contacts]);
    return (<Stack height="100%">
      <Table handleRowSelection={handleRowSelection} initialSelect={initialSelect} id="table_contacts" initialSort={initialSort} columns={columns} data={contacts || []} isLoading={isLoading} emptyMessage={t(`tabs.contact.no_result`)} exportTitle={t('tabs.contact.title')} withExport={false} analytics={{
            getColumnName: (id) => t(`tabs.contact.columns.${id}`),
            tableName: t('tabs.contact.title'),
        }}/>
      <HStack marginTop={2} justifyContent="flex-end">
        <Box> {children} </Box>
        <Box>
          <Button type="submit" size="sm" variant="primary" isLoading={isLoading} disabled={contactIdsSelected.length === 0} onClick={checkIncompleteContactSelected} loadingText={t('global.status.selecting')}>
            {t('global.next')}
          </Button>
        </Box>
      </HStack>
    </Stack>);
};
export default ContactListPublipostage;
