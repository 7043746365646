import * as React from 'react';
import { memo, useRef, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import * as iots from 'io-ts';
import PersonalizationPlotStatus from 'components/tabs/personalization/PersonalizationSections/PersonalisationPlotStatus';
import PanelNavigation from 'components/tabs/TabsNavigation/PanelNavigation';
import useUserPersonalization from 'hooks/personalization/useUserPersonalization';
import { TOOLBAR_HEIGHT } from 'utils/plots';
import PersonalizationProjectStatus from './PersonalizationSections/PersonalisationProjectStatus';
export const PERSONALIZATION_PLOT_STATUS = 'plot_status';
export const PERSONALIZATION_PROJECT_STATUS = 'project_status';
const IOPersonalizationTabTab = iots.union([
    iots.literal(PERSONALIZATION_PLOT_STATUS),
    iots.literal(PERSONALIZATION_PROJECT_STATUS),
], 'IOSiteConfigurationPlotTab');
const SECTIONS = {
    [PERSONALIZATION_PLOT_STATUS]: PersonalizationPlotStatus,
    [PERSONALIZATION_PROJECT_STATUS]: PersonalizationProjectStatus,
};
const UserPersonalizationPanel = () => {
    const { userPersonalization } = useUserPersonalization();
    const scrollRef = useRef();
    const [selectedSection, setSelectedSection] = useState(PERSONALIZATION_PLOT_STATUS);
    const allowedSections = Object.keys(SECTIONS).filter((section) => {
        switch (section) {
            case PERSONALIZATION_PLOT_STATUS:
                return userPersonalization.plotStatus;
            case PERSONALIZATION_PROJECT_STATUS:
                return userPersonalization.projectStatus;
            default:
                return false;
        }
    });
    const handleChangeSection = (section) => {
        setSelectedSection(section);
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };
    const Component = SECTIONS[selectedSection] || undefined;
    return (<>
      <HStack width="100%" justifyContent="space-between" bg="white">
        {Object.keys(SECTIONS).length > 1 && (<PanelNavigation isLoading={false} allowedSections={allowedSections} handleChangeSection={handleChangeSection} panelTitle="personalization" trackEventCategory={ANALYTICS_PERSONALIZATION} section={selectedSection}/>)}
      </HStack>
      <Box ref={scrollRef} height={`calc(100% - ${TOOLBAR_HEIGHT})`} overflowY="auto" width="100%">
        {Component && <Component />}
      </Box>
    </>);
};
export default memo(UserPersonalizationPanel);
