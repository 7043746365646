import * as React from 'react';
import { HStack, Link } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import LogoLinkedin from 'images/logo/linkedIn.svg';
import LogoPappersLight from 'images/logo/pappers_light.png';
import { getLinkedinLinkManager, getPappersLinkManager, } from 'utils/externalLink';
export const ManagerLink = ({ name, firstname, date, analyticOrigin = ANALYTICS_OPEN, analyticDetail, }) => {
    const { trackEvent } = useAnalytics();
    return (<HStack spacing={1}>
      <Link href={getPappersLinkManager(name, firstname, date)} textStyle="small" variant="secondary" isExternal onClick={() => {
            if (analyticOrigin && analyticDetail) {
                trackEvent({
                    category: analyticOrigin,
                    action: 'Pappers - ' + analyticDetail,
                });
            }
        }} lineHeight="1em" verticalAlign="middle">
        <img width={14} src={LogoPappersLight} alt="Pappers" title="Pappers"/>
      </Link>
      <Link href={getLinkedinLinkManager(name, firstname)} textStyle="small" variant="secondary" isExternal onClick={() => {
            if (analyticOrigin && analyticDetail) {
                trackEvent({
                    category: analyticOrigin,
                    action: 'Linkedin - ' + analyticDetail,
                });
            }
        }} lineHeight="1em" verticalAlign="middle">
        <img width={14} src={LogoLinkedin} alt="Linkedin" title="Linkedin"/>
      </Link>
    </HStack>);
};
export default ManagerLink;
