import * as React from 'react';
import { registerLocale } from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, matchRoutes, Navigate, Outlet, RouterProvider, useLocation, useSearchParams, } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider, } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { fr } from 'date-fns/locale';
import ErrorPage from 'ErrorPage';
import ForgottenPasswordPage from 'ForgottenPasswordPage';
import Homepage from 'homepage';
import LoginPage from 'LoginPage';
import qs from 'qs';
import ResetPasswordPage from 'ResetPasswordPage';
import RoutesPage from 'RoutesPage';
import Styleguide from 'styleguide';
import { AuthProvider } from 'context/AuthContext';
import { FiltersProvider } from 'context/FiltersContext';
import { LayersTreeProvider } from 'context/LayersTreeContext';
import { LayoutProvider } from 'context/LayoutContext';
import { LegendProvider } from 'context/LegendContext';
import { LocationProvider } from 'context/LocationContext';
import { MapProvider } from 'context/MapContext';
import { MenuProvider } from 'context/MenuContext';
import { PlotSearchProvider } from 'context/PlotSearchContext';
import { SecondaryPlotsProvider } from 'context/SecondaryPlotsContext';
import { TabsProvider } from 'context/TabsContext';
import { useIsAuthenticated } from 'hooks/contexts/useAuthContext';
import useAxiosConfig from 'hooks/useAxiosConfig';
import usePersistentColorMode from 'hooks/usePersistentColorMode';
import matomoInstance from 'utils/matomo';
import extendedTheme from 'utils/theme';
// import '../wdyr'
import './i18n';
import { ModalProvider } from './context/ModalContext';
import 'styles/app.css';
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN_FRONT,
        integrations: [Sentry.browserTracingIntegration()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const DEFAULT_STALE_TIME = 1000 * 60 * 60 * 8; // 8 hours
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: DEFAULT_STALE_TIME,
        },
    },
    queryCache: new QueryCache({
        onError: (error, query) => {
            if (query.meta?.onError) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                query.meta.onError(error);
            }
        },
        onSuccess: (data, query) => {
            if (query.meta?.onSuccess) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                query.meta.onSuccess(data);
            }
        },
    }),
});
registerLocale('fr', fr);
const renderMapError = (status) => {
    return <h1>{status}</h1>;
};
const AppContent = () => {
    useAxiosConfig();
    usePersistentColorMode();
    return <Outlet />;
};
const App = () => {
    return (<MatomoProvider value={matomoInstance}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={extendedTheme} portalZIndex={40}>
          <AuthProvider>
            <LocationProvider>
              <LayoutProvider>
                <FiltersProvider>
                  <LegendProvider>
                    <LayersTreeProvider>
                      <MapProvider>
                        <MenuProvider>
                          <SecondaryPlotsProvider>
                            <TabsProvider>
                              <PlotSearchProvider>
                                <GoogleMapsWrapper apiKey={process.env.GOOGLE_MAPS_API_KEY} render={renderMapError} libraries={['places', 'drawing', 'marker']}>
                                  <ModalProvider>
                                    <AppContent />
                                  </ModalProvider>
                                </GoogleMapsWrapper>
                              </PlotSearchProvider>
                            </TabsProvider>
                          </SecondaryPlotsProvider>
                        </MenuProvider>
                      </MapProvider>
                    </LayersTreeProvider>
                  </LegendProvider>
                </FiltersProvider>
              </LayoutProvider>
            </LocationProvider>
          </AuthProvider>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left"/>
      </QueryClientProvider>
    </MatomoProvider>);
};
export const FORGOTTEN_PASSWORD_URL = '/forgotten-password';
export const LEGACY_RESET_PASSWORD_URL = 'resetpassword.php';
export const RESET_PASSWORD_URL = '/reset-password';
export const HOME_URL = '';
export const LOGIN_URL = '/login';
export const TOWN_URL = 'ville/:lat/:lng';
export const PLOT_URL = 'parcelle/:townId/:lat/:lng/:plotId';
const ProtectedRoute = () => {
    const isAuthenticated = useIsAuthenticated();
    const { pathname, search } = useLocation();
    const redirect = pathname + search;
    if (!isAuthenticated) {
        return (<Navigate to={{
                pathname: LOGIN_URL,
                search: redirect !== '/'
                    ? qs.stringify({ redirect: pathname + search })
                    : undefined,
            }} replace/>);
    }
    return <Outlet />;
};
const NavigateAndKeepSearch = ({ to }) => {
    const [searchParams] = useSearchParams();
    const path = searchParams.toString() !== '' ? `${to}?${searchParams}` : to;
    return <Navigate replace to={path}/>;
};
const routes = [
    {
        element: <ProtectedRoute />,
        children: [
            {
                index: true,
                // Using the same key for all routes ensures that
                // the Homepage component is not re-mounted
                // when the user navigates between the town and plot pages
                element: <Homepage key="home"/>,
            },
            {
                path: TOWN_URL,
                element: <Homepage key="home"/>,
            },
            {
                path: PLOT_URL,
                element: <Homepage key="home"/>,
            },
        ],
    },
    {
        path: LOGIN_URL,
        element: <LoginPage />,
    },
    {
        path: FORGOTTEN_PASSWORD_URL,
        element: <ForgottenPasswordPage />,
    },
    {
        path: LEGACY_RESET_PASSWORD_URL,
        element: <NavigateAndKeepSearch to={RESET_PASSWORD_URL}/>,
    },
    {
        path: RESET_PASSWORD_URL,
        element: <ResetPasswordPage />,
    },
];
if (process.env.NODE_ENV === 'development') {
    routes.push({
        path: 'styleguide',
        element: <Styleguide />,
    });
    routes.push({
        path: 'routes',
        element: <RoutesPage />,
    });
}
const mainRoutes = [
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: routes,
    },
];
const router = createBrowserRouter(mainRoutes);
export const useCurrentPath = () => {
    const location = useLocation();
    const matches = matchRoutes(routes, location);
    const lastMatch = matches[matches.length - 1];
    return lastMatch?.route.path;
};
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<>
    <ColorModeScript initialColorMode={extendedTheme.config.initialColorMode}/>
    <RouterProvider router={router}/>
  </>);
