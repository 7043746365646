import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_STICKY } from 'analytics/useAnalytics';
import { useFilters, useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useIsSecondarySelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import { isGermanySite, isSwissSite } from 'utils/site';
import CadastreMenu from './CadasteMenu';
import ComputerCenterButton from './ComputedCenterButton';
import MapDisplayButtons from './MapDisplayButtons';
import ToolbarButton from './ToolbarButton';
export const toolbarButtonsStyle = {
    variant: 'ghost',
    size: 'sm',
    boxShadow: 'lg',
};
const LayersButtons = ({ hasMapControl, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const toggleFilter = useToggleFilter();
    const filters = useFilters();
    const selectedTown = useSelectedTown();
    const { hasMainLayersTree } = useSiteConfiguration();
    const getTitle = () => {
        if (isSwissSite()) {
            return t('toolbar.plu_swiss');
        }
        else if (isGermanySite()) {
            return t('toolbar.plu_germany');
        }
        return t('toolbar.plu');
    };
    return (<ButtonGroup spacing="0">
      <ComputerCenterButton />

      <MapDisplayButtons />

      {/* PLU */}
      {hasMainLayersTree(filtersKey.PLU) && (<ToolbarButton handleClick={() => {
                trackEvent({
                    category: ANALYTICS_MAP_STICKY,
                    action: `Zonage du PLU ${filters[filtersKey.PLU] ? 'off' : 'on'} carte`,
                });
                toggleFilter(filtersKey.PLU);
            }} isActive={!!filters[filtersKey.PLU]} label={getTitle()} isDisabled={!selectedTown || !selectedTown?.hasPLU} id="layers_button_plu" borderRadius="none"/>)}

      {/* PLU GPU*/}
      {hasMainLayersTree(filtersKey.PLU_GPU) && (<ToolbarButton handleClick={() => {
                trackEvent({
                    category: ANALYTICS_MAP_STICKY,
                    action: `Zonage du PLU GPU ${filters[filtersKey.PLU_GPU] ? 'off' : 'on'} carte`,
                });
                toggleFilter(filtersKey.PLU_GPU);
            }} isActive={!!filters[filtersKey.PLU_GPU]} label={t('toolbar.plu')} isDisabled={!selectedTown} id="layers_button_plu_gpu" borderRadius="none"/>)}

      {/* PLOTS */}
      <ToolbarButton handleClick={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `Parcelles ${filters[filtersKey.PLOTS] ? 'off' : 'on'} carte`,
            });
            toggleFilter(filtersKey.PLOTS);
        }} isActive={!!filters[filtersKey.PLOTS]} label={t('toolbar.plots')} isDisabled={!selectedTown || !!isSecondarySelectionMode} id="layers_button_plots" borderRadius="none"/>

      {/* CADASTRE */}
      {hasMapControl('cadastre') && <CadastreMenu />}
    </ButtonGroup>);
};
export default LayersButtons;
