import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuButton, MenuItem, MenuList, } from '@chakra-ui/react';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
const TabsSeeMore = ({ sections, handleClick, panelTitle, ...props }) => {
    const { t } = useTranslation();
    const selectedSection = useSectionHash();
    return (<Menu autoSelect={false}>
      <MenuButton variant="ghost" as={Button} bg="white" height="32px" borderRadius="none" paddingLeft="10px" paddingRight="10px" {...props}>
        ...{t(`global.more`)}
      </MenuButton>
      <MenuList>
        {sections?.map((section) => {
            const isSelected = selectedSection === section;
            return (<MenuItem onClick={() => handleClick(isSelected, section)} key={section} bgColor={isSelected ? 'colorMode.revertBase' : 'colorMode.base'} color={isSelected ? 'colorMode.base' : 'colorMode.font800'} fontWeight={700} textStyle="paragraph" _hover={{
                    backgroundColor: 'blue.500',
                    opacity: 0.8,
                    color: 'white',
                    _disabled: {
                        color: 'inherit',
                    },
                }} data-cy={'plot_section_' + section}>
              {t(`tabs.${panelTitle}.section.${section}.title`)}
            </MenuItem>);
        })}
      </MenuList>
    </Menu>);
};
export default TabsSeeMore;
