import * as React from 'react';
import { HStack, Link } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import useCurrentSite from 'hooks/useCurrentSite';
import LogoPappersLight from 'images/logo/pappers_light.png';
import { getPappersLinkCompany } from 'utils/externalLink';
import { filtersKey } from 'utils/filters';
export const SirenLink = ({ siren, analyticOrigin = ANALYTICS_OPEN, analyticDetail, id, showExternalLink, }) => {
    const { trackEvent } = useAnalytics();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const { currentSite } = useCurrentSite();
    const sirenValid = siren.match('^[0-9]{9}$');
    if (!currentSite?.ownerSubscription) {
        return <>{siren}</>;
    }
    return (<HStack as="span" spacing={2}>
      <Link textStyle="small" variant="secondary" onClick={() => {
            updateExtraFilter(`${filtersKey.COMPANY_OWNED_PLOTS}.sirens`, [siren]);
            toggleLayerTab({
                tabId: filtersKey.COMPANY_OWNED_PLOTS,
            });
        }} data-cy={id}>
        {siren}
      </Link>

      {showExternalLink && sirenValid && (<Link href={getPappersLinkCompany(siren)} textStyle="small" variant="secondary" isExternal onClick={() => {
                if (analyticOrigin && analyticDetail) {
                    trackEvent({
                        category: analyticOrigin,
                        action: analyticDetail,
                    });
                }
            }} lineHeight="1em" verticalAlign="middle">
          <img width={14} style={{ maxWidth: 'none' }} src={LogoPappersLight} alt="Pappers" title="Pappers"/>
        </Link>)}
    </HStack>);
};
export default SirenLink;
