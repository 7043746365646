import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { formatNumberToLocale } from 'utils/formatNumber';
import InfoWindowTable from './InfoWindowTable';
const WindFarmInfoWindows = ({ item, }) => {
    const { t, i18n } = useTranslation();
    if (!item) {
        return null;
    }
    return (<Box maxHeight="400px" overflow="hidden">
      <InfoWindowTable content={[
            {
                title: t('info_window.wind_farm.name'),
                data: item.name,
                bold: true,
            },
            {
                title: t('info_window.wind_farm.nb_mast'),
                data: item.masts.length,
            },
            {
                title: t('info_window.wind_farm.power'),
                data: formatNumberToLocale(item.power, i18n.language) + ` MW`,
                bold: true,
            },
            {
                title: t('info_window.wind_farm.date_permit'),
                data: item.datePermit,
            },
            {
                title: t('info_window.wind_farm.date_online'),
                data: item.dateOnline,
            },
            {
                title: t('info_window.wind_farm.state'),
                data: item.state,
            },
            {
                title: t('info_window.wind_farm.status'),
                data: item.status,
            },
            {
                title: t('info_window.wind_farm.operator'),
                data: item.operator,
            },
        ]}/>
      <div style={{ paddingTop: 10, fontSize: 15, fontWeight: 900 }}>
        {t('info_window.wind_farm.title')}
      </div>
      <table style={{ marginTop: 5, fontSize: 12 }}>
        <tr>
          <th>{t('info_window.wind_farm.power')}</th>
          <th>{t('info_window.wind_farm.height')}</th>
          <th>{t('info_window.wind_farm.height_mast')}</th>
          <th>{t('info_window.wind_farm.cote_NGF')}</th>
          <th>{t('info_window.wind_farm.rotor_diameter')}</th>
          <th>{t('info_window.wind_farm.period')}</th>
          <th>{t('info_window.wind_farm.light')}</th>
          <th>{t('info_window.wind_farm.date_online')}</th>
          <th>{t('info_window.wind_farm.constructor')}</th>
          <th>{t('info_window.wind_farm.model')}</th>
        </tr>
        {item.masts.map((mast) => {
            return (<tr key={mast.id}>
              <td>{formatNumberToLocale(mast.power, i18n.language)} MW</td>
              <td>{formatNumberToLocale(mast.height, i18n.language)} m</td>
              <td>{formatNumberToLocale(mast.heightMast, i18n.language)} m</td>
              <td>{mast.coteNGF}</td>
              <td>
                {formatNumberToLocale(mast.rotorDiameter, i18n.language)} m
              </td>
              <td>{mast.period}</td>
              <td>{mast.light}</td>
              <td>{mast.dateOnline}</td>
              <td>{mast.constructor}</td>
              <td>{mast.model}</td>
            </tr>);
        })}
      </table>
    </Box>);
};
export default WindFarmInfoWindows;
