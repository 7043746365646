import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, CircularProgress, Heading, HStack, Spinner, Stack, Table as ChakraTable, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorMode, } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import Header from 'components/table/Header';
import Pagination from 'components/table/Pagination';
import { searchMatch } from 'utils/search';
import FilterDropdown from './FilterDropdown';
import TableExportButton from './TableExportButton';
const globalSearch = (row, columnId, value, addMeta) => {
    return searchMatch(row.getValue(columnId), value, addMeta);
};
const Table = ({ data, exportTitle, exportName, analyticsAction, columns, id, initialPagination = {
    pageIndex: 0,
    pageSize: 10,
}, initialSort, initialColumnVisibility = {}, initialColumnFilters = [], customColumnFilters, setCustomColumnFilters, variant = 'striped', categories, withExport = true, renderExportButton, onRowSelection, isLoading = false, emptyMessage, backgroundColorHeader, exportedFields, formatExportHeader, formatExportValue, isRowHighlighted, autoResetPageIndex = true, headerTitle, enabledMultiRowSelection = true, sheetMode = 'horizontal', analytics, disabled, headerExtend = null, footerExtend = null, handleRowSelection, initialSelect, extraExportItems, exportItems, exportButtonTitle, containerHeight = '100%', }) => {
    const { t } = useTranslation();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [columnFilters, setColumnFilters] = customColumnFilters
        ? [customColumnFilters, setCustomColumnFilters]
        : useState(initialColumnFilters);
    const [sorting, setSorting] = useState(initialSort);
    const [pagination, setPagination] = useState(initialPagination);
    const [rowSelection, setRowSelection] = useState(initialSelect ?? {});
    const table = useReactTable({
        data,
        columns,
        autoResetPageIndex,
        state: {
            pagination,
            sorting,
            globalFilter,
            columnFilters,
            rowSelection,
            columnVisibility: { category: false, ...initialColumnVisibility },
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        onRowSelectionChange: setRowSelection,
        globalFilterFn: globalSearch,
        enableMultiRowSelection: enabledMultiRowSelection,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });
    useEffect(() => {
        if (onRowSelection) {
            onRowSelection(table.getSelectedRowModel().flatRows);
        }
    }, [rowSelection]);
    const { colorMode } = useColorMode();
    const mapDataFiltered = (row) => {
        const rowCache = row._valuesCache;
        const data = { ...row.original };
        // Use this block to export data as it is on the UI
        if (Object.values(rowCache).length > 0) {
            if ('type' in data)
                data.type = rowCache.type;
        }
        return data;
    };
    const dataFiltered = table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
        ? table
            .getFilteredRowModel()
            .rows.filter((row) => row.getIsSelected())
            .map((row) => mapDataFiltered(row))
        : withExport
            ? table.getFilteredRowModel().rows.map((row) => mapDataFiltered(row))
            : [];
    const dataSelected = table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
        ? table
            .getFilteredRowModel()
            .rows.filter((row) => row.getIsSelected())
            .map((row) => mapDataFiltered(row))
        : [];
    useEffect(() => {
        if (handleRowSelection) {
            const isAllSelected = table.getIsAllRowsSelected();
            const isNoneSelected = !table.getIsSomeRowsSelected();
            handleRowSelection(dataFiltered, isAllSelected, isNoneSelected);
        }
    }, [JSON.stringify(dataFiltered)]);
    useEffect(() => {
        table.resetRowSelection(initialSelect);
    }, [initialSelect]);
    const withHeader = headerTitle || headerExtend;
    const hasOnePage = table.getPageCount() <= 1;
    const formatExportValueDefault = (value) => {
        return value;
    };
    return (<Stack height={containerHeight}>
      {withHeader && (<Header titleElement={headerTitle} headerExtend={headerExtend}/>)}
      <Box className="smooth-box-shadow" backgroundColor="colorMode.background900" borderBottomRadius={6} maxH={`calc(100% - ${hasOnePage ? (withHeader ? 90 : 50) : withHeader ? 120 : 80}px)`}>
        <TableContainer overflowX="auto" overflowY="auto" height={data.length === 0 ? 'auto' : '100%'}>
          <ChakraTable variant={variant} id={id} data-cy={id} borderRadius={6} opacity={data?.length && isLoading ? '0.4' : '1'}>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => {
            if (headerGroup.depth === 0) {
                return null;
            }
            return (<Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                    const columnDef = header.column.columnDef
                        .meta;
                    return (<Th key={header.id} backgroundColor={backgroundColorHeader && colorMode !== 'dark'
                            ? backgroundColorHeader[header.id]
                            : 'none'} width={columnDef?.width}>
                          <Box display="flex" alignItems="center" minW={columnDef?.minW} maxW={columnDef?.maxW}>
                            <Text as="span">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </Text>
                            {(header.column.getCanFilter() ||
                            header.column.getCanSort()) && (<FilterDropdown key={header.id} column={header.column} filters={(header.column.getFilterValue() ||
                                [])} canFilter={header.column.getCanFilter()} canSort={header.column.getCanSort()} currentSorting={header.column.getIsSorted()} filterFn={header.column.getFilterFn()} isLoading={isLoading} analytics={analytics}/>)}
                          </Box>
                        </Th>);
                })}
                  </Tr>);
        })}
            </Thead>
            <Tbody 
    // opacity={disabled ? 0.4 : 1}
    style={disabled ? { position: 'relative', opacity: 0.4 } : {}} _after={disabled
            ? {
                position: 'absolute!important',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
            }
            : {}}>
              {categories
            ? categories.map((category) => {
                const rows = table.getRowModel().rows.filter((value) => {
                    return value.getValue('category') === category;
                });
                if (rows.length === 0) {
                    return null;
                }
                return (<React.Fragment key={category}>
                        <Tr data-cy={id + '_row'}>
                          <Td colSpan={rows[0].getVisibleCells().length} className="row-title">
                            <Heading as="h5" variant="h5" color="colorMode.font800">
                              {category}
                            </Heading>
                          </Td>
                        </Tr>
                        {rows.map((row) => {
                        const highlighted = isRowHighlighted && isRowHighlighted(row.original);
                        return (<Tr data-cy={id + '_row'} key={row.id} onClick={onRowSelection && row.getToggleSelectedHandler()} cursor={onRowSelection ? 'pointer' : 'default'} backgroundColor={highlighted
                                ? 'colorMode.background700'
                                : undefined}>
                              {row.getVisibleCells().map((cell) => {
                                return (<Td key={cell.id} className={row.getIsSelected() ? 'selected' : ''}>
                                    <Box>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Box>
                                  </Td>);
                            })}
                            </Tr>);
                    })}
                      </React.Fragment>);
            })
            : table.getRowModel().rows.map((row) => {
                const highlighted = isRowHighlighted && isRowHighlighted(row.original);
                return (<Tr key={row.id} data-cy={id + '_row'} backgroundColor={highlighted ? 'colorMode.background500' : undefined} onClick={onRowSelection && row.getToggleSelectedHandler()} cursor={onRowSelection ? 'pointer' : 'default'}>
                        {row.getVisibleCells().map((cell) => {
                        return (<Td key={cell.id} isNumeric={typeof cell.getContext().getValue() === 'number'}>
                              <Box>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Box>
                            </Td>);
                    })}
                      </Tr>);
            })}
            </Tbody>
          </ChakraTable>
        </TableContainer>
        {Boolean(isLoading) && !data?.length && (<Center paddingY={10}>
            <CircularProgress isIndeterminate color="gray"/>
          </Center>)}

        {data.length === 0 && !isLoading && (<Center width="100%" height="80px" backgroundColor="colorMode.background800" marginTop="0px!important">
            {isLoading ? (<Spinner />) : (<Text textStyle="paragraph" color="colorMode.font700">
                {emptyMessage || t('table.empty')}
              </Text>)}
          </Center>)}
      </Box>
      <Pagination table={table}/>
      {(withExport || footerExtend) && (<HStack alignItems="flex-start">
          {withExport &&
                (renderExportButton ? (renderExportButton(dataFiltered, dataSelected, isLoading)) : (<TableExportButton exportItems={exportItems} exportName={exportName} exportTitle={exportTitle} isLoading={isLoading} analyticsAction={analyticsAction ? analyticsAction : exportTitle} dataFiltered={dataFiltered} formatExportHeader={formatExportHeader} formatExportValue={formatExportValue ?? formatExportValueDefault} sheetMode={sheetMode} extraExportItems={extraExportItems} exportedFields={exportedFields} exportButtonTitle={exportButtonTitle}/>))}
          {footerExtend && footerExtend}
        </HStack>)}
    </Stack>);
};
export default Table;
