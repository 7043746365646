import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Flex, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE } from 'analytics/useAnalytics';
import { useMenuItemSelected, useToggleMenu, } from 'hooks/contexts/useMenuContext';
import useNewsCount from 'hooks/news/useNewsCount';
import { menuItems } from '../utils';
const VerticalMenuButton = ({ Icon, disabled, menuItemKey, }) => {
    const { t } = useTranslation();
    const menuItemSelected = useMenuItemSelected();
    const { data: newsCount } = useNewsCount();
    const hasNotification = menuItemKey === menuItems.NEWS && newsCount?.freshNews > 0;
    const toggleMenu = useToggleMenu();
    const { trackEvent } = useAnalytics();
    const isSelected = menuItemSelected === menuItemKey;
    const handleMenuItemClick = (clickedMenuItem) => {
        toggleMenu(clickedMenuItem);
        trackEvent({
            category: ANALYTICS_TELECOMMANDE,
            action: t(`menu.${clickedMenuItem}`),
        });
    };
    return (<Box as="button" width="full" padding="2px" bgColor={isSelected ? 'red.500' : 'transparent'} opacity={disabled ? 0.2 : 1} onClick={() => {
            if (!disabled)
                handleMenuItemClick(menuItemKey);
        }} disabled={disabled} data-cy={`menu_button_${menuItemKey}`}>
      <Flex paddingY={4} flexDirection="column" alignItems="center">
        <Box color={isSelected ? 'white' : 'colorMode.font800'} position="relative">
          {hasNotification && (<Circle position="absolute" zIndex={60} backgroundColor="blue.500" size={3} marginLeft={3}/>)}
          <Icon fill="currentColor" width={25} height={25}/>
        </Box>
        <Text textAlign="center" noOfLines={2} textStyle="paragraph" color={isSelected ? 'white' : 'colorMode.font800'}>
          {t(`sidebar.menu.${menuItemKey}`)}
        </Text>
      </Flex>
    </Box>);
};
export default VerticalMenuButton;
