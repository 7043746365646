import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import { useIsLoadingLayersTree, useMainLayers, } from 'hooks/contexts/useLayersTreeContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { getTownPlotsInfo } from 'utils/filters';
import { isSwissSite } from 'utils/site';
import { PADDING } from './PlotsSearchFormFields';
import { ELEVATION_OPTIONS_KEY } from './RulesSearch';
export const baseSearchFields = ['zones', 'zonesApplicable', 'plotArea'];
const BaseSearch = ({ isLoadingSearchData, hasSearchField, form, }) => {
    const { control, register } = form;
    const mainLayers = useMainLayers();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const selectedTown = useSelectedTown();
    const isLoading = isLoadingSearchData || isLoadingLayersTree;
    const { t } = useTranslation();
    const townPlotsInfo = getTownPlotsInfo(mainLayers);
    const formattedZones = townPlotsInfo?.zones && townPlotsInfo.zones.length > 0
        ? townPlotsInfo.zones.map((zone) => {
            return { label: zone, value: zone };
        })
        : null;
    const formattedZonesAdditional = townPlotsInfo?.zonesAdditional && townPlotsInfo.zonesAdditional.length > 0
        ? townPlotsInfo.zonesAdditional.map((zone) => {
            return { label: zone, value: zone };
        })
        : [];
    const getLabel = (label) => {
        if (isSwissSite() && label === 'plu_areas') {
            return t('sidebar.search_plots.' + label + '_swiss');
        }
        return t('sidebar.search_plots.' + label);
    };
    const elevationOptions = [];
    for (const key in ELEVATION_OPTIONS_KEY) {
        elevationOptions.push({
            value: key,
            label: t(ELEVATION_OPTIONS_KEY[key]),
        });
    }
    return (<>
      {((hasSearchField('zones') &&
            (formattedZones?.length !== 0 || formattedZones === null)) ||
            hasSearchField('plotArea')) && (<Stack spacing={2} padding={PADDING}>
          {hasSearchField('zones') &&
                (formattedZones?.length !== 0 || formattedZones === null) && (<>
                <FormControl isDisabled={isLoading} data-cy="plu_zone_select">
                  <FormLabel htmlFor="zones" marginBottom={1}>
                    {selectedTown?.additionalPLU
                    ? t('sidebar.search_plots.plu_areas_decided')
                    : getLabel('plu_areas')}
                  </FormLabel>
                  <Controller name="zones" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_plu_areas')} noOptionsMessage={() => t('sidebar.search_plots.no_data_plu_areas')} closeMenuOnSelect={false} isMulti options={formattedZones ?? []} styles={multiSelectStyles} isLoading={isLoadingLayersTree || townPlotsInfo === null} loadingMessage={() => t('global.loading')}/>)}/>
                </FormControl>
                {selectedTown?.additionalPLU && (<FormControl isDisabled={isLoading}>
                    <FormLabel htmlFor="zonesApplicable" marginBottom={1}>
                      {t('sidebar.search_plots.plu_areas_applicable')}
                    </FormLabel>
                    <Controller name="zonesApplicable" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_plu_areas_applicable')} noOptionsMessage={() => t('sidebar.search_plots.no_data_plu_areas')} closeMenuOnSelect={false} isMulti options={formattedZonesAdditional} styles={multiSelectStyles} isLoading={isLoadingLayersTree || townPlotsInfo === null} loadingMessage={() => t('global.loading')}/>)}/>
                  </FormControl>)}
              </>)}

          {hasSearchField('plotArea') && (<FormControl isDisabled={isLoading}>
              <FormLabel htmlFor="plotAreaMin" marginBottom={1}>
                {t('sidebar.search_plots.plot_area')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Min" {...register('plotAreaMin')} type="number" data-cy="min_area_input"/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Max" {...register('plotAreaMax')} type="number" data-cy="max_area_input"/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}
        </Stack>)}
    </>);
};
export default BaseSearch;
