import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Link, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import CreateContactButton from 'components/buttons/CreateContactButton';
import { ManagerLink } from 'components/links/ManagerLink';
import { formatOwnerContactInfo } from 'components/tabs/plots/PlotSections/owner/PlotOwnerDetail/PlotOwnerDetailWrapper';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import { CONTACT_TYPE_MANAGER, CONTACT_TYPE_REPRESENTATIVE, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
const StaffItem = ({ owner, occupant, staff, index, onClick = () => { }, setContactToCreate, flexDirection = 'row', widthLabel = '55%', }) => {
    const { t, i18n } = useTranslation();
    const tabsView = useTabsView();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const { trackEvent } = useAnalytics();
    const getData = () => {
        const type = [
            30, 31, 38, 37, 12, 53, 15, 27, 16, 17, 4, 9, 10, 8, 19, 13, 20, 14, 43,
            44,
        ].includes(staff.roleId)
            ? CONTACT_TYPE_MANAGER
            : CONTACT_TYPE_REPRESENTATIVE;
        const comment = staff.role +
            ', ' +
            staff.age +
            ' ' +
            t('global.years') +
            ' - ' +
            formatDate(staff.dateBirth, 'YYYY-MM', i18n.language);
        if (owner) {
            return {
                ...owner,
                name: staff?.name,
                lastname: staff?.name,
                firstname: staff.firstname,
                company: owner.name,
                type,
                comment,
            };
        }
        else {
            return {
                ownerId: null,
                name: staff?.name,
                lastname: staff?.name,
                firstname: staff.firstname,
                streetNumber: '',
                street: occupant.address,
                postalCode: occupant.postalCode,
                town: occupant.town,
                company: occupant.companyName,
                siren: occupant.siren,
                type,
                comment,
            };
        }
    };
    return (<Stack width="100%" flexDirection={flexDirection} justifyContent="space-between" alignItems={flexDirection === 'row' ? 'center' : 'flex-start'}>
      <Stack maxWidth={widthLabel} flex={tabsView === TABS_VIEW_HALF_VERTICAL ? 1 : 'none'} key={`owner-staff-${index}`} paddingY={1} spacing={0} direction="column">
        <HStack>
          <Link variant="secondary" fontWeight="bold" onClick={() => {
            onClick();
            updateExtraFilter(`${filtersKey.COMPANY_OWNED_MANAGER}.manager`, staff);
            toggleLayerTab({
                tabId: filtersKey.COMPANY_OWNED_MANAGER,
            });
            trackEvent({
                category: ANALYTICS_PLOT_TAB,
                action: 'Ouverture sociétés du dirigeant',
            });
        }}>
            {staff.firstname} {staff.name}
          </Link>
          {staff.dateBirth && (<ManagerLink name={staff.name} firstname={staff.firstname} date={staff.dateBirth} analyticDetail="Nom du dirigeant"/>)}
        </HStack>
        <Text textStyle="paragraph">{staff.role}</Text>
        {staff.age && (<Text textStyle="paragraph">
            {staff.age} {t('global.years')} -{' '}
            {formatDate(staff.dateBirth, 'YYYY-MM', i18n.language)}
          </Text>)}
      </Stack>
      <Box flex={tabsView === TABS_VIEW_HALF_VERTICAL ? 1 : 'none'}>
        <CreateContactButton newContact={formatOwnerContactInfo(getData())} setContactToCreate={setContactToCreate} analyticsCategory={ANALYTICS_PLOT_TAB} analyticsAction="Création contact - dirigeant"/>
      </Box>
    </Stack>);
};
export default StaffItem;
