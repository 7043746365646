import { useState } from 'react';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import BusinessInfoWindow, { IOBODACCBusinessInfo, } from 'components/infoWindow/BusinessInfoWindow';
import createLayersHook from 'hooks/layers/createLayersHook';
import BODACCBusinessImg from 'images/POIImages/Pin_Fond_Commerce.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { numberWithSpaces } from 'utils/text';
const LABEL_WIDTH = 60;
const LABEL_LINE_HEIGHT = 18;
const IOBODACCBusinessMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        price: iots.number,
    }),
], 'IOBODACCBusinessMarkerItem');
const fetchBODACCBusiness = (townId) => () => get(iots.array(IOBODACCBusinessMarkerItem), `${townId}/bodacc/business`);
const fetchDPENewInfo = (item) => {
    return get(IOBODACCBusinessInfo, `bodacc/business/${item.id}`);
};
const LEGENDS = {
    bodacc_business: {
        label: 'bodacc_business',
        layerKey: filtersKey.BODACC_BUSINESS,
        needsTranslate: true,
        image: BODACCBusinessImg,
    },
};
const getMarkerLabel = (item) => {
    if (item.price === null) {
        return null;
    }
    const label = numberWithSpaces(item.price) + ' €';
    return {
        content: ReactDOMServer.renderToStaticMarkup(label),
        anchorX: LABEL_WIDTH / 3.5,
        anchorY: -LABEL_LINE_HEIGHT / 1.5,
        className: 'marker-label',
    };
};
const formatInfoWindowContent = (info) => {
    const content = (<BusinessInfoWindow info={info} style={{ maxWidth: '400px' }}/>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const useBODACCBusinessLayer = () => {
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const businessInfo = await fetchDPENewInfo(marker);
        const content = formatInfoWindowContent(businessInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    createLayersHook({
        fetch: fetchBODACCBusiness,
        markerImg: BODACCBusinessImg,
        legends: LEGENDS,
        getInfoWindowContent,
        getMarkerLabel,
        updateOnChange: true,
    })(filtersKey.BODACC_BUSINESS);
};
const BODACCBusinessLayer = () => {
    useBODACCBusinessLayer();
    return null;
};
export default BODACCBusinessLayer;
