import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
const CityHallSidebarContent = ({ selectedTown, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    return (<VStack alignItems="start" spacing={1}>
      <Text textStyle="paragraph" fontWeight={500}>
        {selectedTown?.cityHall?.name}
      </Text>
      <Link variant="secondary" href={selectedTown?.cityHall?.website} isExternal onClick={() => {
            trackEvent({
                category: ANALYTICS_OPEN,
                action: 'URL',
            });
        }}>
        {selectedTown?.cityHall?.website}
      </Link>
      {selectedTown?.cityHall?.phone && (<Link variant="secondary" href={`tel:${selectedTown.cityHall.phone}`} isExternal onClick={() => {
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Téléphone',
                });
            }}>
          {`${t('sidebar_content.city_hall.phone')} : ${selectedTown.cityHall.phone}`}
        </Link>)}
      {selectedTown?.cityHall?.email && (<Link variant="secondary" href={`mailto:${selectedTown.cityHall.email}`} isExternal onClick={() => {
                trackEvent({
                    category: ANALYTICS_OPEN,
                    action: 'Email',
                });
            }}>
          {`${t('sidebar_content.city_hall.mail')} : ${selectedTown.cityHall.email}`}
        </Link>)}
    </VStack>);
};
export default CityHallSidebarContent;
