import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useAnalytics, { ANALYTICS_CATEGORY_SEARCH } from 'analytics/useAnalytics';
import * as yup from 'yup';
import MultiSelectCreateOption from 'components/inputs/MultiSelectCreateOption';
import { FILTER_PADDING_X } from 'components/sidebar/FiltersSideBar/FiltersTree';
import OwnerSearchOptional from 'components/sidebar/PlotSearchSideBar/form/OwnerSearchOptional';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import usePlotsSirenSearch from 'hooks/plotsSearch/usePlotsSirenSearch';
import useCurrentSite from 'hooks/useCurrentSite';
import useCustomToast from 'hooks/useCustomToast';
import FavoriteOffIcon from 'images/icons/FavoriteOff';
import { filtersKey } from 'utils/filters';
import SavedFiltersList from './SavedFiltersList';
const CompanyOwnedPlotsSidebarContent = () => {
    const { t } = useTranslation();
    const { savedSirens, deleteSirens, addSirens } = usePlotsSirenSearch();
    const { currentSite } = useCurrentSite();
    const { getErrorToast } = useCustomToast();
    const getValidationSchema = (t) => {
        const properties = {
            sirens: yup
                .string()
                .test('len', t('sidebar.search_plots.error.siren_length'), (val) => {
                return val
                    ? val
                        ?.split(';')
                        .every((el) => el?.length === 9 && el?.toString().match(/^\d+$/) !== null)
                    : true;
            })
                .required(t('global.fields.error.required')),
        };
        return yup.object().shape(properties);
    };
    const saveSirens = () => {
        if (!savedSirens?.find((el) => el?.sirens === getValues()?.sirens)) {
            addSirens(getValues());
        }
        else {
            getErrorToast({
                title: t('sidebar_content.company_owned_plots.already_saved'),
            });
        }
    };
    const validationSchema = getValidationSchema(t);
    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { sirens: undefined },
    });
    const { handleSubmit, formState, getValues, reset, watch } = methods;
    const { isSubmitted, errors } = formState;
    const [appliedSearchId, setAppliedSearchId] = React.useState();
    const { trackEvent } = useAnalytics();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleLayerTab = useToggleLayerTab();
    const searchPlots = (values) => {
        const sirens = values.sirens && values.sirens.trim() !== ''
            ? values.sirens.trim().split(';')
            : [];
        updateExtraFilter(filtersKey.COMPANY_OWNED_PLOTS, {
            sirens: sirens,
        });
        trackEvent({ category: ANALYTICS_CATEGORY_SEARCH, action: 'Siren' });
        toggleLayerTab({ tabId: filtersKey.COMPANY_OWNED_PLOTS });
    };
    const formatSirenName = (sirens) => {
        const sirensList = sirens.split(';').filter((value, index, array) => {
            return array.indexOf(value) === index;
        });
        return `${sirensList.slice(0, 30).join(' ')}${sirensList?.length > 30 ? '...' : ''}`;
    };
    const getFormattedSirens = () => {
        return savedSirens?.map((el) => ({
            ...el,
            name: formatSirenName(el?.sirens),
        }));
    };
    const sirens = watch('sirens');
    return (<Box paddingY={2} maxW="100%" width="100%">
      <FormProvider {...methods}>
        {currentSite.ownerSubscription && (<form onSubmit={handleSubmit(searchPlots)}>
            <VStack>
              <VStack paddingX={FILTER_PADDING_X}>
                <MultiSelectCreateOption id="siren_urbanism_search" label={t('sidebar_content.company_owned_plots.input.label')} name="sirens" placeholder={t('sidebar_content.company_owned_plots.input.placeholder')} noOptionsMessage={() => t('sidebar_content.company_owned_plots.input.no_options')} formatCreateLabel={(inputValue) => `${t('global.add')} ${inputValue}`}/>

                <Button type="submit" size="sm" variant="outline" width="full" isDisabled={!sirens?.length} data-cy="siren_plot_search_submit" onClick={() => {
                const savedSearch = savedSirens?.find((el) => el?.sirens === getValues().sirens);
                setAppliedSearchId(savedSearch ? savedSearch?.id : undefined);
            }}>
                  {t('sidebar_content.company_owned_plots.submit')}
                </Button>
                {isSubmitted && !Object?.keys(errors)?.length && (<Button size="sm" width="full" backgroundColor="transparent" borderColor="red.500" borderWidth={1} rightIcon={<FavoriteOffIcon fill="red"/>} onClick={saveSirens} color="red.500" data-cy="save_siren_filter" disabled={!sirens?.length}>
                    {t('sidebar_content.company_owned_plots.save_siren')}
                  </Button>)}
              </VStack>
              <SavedFiltersList savedFilters={getFormattedSirens()} handleDelete={(id) => {
                deleteSirens(id);
            }} handleToggle={(id) => {
                if (appliedSearchId !== id) {
                    const sirens = savedSirens.find((el) => el?.id === id)?.sirens;
                    setAppliedSearchId(id);
                    reset({ sirens });
                    searchPlots({ sirens });
                }
                else {
                    toggleLayerTab({
                        tabId: filtersKey.COMPANY_OWNED_PLOTS,
                        closeTab: true,
                    });
                    setAppliedSearchId(undefined);
                    reset({ sirens: undefined });
                }
            }} appliedFilterId={appliedSearchId} title={t('sidebar_content.company_owned_plots.saved_siren_title')}/>
            </VStack>
          </form>)}
        <Box paddingX={FILTER_PADDING_X}>
          {currentSite.ownerPublicity && <OwnerSearchOptional />}
        </Box>
      </FormProvider>
    </Box>);
};
export default CompanyOwnedPlotsSidebarContent;
