// ONLY_SWISS
import * as React from 'react';
import { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem, } from 'types/api';
import { filtersKey } from 'utils/filters';
const getInfoWindowContent = (layerInfo) => {
    const content = <>{layerInfo?.infoTitle && layerInfo.infoTitle}</>;
    return ReactDOMServer.renderToString(content);
};
const getLegends = (filterKey) => {
    const legends = {};
    legends[`danger_zone_high_${filterKey}`] = {
        layerKey: filterKey,
        label: 'danger_zone_high',
        needsTranslate: true,
        color: '#FF3333',
    };
    legends[`danger_zone_medium_${filterKey}`] = {
        layerKey: filterKey,
        label: 'danger_zone_medium',
        needsTranslate: true,
        color: '#33AFFF',
    };
    legends[`danger_zone_low_${filterKey}`] = {
        layerKey: filterKey,
        label: 'danger_zone_low',
        needsTranslate: true,
        color: '#FFD733',
    };
    legends[`danger_zone_residual_${filterKey}`] = {
        layerKey: filterKey,
        label: 'danger_zone_residual',
        needsTranslate: true,
        color: '#FFE98E',
    };
    return legends;
};
const fetchDangerZone = (townId, townScot, selectedTown, categoryId) => () => get(iots.array(IOPolygonLayerItem), `${townId}/danger-zone/${categoryId}`);
const useDangerZoneLayer = (filterKey) => {
    const getCategoryId = (filterKey) => {
        switch (filterKey) {
            case filtersKey.DANGER_ZONE_FLOOD:
                return 1;
            case filtersKey.DANGER_ZONE_AVALANCHE:
                return 2;
            case filtersKey.DANGER_ZONE_LANDSLIDE:
                return 3;
            case filtersKey.DANGER_ZONE_SLIDING:
                return 4;
        }
    };
    const categoryId = useMemo(() => getCategoryId(filterKey), [filterKey]);
    createLayersHook({
        fetch: fetchDangerZone,
        legends: getLegends(filterKey),
        getInfoWindowContent,
        extraParams: [categoryId],
    })(filterKey);
};
const DangerZoneLayer = ({ filterKey }) => {
    useDangerZoneLayer(filterKey);
    return null;
};
export default DangerZoneLayer;
