import * as React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Checkbox, FormControl, FormLabel, Heading, HStack, Input, Stack, useDisclosure, } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import SearchCheckbox from 'components/inputs/SearchCheckbox';
import { useIsLoadingLayersTree } from 'hooks/contexts/useLayersTreeContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import CheckIcon from 'images/icons/Check';
import { BUILDABLE_OPTIONS_KEY, OFFICE_CONSTRUCTION_KEY, STORE_CONSTRUCTION_KEY, WAREHOUSE_CONSTRUCTION_KEY, } from 'utils/plotsSearch';
import { getPlotSelectOptions } from './ActualBuildingSearch';
import { PADDING } from './PlotsSearchFormFields';
import { ELEVATION_OPTIONS_KEY } from './RulesSearch';
import SeeMoreInformation from './SeeMoreInformation';
export const feasibilitySearchFields = [
    'buildable',
    'allowedFloorArea',
    'permittedBuildingFootprint',
    'authorizedLevel',
    'warehouseConstruction',
    'officeConstruction',
    'storeConstruction',
    'possibleElevations',
    'smallFootprints',
];
const FeasibilitySearch = ({ isLoadingSearchData, hasSearchField, form, hasFilter, }) => {
    const { control, register, setValue } = form;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isLoadingLayersTree = useIsLoadingLayersTree();
    const selectedTown = useSelectedTown();
    const isLoading = isLoadingSearchData || isLoadingLayersTree;
    const { t } = useTranslation();
    const buildableOptions = ['2', '3', '1'].map((value) => ({
        value: value,
        label: t(BUILDABLE_OPTIONS_KEY[value]),
    }));
    const elevationOptions = [];
    for (const key in ELEVATION_OPTIONS_KEY) {
        elevationOptions.push({
            value: key,
            label: t(ELEVATION_OPTIONS_KEY[key]),
        });
    }
    return (<Stack spacing={2} alignItems="flex-start" padding={PADDING}>
      {hasSearchField('buildable') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.buildable')}
          </FormLabel>
          <Controller name="buildable" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_buildable')} closeMenuOnSelect={false} isMulti options={buildableOptions} styles={multiSelectStyles} isLoading={isLoadingLayersTree}/>)}/>
        </FormControl>)}

      {hasSearchField('allowedFloorArea') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.allowed_floor_area')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper rightElementLabel="m²">
              <Input size="sm" placeholder="Min" type="number" {...register('allowedFloorAreaMin')}/>
            </AreaInputWrapper>
            <AreaInputWrapper rightElementLabel="m²">
              <Input size="sm" placeholder="Max" type="number" {...register('allowedFloorAreaMax')}/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {hasSearchField('permittedBuildingFootprint') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.permitted_building_footprint')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper rightElementLabel="%">
              <Input size="sm" placeholder="Min" type="number" {...register('permittedBuildingFootprintMin')}/>
            </AreaInputWrapper>
            <AreaInputWrapper rightElementLabel="%">
              <Input size="sm" placeholder="Max" type="number" {...register('permittedBuildingFootprintMax')}/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {hasSearchField('authorizedLevel') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.authorized_level')}
          </FormLabel>
          <HStack width="50%">
            <AreaInputWrapper>
              <Input size="sm" placeholder="1" type="number" {...register('authorizedLevelMin')}/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {(hasSearchField('wallMaterial') ||
            hasSearchField('roofingMaterial') ||
            ((hasSearchField('officeConstruction') ||
                hasSearchField('storeConstruction')) &&
                selectedTown?.hasFeasibility)) && (<Button size="sm" variant="link" onClick={() => {
                isOpen ? onClose() : onOpen();
            }}>
          <Heading as="h5" variant="h5" fontWeight={500} lineHeight={5} whiteSpace="normal" textDecoration="underline">
            {!isOpen
                ? t('sidebar.search_plots.more_criteria')
                : t('sidebar.search_plots.less_criteria')}
            <ChevronDownIcon w={4} h={4} fill={'colorMode.font800'} transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s"/>
          </Heading>
        </Button>)}

      {isOpen && (<Stack spacing={2}>
          {selectedTown?.hasFeasibility &&
                hasSearchField('warehouseConstruction') &&
                hasFilter('warehouseConstruction') && (<FormControl isDisabled={isLoading}>
                <FormLabel marginBottom={1}>
                  {t('sidebar.search_plots.warehouse_construction')}
                </FormLabel>
                <HStack>
                  <Controller name="warehouseConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(WAREHOUSE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
                </HStack>
              </FormControl>)}
          {selectedTown?.hasFeasibility && (<>
              {hasSearchField('officeConstruction') && (<FormControl isDisabled={isLoading}>
                  <FormLabel marginBottom={1}>
                    {t('sidebar.search_plots.office_construction')}
                  </FormLabel>
                  <HStack>
                    <Controller name="officeConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(OFFICE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
                  </HStack>
                </FormControl>)}

              {hasSearchField('storeConstruction') && (<FormControl isDisabled={isLoading}>
                  <FormLabel marginBottom={1}>
                    {t('sidebar.search_plots.store_construction')}
                  </FormLabel>
                  <HStack>
                    <Controller name="storeConstruction" control={control} render={({ field }) => (<Select {...field} isLoading={isLoading} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(STORE_CONSTRUCTION_KEY, 'construction', t)} isMulti styles={multiSelectStyles}/>)}/>
                  </HStack>
                </FormControl>)}
              {selectedTown?.hasFeasibility && (<>
                  {hasSearchField('possibleElevations') && (<>
                      <FormControl isDisabled={isLoading}>
                        <Checkbox icon={<CheckIcon />} onChange={(e) => {
                            if (e.target.checked) {
                                setValue('possibleElevations', elevationOptions);
                            }
                            else {
                                setValue('possibleElevations', []);
                            }
                        }}>
                          <FormLabel marginRight="0">
                            {t('sidebar.search_plots.possible_elevations')}
                          </FormLabel>
                        </Checkbox>

                        <Controller name="possibleElevations" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.possible_elevations_option.placeholder')} closeMenuOnSelect={false} options={elevationOptions} isMulti styles={multiSelectStyles}/>)}/>
                      </FormControl>
                    </>)}

                  {hasSearchField('smallFootprints') && (<>
                      <FormControl display="flex" isDisabled={isLoading}>
                        <SearchCheckbox name="smallFootprints"/>
                        <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                          {t('sidebar.search_plots.small_footprints')}
                        </FormLabel>
                      </FormControl>

                      <SeeMoreInformation title="sidebar.search_plots.small_footprints_title" value="sidebar.search_plots.small_footprints_text"/>
                    </>)}
                </>)}
            </>)}
        </Stack>)}
    </Stack>);
};
export default FeasibilitySearch;
