import * as React from 'react';
import { useCallback, useEffect, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import CompensatoryMeasuresForBiodiversityDamage from 'components/layers/CompensatoryMeasuresForBiodiversityDamage';
import ContactPlotsLayer from 'components/layers/ContactPlotsLayer';
import HighlightedPlotsLayer from 'components/layers/HighlightedPlotsLayer';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useInfoWindow, useMap, useRemoveLayers, useStreetViewMode, } from 'hooks/contexts/useMapContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import BiologicalReserveLayer from './EnvironmentProtection/BiologicalReserveLayer';
import BiosphereReserveLayer from './EnvironmentProtection/BiosphereReserveLayer';
import BiotopProtectionLayer from './EnvironmentProtection/BiotopProtectionLayer';
import CoastlineConservatoireLayer from './EnvironmentProtection/CoastlineConservatoireLayer';
import HuntingReserveLayer from './EnvironmentProtection/HuntingReserveLayer';
import MarinParkLayer from './EnvironmentProtection/MarinParkLayer';
import NationalParkLayer from './EnvironmentProtection/NationalParkLayer';
import NationalReserveLayer from './EnvironmentProtection/NationalReserveLayer';
import Natura2000SICLayer from './EnvironmentProtection/Natura2000SICLayer';
import Natura2000ZPSLayer from './EnvironmentProtection/Natura2000ZPSLayer';
import NoBuildingAreaEnvironmentOtherLayer from './EnvironmentProtection/NoBuildingAreaEnvironmentOtherLayer';
import RegionalNaturalSpaceConservatoireLayer from './EnvironmentProtection/RegionalNaturalSpaceConservatoireLayer';
import RegionalParkLayer from './EnvironmentProtection/RegionalParkLayer';
import RegionalReserveLayer from './EnvironmentProtection/RegionalReserveLayer';
import SensitiveNaturalSpaceLayer from './EnvironmentProtection/SensitiveNaturalSpaceLayer';
import ABFDocUrbaLayer from './ABFDocUrbaLayer';
import ABFHistoricalBuildingLayer from './ABFHistoricalBuildingLayer';
import ABFLayers, { ABF_FILTERS } from './ABFLayers';
import ActiveExploitationsLayer from './ActiveExploitationsLayer';
import AdvertisingAreaLayer from './AdvertisingAreaLayer';
import AeronauticLayers, { AERONAUTIC_FILTERS } from './AeronauticLayers';
import AeronauticServitudeLayers from './AeronauticServitudeLayers';
import AgriculturalUseReservedAreasBisLayer from './AgriculturalUseReservedAreasBisLayer';
import AgriculturalUseReservedAreasLayer from './AgriculturalUseReservedAreasLayer';
import AgricultureRearingCompanyLayer from './AgricultureRearingCompanyLayer';
import AirborneNoiseLayers from './AirborneNoiseLayers';
import AlignmentLayer from './AlignmentLayer';
import AllowedLevelsDecreasedLayer from './AllowedLevelsDecreasedLayer';
import ANRUExtendsLayer from './ANRUExtendsLayer';
import ANRULayer from './ANRULayer';
import ArchitecturalDisciplineLayer from './ArchitecturalDisciplineLayer';
import AverageSolarIrradiationLayer from './AverageSolarIrradiationLayer';
import BankruptCompaniesPlotsLayer from './BankruptCompaniesPlotsLayer';
import BirdsZICOLayer from './BirdsZICOLayer';
import BODACCBusinessLayer from './BODACCBusinessLayer';
import BuildingConsumptionLayer from './BuildingConsumptionLayer';
import BuildingPermitNewMarketLayers, { BUILDING_PERMIT_NEW_MARKET_FILTERS, } from './BuildingPermitNewMarketLayers';
import CadastreLayer from './CadastreLayer';
import CDACLayer from './CDACLayer';
import CellTowerLayer from './CellTowerLayer';
import ChargingStationLayers from './ChargingStationLayers';
import CitiesOfTomorrowLayer from './CitiesOfTomorrowLayer';
import CoastalProtectionLayer from './CoastalProtectionLayer';
import CommercialBuildingsLayers, { COMMERCIAL_BUILDINGS_FILTERS, } from './CommercialBuildingsLayers';
import CommercialLineLayer from './CommercialLineLayer';
import CommercialScotLayer from './CommercialScotLayer';
import CompanyOwnedPlotsLayer from './CompanyOwnedPlotsLayer';
import ConstructabilityLimitLayer from './ConstructabilityLimitLayer';
import ConstructableStripLayer from './ConstructableStripLayer';
import ContaminatedSitesAndSoilsLayer from './ContaminatedSitesAndSoilsLayer';
import DangerZoneLayer from './DangerZoneLayer';
import DeclarationOfPublicInterestLayer from './DeclarationOfPublicInterestLayer';
import DevelopmentAreaLayer from './DevelopmentAreaLayer';
import DPENewLayer from './DPENewLayer';
import DPEOldLayer from './DPEOldLayer';
import DPETertiaryLayer from './DPETertiaryLayer';
import DREALLayer from './DREALLayer';
import DrinkingWaterCaptureLayer from './DrinkingWaterCaptureLayer';
import EasementForNaturalAndUrbanSitesProtectionLayer from './EasementForNaturalAndUrbanSitesProtectionLayer';
import EasementLayer from './EasementLayer';
import EcologicalContinuityElementsLayer from './EcologicalContinuityElementsLayer';
import EcologicalContinuumLayer from './EcologicalContinuumLayer';
import EconomicalScotLayer from './EconomicalScotLayer';
import ElectricalNetworksBTAerialLayer from './ElectricalNetworksBTAerialLayer';
import ElectricalNetworksBTUndergroundLayer from './ElectricalNetworksBTUndergroundLayer';
import ElectricalNetworksByTypeLayers from './ElectricalNetworksByTypeLayers';
import ElectricalNetworkCapareseauLayers from './ElectricalNetworksCapareseauLayers';
import ElectricalNetworksDistributionPostLayer from './ElectricalNetworksDistributionPostLayer';
import ElectricalNetworksHTAAerialLayer from './ElectricalNetworksHTAAerialLayer';
import ElectricalNetworksHTAUndergroundLayer from './ElectricalNetworksHTAUndergroundLayer';
import ElectricalNetworksHTBAerialLayer from './ElectricalNetworksHTBAerialLayer';
import ElectricalNetworksHTBUndergroundLayer from './ElectricalNetworksHTBUndergroundLayer';
import ElectricalNetworksLayers from './ElectricalNetworksLayers';
import ElectricalNetworksSourcePostLayer from './ElectricalNetworksSourcePostLayer';
import EnergyPerformanceAreaLayer from './EnergyPerformanceAreaLayer';
import EPCILayer from './EPCILayer';
import EPFLandControlAndWatchLayer from './EPFLandControlAndWatchLayer';
import EPFLandControlLayer from './EPFLandControlLayer';
import EPFLandWatchLayer from './EPFLandWatchLayer';
import EPFOrcodinLayer from './EPFOrcodinLayer';
import ExternalAspectLayer from './ExternalAspectLayer';
import FeasibilityPlotsLayer from './FeasibilityPlotsLayer';
import FiberDeployment2020Layer from './FiberDeployment2020Layer';
import FireHazardLayer from './FireHazardLayer';
import FloodingAreaLayer from './FloodingAreaLayer';
import FloodingRisksLayer from './FloodingRisksLayer';
import FloodZoneLayer from './FloodZoneLayer';
import FrostPerimeterLayer from './FrostPerimeterLayer';
import GreenSpaceCoeffLayer from './GreenSpaceCoeffLayer';
import HazmatTransportPipelinesLayer from './HazmatTransportPipelinesLayer';
import HeartOfTownLayer from './HeartOfTownLayer';
import HeatingNetworkLayer from './HeatingNetworkLayer';
import ICPELayer from './ICPELayer';
import InactiveExploitationsLayer from './InactiveExploitationsLayer';
import Incline10Layer from './Incline10Layer';
import Incline30Layer from './Incline30Layer';
import IncreasedRealEstateTaxesLayer from './IncreasedRealEstateTaxesLayer';
import IncreaseInBuildableVolumesLayer from './IncreaseInBuildableVolumesLayer';
import InstallationLimitLayer from './InstallationLimitLayer';
import LandMovementLayer from './LandMovementLayer';
import LandscapingProjectsLayer from './LandscapingProjectsLayer';
import LayoutOrientationLayer from './LayoutOrientationLayer';
import LevelCurveLayer from './LevelCurveLayer';
import LimitedBuildingAreaEnvironmentLayer from './LimitedBuildingAreaEnvironmentLayer';
import LimitedBuildingZoneResourcesAndFacilitiesLayer from './LimitedBuildingZoneResourcesAndFacilitiesLayer';
import LimitedSizeAndAccommodationCapacityAreaLayer from './LimitedSizeAndAccommodationCapacityAreaLayer';
import LogisticsBuildingsLayers from './LogisticsBuildingsLayers';
import LowAltitudeLayers from './LowAltitudeLayers';
import MapDisplayLayer from './MapDisplayLayer';
import MapPlotsLayer from './MapPlotsLayer';
import MaximumFootprintLayer from './MaximumFootprintLayer';
import MaximumHeightLayer from './MaximumHeightLayer';
import MiscEasementLayer from './MiscEasementLayer';
import MiscEasementsLayer from './MiscEasementsLayer';
import MiscLimitationsLayer from './MiscLimitationsLayer';
import MRAELayer from './MRAELayer';
import NationalMajorProjectLayer from './NationalMajorProjectLayer';
import NaturalAreaOfEcologicalInterestLayer from './NaturalAreaOfEcologicalInterestLayer';
import NaturalPeriUrbanAreaProtectionPerimeterLayer from './NaturalPeriUrbanAreaProtectionPerimeterLayer';
import NeighborhoodsLayer from './NeighborhoodsLayer';
import NewMarketPricesLayer from './NewMarketPricesLayer';
import NoBuildingAreaEnvironmentLayer from './NoBuildingAreaEnvironmentLayer';
import NoBuildingZoneResourcesAndFacilitiesLayer from './NoBuildingZoneResourcesAndFacilitiesLayer';
import NoiseAreaLayer from './NoiseAreaLayer';
import NoiseExposurePlanLayer from './NoiseExposurePlanLayer';
import NoiseSensibilityLayer from './NoiseSensibilityLayer';
import NonAedificandiAreaLayer from './NonAedificandiAreaLayer';
import NPNRULayer from './NPNRULayer';
import OfficeBuildingsLayers from './OfficeBuildingsLayers';
import OINLayer from './OINLayer';
import OldCadastreLayer from './OldCadastreLayer';
import OldMarketBuildableLotsIdfLayer from './OldMarketBuildableLotsIdfLayer';
import OldMarketDvfLayer from './OldMarketDvfLayer';
import OpticalFiberLayer from './OpticalFiberLayer';
import OrfelLayer from './OrfelLayer';
import OrtLayer from './OrtLayer';
import OSMEnergyLayers from './OSMEnergyLayers';
import OtherEasementLayer from './OtherEasementLayer';
import OverallDevelopmentPlanLayer from './OverallDevelopmentPlanLayer';
import PadducLimitedBuildingZoneLayer from './PadducLimitedBuildingZoneLayer';
import PadducNoBuildingLayer from './PadducNoBuildingLayer';
import PadducVariousRequirementLayer from './PadducVariousRequirementLayer';
import ParkingLayer from './ParkingLayer';
import ParkingSpaceCurrentExemptionLayer from './ParkingSpaceCurrentExemptionLayer';
import ParkingSpaceFutureExemptionLayer from './ParkingSpaceFutureExemptionLayer';
import ParkingSpaceLayer from './ParkingSpaceLayer';
import PartnershipUrbanProjectLayer from './PartnershipUrbanProjectLayer';
import PatrimonyLayer from './PatrimonyLayer';
import PedestrianConnectionsLayer from './PedestrianConnectionsLayer';
import PhotovoltaicNotProneAreaAocWineLayer from './PhotovoltaicNotProneAreaAocWineLayer';
import PhotovoltaicNotProneAreaPrairieLayer from './PhotovoltaicNotProneAreaPrairieLayer';
import PhotovoltaicNotProneAreaRpgLayer from './PhotovoltaicNotProneAreaRpgLayer';
import PhotovoltaicNotProneAreaRpgTiles from './PhotovoltaicNotProneAreaRpgTiles';
import PhotovoltaicProneAreaAbandonedMotorwaysLayer from './PhotovoltaicProneAreaAbandonedMotorwaysLayer';
import PhotovoltaicProneAreaAirportsLayer from './PhotovoltaicProneAreaAirportsLayer';
import PhotovoltaicProneAreaMilitaryGroundLayer from './PhotovoltaicProneAreaMilitaryGroundLayer';
import PhotovoltaicProneAreaPortsLayer from './PhotovoltaicProneAreaPortsLayer';
import PhotovoltaicProneAreaStationsLayer from './PhotovoltaicProneAreaStationsLayer';
import PhotovoltaicProneAreaWetlandLayer from './PhotovoltaicProneAreaWetlandLayer';
import PhotovoltaicProneLandUnitAreaParkingSpace500Layer from './PhotovoltaicProneLandUnitAreaParkingSpace500Layer';
import PinelBretagneLayer from './PinelBretagneLayer';
import PlanningAndStudiesLayer from './PlanningAndStudiesLayer';
import PlotElevationPossibleLayer from './PlotElevationPossibleLayer';
import PlotOwnerLayer from './PlotOwnerLayer';
import PlotsLayer from './PlotsLayer';
import PlotSourcePostLayer from './PlotSourcePostLayer';
import PlotsSearchLayer from './PlotsSearchLayer';
import PlotUpdatedBuildingPotentialLayer from './PlotUpdatedBuildingPotentialLayer';
import PLUAdditionalLayer from './PLUAdditionalLayer';
import PLUGPULayer from './PLUGPULayer';
import PLULayer from './PLULayer';
import PLUsAdjacentLayer from './PLUsAdjacentLayer';
import POILayers, { POI_COMMERCE_AND_SERVICES_FILTERS_KEY, POI_CULTURE_FILTERS_KEY, POI_EARLY_CHILDHOOD_FILTERS_KEY, POI_EDUCATION_FILTERS_KEY, POI_FESTIVAL_FILTERS_KEY, POI_FILTERS, POI_HEALTHCARE_FILTERS_KEY, POI_HOBBIES_FILTERS_KEY, POI_PLACES_OF_INTEREST_FILTERS_KEY, POI_PUBLIC_SERVICE_FILTERS_KEY, POI_RECREATIONAL_ACTIVITIES_FILTERS_KEY, POI_RISKS_FILTERS_KEY, POI_SPORTS_ACTIVITIES_FILTERS_KEY, POI_TOURISM_FILTERS_KEY, POI_TOURISTIC_PLACES_FILTERS_KEY, POI_TRANSPORT_FILTERS_KEY, POI_ZOOM_LIMIT, } from './POILayers';
import PollutionRisksLayer from './PollutionRisksLayer';
import PotentiallyContaminatedSitesAndSoilsLayer from './PotentiallyContaminatedSitesAndSoilsLayer';
import PPREarthquakeHazardLayer from './PPREarthquakeHazardLayer';
import PPRFireHydrantsLayer from './PPRFireHydrantsLayer';
import PPRFloodingLimitedBuildingZoneLayer from './PPRFloodingLimitedBuildingZoneLayer';
import PPRFloodingLowContingencyBuildingZoneLayer from './PPRFloodingLowContingencyBuildingZoneLayer';
import PPRFloodingLowHazardLayer from './PPRFloodingLowHazardLayer';
import PPRFloodingMediumHazardLayer from './PPRFloodingMediumHazardLayer';
import PPRFloodingNoBuildingZoneLayer from './PPRFloodingNoBuildingZoneLayer';
import PPRFloodingStrongHazardLayer from './PPRFloodingStrongHazardLayer';
import PPRForestFiresLayer from './PPRForestFiresLayer';
import PPRGroundMovementsClayLayer from './PPRGroundMovementsClayLayer';
import PPRGroundMovementsEarthquakeLayer from './PPRGroundMovementsEarthquakeLayer';
import PPRGroundMovementsOtherLayer from './PPRGroundMovementsOtherLayer';
import PPRLandslideLayer from './PPRLandslideLayer';
import PPRNaturalLayer from './PPRNaturalLayer';
import PPRTechnologicalLayers from './PPRTechnologicalLayers';
import PreemptionLayer from './PreemptionLayer';
import PrelocationLayer from './PrelocationLayer';
import PreservedLandscapeLayer from './PreservedLandscapeLayer';
import PresumedArcheologicalAreaLayer from './PresumedArcheologicalAreaLayer';
import PrincipleOfAccessLayer from './PrincipleOfAccessLayer';
import ProhibitedConstructionLayer from './ProhibitedConstructionLayer';
import ProjectAreaLayer from './ProjectAreaLayer';
import ProjectPlotsLayer from './ProjectPlotsLayer';
import ProtectedCultivatedLandLayer from './ProtectedCultivatedLandLayer';
import ProtectedOrAtRiskSitesLayer from './ProtectedOrAtRiskSitesLayer';
import ProtectedWoodlandLayer from './ProtectedWoodlandLayer';
import PublicElectricityNetworksLayer from './PublicElectricityNetworksLayer';
import PublicForestLayer from './PublicForestLayer';
import PVRAreasLayer from './PVRAreasLayer';
import QuarryOrWasteLayer from './QuarryOrWasteLayer';
import RadarLayer from './RadarLayer';
import RadioactiveGasLayer from './RadioactiveGasLayer';
import RailwayNetworksLayers, { RAILWAY_NETWORK_FILTERS, } from './RailwayNetworksLayers';
import RainZoneLayer from './RainZoneLayer';
import RealEstatePromotionsLayer from './RealEstatePromotionsLayer';
import ReconstructionOrDemolitionProvisionLayer from './ReconstructionOrDemolitionProvisionLayer';
import RegionalPlanContinuitiesLayer from './RegionalPlanContinuitiesLayer';
import RegionalPlanGreenAreaLayer from './RegionalPlanGreenAreaLayer';
import RegionalPlanLogisticFrameLayer from './RegionalPlanLogisticFrameLayer';
import RegionalPlanNewUrbanAreaLayer from './RegionalPlanNewUrbanAreaLayer';
import RegionalPlanPotentialLimitLayer from './RegionalPlanPotentialLimitLayer';
import RegionalPlanTransportInfrastructureLayer from './RegionalPlanTransportInfrastructureLayer';
import RegionalPlanUrbanAreaLayer from './RegionalPlanUrbanAreaLayer';
import RegionalPlanUrbanFrontLayer from './RegionalPlanUrbanFrontLayer';
import RegionalPlanWaterAreaLayer from './RegionalPlanWaterAreaLayer';
import RegionalPlanWoodlandAreaLayer from './RegionalPlanWoodlandAreaLayer';
import ReservedAreaLayer from './ReservedAreaLayer';
import RetailLayer, { RETAIL_FILTERS } from './RetailLayer';
import RichSoilProtectedAreaLayer from './RichSoilProtectedAreaLayer';
import RightOfFirstRefusalLayer from './RightOfFirstRefusalLayer';
import RisingWaterTableAreaLayer from './RisingWaterTableAreaLayer';
import RoadEasementLayer from './RoadEasementLayer';
import RoadProjectCrossingLayer from './RoadProjectCrossingLayer';
import RoadProjectHighwayLayer from './RoadProjectHighwayLayer';
import RoadProjectMultimodaleLayer from './RoadProjectMultimodaleLayer';
import RoadRailwayNoiseLayer, { ROAD_RAILWAY_FILTERS, } from './RoadRailwayNoiseLayer';
import ScotLayer from './ScotLayer';
import SelectedPlotsBuildingsLayer from './SelectedPlotsBuildingsLayer';
import SelectedPlotsLayer from './SelectedPlotsLayer';
import SeniorResidencesLayer from './SeniorResidencesLayer';
import SensitiveArcheologicalAreaLayer from './SensitiveArcheologicalAreaLayer';
import SensitiveNaturalAreaLayer from './SensitiveNaturalAreaLayer';
import SetbackLayer from './SetbackLayer';
import SewerageZoningLayer from './SewerageZoningLayer';
import ShrinkageSwellingOfClaysLayer from './ShrinkageSwellingOfClaysLayer';
import SocialMixingLayer from './SocialMixingLayer';
import SolarRoofPotentialLayer from './SolarRoofPotentialLayer';
import SpecialArchitecturalRequirementsLayer from './SpecialArchitecturalRequirementsLayer';
import SpecificSettingLayer from './SpecificSettingLayer';
import SRCELayer from './SRCELayer';
import StayOfProceedingsLayer from './StayOfProceedingsLayer';
import StayOfProceedingsPerimeterLayer from './StayOfProceedingsPerimeterLayer';
import StoneQuarryLayer from './StoneQuarryLayer';
import StreetViewLayer from './StreetViewLayer';
import StudentResidencesLayer from './StudentResidencesLayer';
import StudyPerimeterLayer from './StudyPerimeterLayer';
import SubmersibleEasementLayer from './SubmersibleEasementLayer';
import TeamPlotsLayer from './TeamPlotsLayer';
import TeamProjectsLayer from './TeamProjectsLayer';
import TechnologicalRiskLayer from './TechnologicalRiskLayer';
import TenYearsOldHousingWithRetainedRegulationLayer from './TenYearsOldHousingWithRetainedRegulationLayer';
import TermitesLayer from './TermitesLayer';
import TerritorialDevelopmentAgreementLayer from './TerritorialDevelopmentAgreementLayer';
import ThematicMapLayers from './ThematicMapLayers';
import ThematicMapRentLayers from './ThematicMapRentLayers';
import TownLayer from './TownLayer';
import TownsLayer from './TownsLayer';
import TownsLimitsLayer from './TownsLimitsLayer';
import TramFootprintLayer from './TramFootprintLayer';
import TunnelEasementLayer from './TunnelEasementLayer';
import UndergroundCavitiesLayer from './UndergroundCavitiesLayer';
import UrbanScotLayer from './UrbanScotLayer';
import UrbanSequenceProtectionLayer from './UrbanSequenceProtectionLayer';
import UserDrawingsLayer from './UserDrawingsLayer';
import UserPlotsLayer from './UserPlotsLayer';
import UserPOIsLayer from './UserPOIsLayers';
import UserProjectsLayer from './UserProjectsLayer';
import ViewAnglesProtectionLayer from './ViewAnglesProtectionLayer';
import VolumetryLayer from './VolumetryLayer';
import VotePlaceLayer from './VotePlaceLayer';
import WastelandLayer from './WastelandLayer';
import WasteLayer from './WasteLayer';
import WaterPipelineLayer from './WaterPipelineLayer';
import WetlandAreaLayer from './WetlandAreaLayer';
import WetlandAreaPotentiallyLayers from './WetlandAreaPotentiallyLayer';
import WetZoneLayer from './WetZoneLayer';
import WindBirdLayers, { WIND_BIRD_FILTERS } from './WindBirdLayers';
import WindConstraintHousingLayer from './WindConstraintHousingLayer';
import WindConstraintInclineLayer from './WindConstraintInclineLayer';
import WindConstraintRailwayLayer from './WindConstraintRailwayLayer';
import WindConstraintRoadLayer from './WindConstraintRoadLayer';
import WindFarmLayer from './WindFarmLayer';
import WindLargeFrenchSiteLayer from './WindLargeFrenchSiteLayer';
import WindMRAELayer from './WindMRAELayer';
import WindPotential50Layer from './WindPotential50Layer';
import WindPotential100Layer from './WindPotential100Layer';
import WindPotential140Layer from './WindPotential140Layer';
import WindPotential160Layer from './WindPotential160Layer';
import WindPotentialLayer from './WindPotentialLayer';
import WindTowerLayer from './WindTowerLayer';
import WindUNESCONaturalLayer from './WindUNESCONaturalLayer';
import WindZAERExclusionLayer from './WindZAERExclusionLayer';
import WoodlandAreaLayer from './WoodlandAreaLayer';
import WoodlandStripLayer from './WoodlandStripLayer';
import ZACLayer from './ZACLayer';
import ZAELayer from './ZAELayer';
import ZAERLayers, { ZAER_FILTERS } from './ZAERLayers';
import ZnieffType1Layer from './ZnieffType1Layer';
import ZnieffType2Layer from './ZnieffType2Layer';
const Layers = () => {
    const map = useMap();
    const { t } = useTranslation();
    const infoWindow = useInfoWindow();
    const streetViewMode = useStreetViewMode();
    const filters = useFilters();
    const computedZoom = useComputedZoom();
    const [layersToShow, setLayersToShow] = React.useState([]);
    const [, startTransition] = useTransition();
    const { hasMainLayersTree } = useSiteConfiguration();
    const toastPOIId = React.useRef(null);
    const toast = useToast();
    const { hasMapControl } = useSiteConfiguration();
    const removeLayers = useRemoveLayers();
    const selectedTown = useSelectedTown();
    const selectedTownId = selectedTown?.id;
    const showLayer = useCallback((filters) => {
        return (typeof filters === 'object' ? filters : [filters]).some((filter) => {
            return layersToShow?.includes(filter);
        });
    }, [layersToShow]);
    useEffect(() => {
        const activeFilters = [];
        startTransition(() => {
            Object.keys(filters).map((filterName) => {
                if (filters[filterName]) {
                    activeFilters.push(filterName);
                }
            });
            setLayersToShow(activeFilters);
        });
    }, [filters]);
    const showPLULayer = showLayer(filtersKey.PLU);
    const showPLUGPULayer = showLayer(filtersKey.PLU_GPU);
    useEffect(() => {
        if (infoWindow.current && (!showPLULayer || !showPLUGPULayer)) {
            infoWindow.current.close();
        }
    }, [showPLULayer, showPLUGPULayer]);
    useEffect(() => {
        if (showLayer(POI_TRANSPORT_FILTERS_KEY) ||
            showLayer(POI_COMMERCE_AND_SERVICES_FILTERS_KEY) ||
            showLayer(POI_HOBBIES_FILTERS_KEY) ||
            showLayer(POI_EDUCATION_FILTERS_KEY) ||
            showLayer(POI_CULTURE_FILTERS_KEY) ||
            showLayer(POI_HEALTHCARE_FILTERS_KEY) ||
            showLayer(POI_EARLY_CHILDHOOD_FILTERS_KEY) ||
            showLayer(POI_TOURISM_FILTERS_KEY) ||
            showLayer(POI_PUBLIC_SERVICE_FILTERS_KEY) ||
            showLayer(POI_RISKS_FILTERS_KEY) ||
            showLayer(POI_SPORTS_ACTIVITIES_FILTERS_KEY) ||
            showLayer(POI_RECREATIONAL_ACTIVITIES_FILTERS_KEY) ||
            showLayer(POI_TOURISTIC_PLACES_FILTERS_KEY) ||
            showLayer(POI_FESTIVAL_FILTERS_KEY) ||
            showLayer(POI_PLACES_OF_INTEREST_FILTERS_KEY)) {
            if (computedZoom < POI_ZOOM_LIMIT) {
                if (!toast.isActive(`poi_too_much`)) {
                    toastPOIId.current = toast({
                        status: 'error',
                        title: t('global.poi_too_much_dezoom'),
                        id: 'poi_too_much',
                        duration: 10000,
                    });
                }
                Object.keys(POI_FILTERS).map((filterParentKey) => {
                    Object.keys(POI_FILTERS[filterParentKey]).map((filterKey) => {
                        removeLayers(filterKey);
                    });
                });
            }
            else {
                toast.close(toastPOIId.current);
            }
        }
        else {
            toast.close(toastPOIId.current);
        }
    }, [layersToShow, computedZoom]);
    if (!map) {
        return null;
    }
    return (<>
      <MapDisplayLayer />
      {/* crm or interactive layers */}
      {streetViewMode && <StreetViewLayer />}
      <SelectedPlotsLayer />
      {showLayer(filtersKey.USER_DRAWINGS) && hasMapControl('drawing') && (<UserDrawingsLayer />)}
      {/* always displayed depending on the data, never removed */}
      {showLayer(filtersKey.FEASIBILITY_PLOTS) && <FeasibilityPlotsLayer />}
      {/* handle potential buildings and buildings */}
      <SelectedPlotsBuildingsLayer />
      <HighlightedPlotsLayer />
      {showLayer(filtersKey.CONTACT_PLOTS) && <ContactPlotsLayer />}
      {showLayer(filtersKey.PROJECT_PLOTS) && <ProjectPlotsLayer />}
      {showLayer(filtersKey.USER_PLOTS) && <UserPlotsLayer />}
      {showLayer(filtersKey.TEAM_PLOTS) && <TeamPlotsLayer />}

      {showLayer(filtersKey.USER_PROJECTS) && <UserProjectsLayer />}
      {showLayer(filtersKey.TEAM_PROJECTS) && <TeamProjectsLayer />}

      {/* search layer */}
      {showLayer(filtersKey.PLOTS_SEARCH) && <PlotsSearchLayer />}

      {/* main layers */}
      {showPLULayer && hasMainLayersTree('plu') && (<PLULayer townId={selectedTownId} pluAdjacent={false} computedZoom={computedZoom} isSelectedTown/>)}
      {showPLUGPULayer && hasMainLayersTree('plu_gpu') && (<PLUGPULayer townId={selectedTownId} computedZoom={computedZoom} isSelectedTown/>)}
      {showLayer(filtersKey.PLU_ADJACENT) && (<PLUsAdjacentLayer computedZoom={computedZoom}/>)}
      {showLayer(filtersKey.PLU_ADDITIONAL) && (<PLUAdditionalLayer computedZoom={computedZoom}/>)}
      {showLayer(filtersKey.PLOTS) && (<PlotsLayer computedZoom={computedZoom}/>)}
      {showLayer(filtersKey.PLOTS_OWNERS) && <PlotOwnerLayer />}
      {showLayer(filtersKey.CADASTRE) && (<CadastreLayer computedZoom={computedZoom}/>)}
      {showLayer(filtersKey.OLD_CADASTRE) && <OldCadastreLayer />}

      {/* urbanism layers */}
      <>
        {/* urbanism layers always showed (layerkeys from layer tree) */}
        <AirborneNoiseLayers />
        <PPRTechnologicalLayers />
        <PPRForestFiresLayer />
        <PPRNaturalLayer />
        <PPRGroundMovementsClayLayer />
        <PPRGroundMovementsEarthquakeLayer />
        <PPRGroundMovementsOtherLayer />

        {/* urbanism layers with show/hide */}
        <>
          {/* several keys */}
          {showLayer(POI_TRANSPORT_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_TRANSPORT} POIFiltersKey={POI_TRANSPORT_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_COMMERCE_AND_SERVICES_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_COMMERCE_AND_SERVICES} POIFiltersKey={POI_COMMERCE_AND_SERVICES_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_HOBBIES_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_HOBBIES} POIFiltersKey={POI_HOBBIES_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_EDUCATION_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_EDUCATION} POIFiltersKey={POI_EDUCATION_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_CULTURE_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_CULTURE} POIFiltersKey={POI_CULTURE_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_HEALTHCARE_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_HEALTHCARE} POIFiltersKey={POI_HEALTHCARE_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_EARLY_CHILDHOOD_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_EARLY_CHILDHOOD} POIFiltersKey={POI_EARLY_CHILDHOOD_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_TOURISM_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_TOURISM} POIFiltersKey={POI_TOURISM_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_PUBLIC_SERVICE_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_PUBLIC_SERVICE} POIFiltersKey={POI_PUBLIC_SERVICE_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_RISKS_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_RISKS} POIFiltersKey={POI_RISKS_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_SPORTS_ACTIVITIES_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_SPORTS_ACTIVITIES} POIFiltersKey={POI_SPORTS_ACTIVITIES_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_RECREATIONAL_ACTIVITIES_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_RECREATIONAL_ACTIVITIES} POIFiltersKey={POI_RECREATIONAL_ACTIVITIES_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_TOURISTIC_PLACES_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_TOURISTIC_PLACES} POIFiltersKey={POI_TOURISTIC_PLACES_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_FESTIVAL_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_FESTIVAL} POIFiltersKey={POI_FESTIVAL_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(POI_PLACES_OF_INTEREST_FILTERS_KEY) && (<POILayers parentFilterKey={filtersKey.POI_PLACES_OF_INTEREST} POIFiltersKey={POI_PLACES_OF_INTEREST_FILTERS_KEY} computedZoom={computedZoom}/>)}
          {showLayer(ABF_FILTERS) && <ABFLayers />}
          {showLayer(COMMERCIAL_BUILDINGS_FILTERS) && (<CommercialBuildingsLayers />)}
          {showLayer(filtersKey.LOW_ALTITUDE_SETBA) && (<LowAltitudeLayers zone={filtersKey.LOW_ALTITUDE_SETBA}/>)}
          {showLayer(filtersKey.LOW_ALTITUDE_VOLTAC) && (<LowAltitudeLayers zone={filtersKey.LOW_ALTITUDE_VOLTAC}/>)}
          {showLayer(filtersKey.LOW_ALTITUDE_RTBA) && (<LowAltitudeLayers zone={filtersKey.LOW_ALTITUDE_RTBA}/>)}
          {showLayer(filtersKey.LOW_ALTITUDE_ASBA) && (<LowAltitudeLayers zone={filtersKey.LOW_ALTITUDE_ASBA}/>)}
          {AERONAUTIC_FILTERS && <AeronauticLayers />}
          {showLayer(filtersKey.AERONAUTIC_SERVITUDE) && (<AeronauticServitudeLayers />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU) && (<ElectricalNetworkCapareseauLayers />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON) && (<ElectricalNetworksLayers filterName={filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON}/>)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL) && (<ElectricalNetworksBTAerialLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_BT_UNDERGROUND) && (<ElectricalNetworksBTUndergroundLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL) && (<ElectricalNetworksHTAAerialLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_UNDERGROUND) && (<ElectricalNetworksHTAUndergroundLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL) && (<ElectricalNetworksHTBAerialLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND) && (<ElectricalNetworksHTBUndergroundLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT) && (<ElectricalNetworksDistributionPostLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT) && (<ElectricalNetworksByTypeLayers filterKey={filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT}/>)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_DATA_LINE_AND_POST) && (<ElectricalNetworksByTypeLayers filterKey={filtersKey.ELECTRICAL_NETWORKS_DATA_LINE_AND_POST}/>)}
          {showLayer(RAILWAY_NETWORK_FILTERS) && <RailwayNetworksLayers />}
          {showLayer(BUILDING_PERMIT_NEW_MARKET_FILTERS) && (<BuildingPermitNewMarketLayers />)}

          {/* one key */}
          {showLayer(filtersKey.ABF_HISTORICAL_BUILDING) && (<ABFHistoricalBuildingLayer />)}
          {showLayer(filtersKey.ACTIVE_EXPLOITATIONS) && (<ActiveExploitationsLayer />)}
          {showLayer(filtersKey.ANRU_EXTENDS) && <ANRUExtendsLayer />}
          {showLayer(filtersKey.ANRU) && <ANRULayer />}
          {showLayer(filtersKey.BIRDS_ZICO) && <BirdsZICOLayer />}
          {showLayer(filtersKey.CITIES_OF_TOMORROW) && (<CitiesOfTomorrowLayer />)}
          {showLayer(filtersKey.COMMERCIAL_LINE) && <CommercialLineLayer />}
          {showLayer(filtersKey.COMMERCIAL_SCOT) && <CommercialScotLayer />}
          {showLayer(filtersKey.CONTAMINATED_SITES_AND_SOILS) && (<ContaminatedSitesAndSoilsLayer />)}
          {showLayer(filtersKey.DANGER_ZONE_FLOOD) && (<DangerZoneLayer filterKey={filtersKey.DANGER_ZONE_FLOOD}/>)}
          {showLayer(filtersKey.DANGER_ZONE_AVALANCHE) && (<DangerZoneLayer filterKey={filtersKey.DANGER_ZONE_AVALANCHE}/>)}
          {showLayer(filtersKey.DANGER_ZONE_LANDSLIDE) && (<DangerZoneLayer filterKey={filtersKey.DANGER_ZONE_LANDSLIDE}/>)}
          {showLayer(filtersKey.DANGER_ZONE_SLIDING) && (<DangerZoneLayer filterKey={filtersKey.DANGER_ZONE_SLIDING}/>)}
          {showLayer(filtersKey.NOISE_SENSIBILITY) && <NoiseSensibilityLayer />}
          {showLayer(filtersKey.WASTE_DMA) && (<WasteLayer filterKey={filtersKey.WASTE_DMA}/>)}
          {showLayer(filtersKey.WASTE_ISDD) && (<WasteLayer filterKey={filtersKey.WASTE_ISDD}/>)}
          {showLayer(filtersKey.WASTE_ISDI) && (<WasteLayer filterKey={filtersKey.WASTE_ISDI}/>)}
          {showLayer(filtersKey.WASTE_ISDND) && (<WasteLayer filterKey={filtersKey.WASTE_ISDND}/>)}
          {showLayer(filtersKey.DEVELOPMENT_AREA) && <DevelopmentAreaLayer />}
          {showLayer(filtersKey.FIBER_DEPLOYMENT_2020) && (<FiberDeployment2020Layer />)}
          {showLayer(filtersKey.INACTIVE_EXPLOITATIONS) && (<InactiveExploitationsLayer />)}
          {showLayer(filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS) && (<LogisticsBuildingsLayers />)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_WITHOUT_EMPLOYEE) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_WITHOUT_EMPLOYEE}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_ONE_TO_TEN_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_ONE_TO_TEN_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_TEN_TO_FIFTY_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_TEN_TO_FIFTY_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_FIFTY_TO_ONE_HUNDRED_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_FIFTY_TO_ONE_HUNDRED_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_ONE_HUNDRED_TO_TWO_HUNDRED_AND_FIFTY_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_ONE_HUNDRED_TO_TWO_HUNDRED_AND_FIFTY_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_TWO_HUNDRED_AND_FIFTY_TO_ONE_THOUSAND_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_TWO_HUNDRED_AND_FIFTY_TO_ONE_THOUSAND_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_ONE_THOUSAND_TO_FIVE_THOUSAND_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_ONE_THOUSAND_TO_FIVE_THOUSAND_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES}/>)}
          {showLayer(filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES) && (<OfficeBuildingsLayers filterName={filtersKey.OFFICE_BUILDINGS_MORE_THAN_FIVE_THOUSAND_EMPLOYEES}/>)}
          {showLayer(filtersKey.PUBLIC_FOREST) && <PublicForestLayer />}
          {showLayer(filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE) && <CompensatoryMeasuresForBiodiversityDamage />}
          {showLayer(ZAER_FILTERS) && <ZAERLayers />}
          {showLayer(filtersKey.BUILDING_CONSUMPTION) && (<BuildingConsumptionLayer />)}
          {showLayer(filtersKey.OSM_BIOGAS) && (<OSMEnergyLayers filterKey={filtersKey.OSM_BIOGAS}/>)}
          {showLayer(filtersKey.OSM_BIOMASS) && (<OSMEnergyLayers filterKey={filtersKey.OSM_BIOMASS}/>)}
          {showLayer(filtersKey.OSM_GEOTHERMAL) && (<OSMEnergyLayers filterKey={filtersKey.OSM_GEOTHERMAL}/>)}
          {showLayer(filtersKey.OSM_SOLAR) && (<OSMEnergyLayers filterKey={filtersKey.OSM_SOLAR}/>)}
          {showLayer(filtersKey.OSM_TIDAL) && (<OSMEnergyLayers filterKey={filtersKey.OSM_TIDAL}/>)}
          {showLayer(filtersKey.OSM_WIND) && (<OSMEnergyLayers filterKey={filtersKey.OSM_WIND}/>)}
          {showLayer(filtersKey.CHARGING_STATION) && <ChargingStationLayers />}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_AOC_WINE) && (<PhotovoltaicNotProneAreaAocWineLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2013) && (<PhotovoltaicNotProneAreaRpgTiles year={2013}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2014) && (<PhotovoltaicNotProneAreaRpgTiles year={2014}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2015) && (<PhotovoltaicNotProneAreaRpgTiles year={2015}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2016) && (<PhotovoltaicNotProneAreaRpgTiles year={2016}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2017) && (<PhotovoltaicNotProneAreaRpgTiles year={2017}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2018) && (<PhotovoltaicNotProneAreaRpgTiles year={2018}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2019) && (<PhotovoltaicNotProneAreaRpgTiles year={2019}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2020) && (<PhotovoltaicNotProneAreaRpgTiles year={2020}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2021) && (<PhotovoltaicNotProneAreaRpgTiles year={2021}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2022) && (<PhotovoltaicNotProneAreaRpgTiles year={2022}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2023) && (<PhotovoltaicNotProneAreaRpgTiles year={2023}/>)}
          {/*ATTENTION Seul le dernier RPG a un layer les autre des tiles*/}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST) && (<PhotovoltaicNotProneAreaRpgLayer />)}
          {showLayer(filtersKey.AGRICULTURE_REARING_COMPANY) && (<AgricultureRearingCompanyLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE) && (<PhotovoltaicNotProneAreaPrairieLayer />)}
          {showLayer(filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS) && (<ElectricalNetworksSourcePostLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_PRONE_AREA_MILITARY_GROUND) && (<PhotovoltaicProneAreaMilitaryGroundLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_PRONE_AREA_WETLAND) && (<PhotovoltaicProneAreaWetlandLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_PRONE_AREA_STATIONS) && (<PhotovoltaicProneAreaStationsLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_PRONE_AREA_PORTS) && (<PhotovoltaicProneAreaPortsLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_PRONE_AREA_AIRPORTS) && (<PhotovoltaicProneAreaAirportsLayer />)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS) && <PhotovoltaicProneAreaAbandonedMotorwaysLayer />}
          {showLayer(filtersKey.PARKING_SPACE_500_750) && (<ParkingSpaceLayer filterKey={filtersKey.PARKING_SPACE_500_750}/>)}
          {showLayer(filtersKey.PARKING_SPACE_750_1000) && (<ParkingSpaceLayer filterKey={filtersKey.PARKING_SPACE_750_1000}/>)}
          {showLayer(filtersKey.PARKING_SPACE_1000_1500) && (<ParkingSpaceLayer filterKey={filtersKey.PARKING_SPACE_1000_1500}/>)}
          {showLayer(filtersKey.PARKING_SPACE_1500_5000) && (<ParkingSpaceLayer filterKey={filtersKey.PARKING_SPACE_1500_5000}/>)}
          {showLayer(filtersKey.PARKING_SPACE_5000_sup) && (<ParkingSpaceLayer filterKey={filtersKey.PARKING_SPACE_5000_sup}/>)}
          {showLayer(filtersKey.PHOTOVOLTAIC_NOT_PRONE_LAND_UNIT_AREA_PARKING_SPACE_500) && <PhotovoltaicProneLandUnitAreaParkingSpace500Layer />}
          {showLayer(filtersKey.SOLAR_ROOF_POTENTIAL) && (<SolarRoofPotentialLayer />)}
          {showLayer(filtersKey.INCLINE_10) && <Incline10Layer />}
          {showLayer(filtersKey.INCLINE_30) && <Incline30Layer />}
          {showLayer(filtersKey.AVERAGE_SOLAR_IRRADIATION) && (<AverageSolarIrradiationLayer />)}
          {showLayer(filtersKey.MRAE) && <MRAELayer />}
          {showLayer(filtersKey.DREAL) && <DREALLayer />}
          {showLayer(filtersKey.REAL_ESTATE_PROMOTIONS) && (<RealEstatePromotionsLayer />)}
          {showLayer(filtersKey.PLANNING_AND_STUDIES) && (<PlanningAndStudiesLayer />)}
          {showLayer(filtersKey.SENIOR_RESIDENCES) && <SeniorResidencesLayer />}
          {showLayer(filtersKey.STUDENT_RESIDENCES) && (<StudentResidencesLayer />)}
          <ThematicMapLayers />
          <ThematicMapRentLayers layerFilterKey={filtersKey.THEMATIC_MAP_RENT_APARTMENT}/>
          <ThematicMapRentLayers layerFilterKey={filtersKey.THEMATIC_MAP_RENT_HOUSE}/>
          {showLayer(filtersKey.WIND_FARM) && <WindFarmLayer />}
          {showLayer(filtersKey.WIND_TOWER) && <WindTowerLayer />}
          {showLayer(filtersKey.WIND_POTENTIAL) && <WindPotentialLayer />}
          {showLayer(filtersKey.WIND_POTENTIAL_50) && <WindPotential50Layer />}
          {showLayer(filtersKey.WIND_POTENTIAL_100) && (<WindPotential100Layer />)}
          {showLayer(filtersKey.WIND_POTENTIAL_140) && (<WindPotential140Layer />)}
          {showLayer(filtersKey.WIND_POTENTIAL_160) && (<WindPotential160Layer />)}
          {showLayer(filtersKey.WIND_CONSTRAINT_ROAD) && (<WindConstraintRoadLayer />)}
          {showLayer(filtersKey.WIND_CONSTRAINT_INCLINE) && (<WindConstraintInclineLayer />)}
          {showLayer(filtersKey.WIND_UNESCO_NATURAL) && (<WindUNESCONaturalLayer />)}
          {showLayer(filtersKey.WIND_LARGE_FRENCH_SITE) && (<WindLargeFrenchSiteLayer />)}
          {showLayer(filtersKey.WIND_ZAER_EXCLUSION) && (<WindZAERExclusionLayer />)}
          {showLayer(filtersKey.WIND_CONSTRAINT_HOUSING) && (<WindConstraintHousingLayer />)}
          {showLayer(filtersKey.WIND_CONSTRAINT_RAILWAY) && (<WindConstraintRailwayLayer />)}
          {showLayer(filtersKey.DRINKING_WATER_CAPTURE) && (<DrinkingWaterCaptureLayer />)}
          {showLayer(WIND_BIRD_FILTERS) && <WindBirdLayers />}
          {showLayer(filtersKey.WIND_MRAE) && <WindMRAELayer />}
          {showLayer(filtersKey.RADAR) && <RadarLayer />}
          {showLayer(filtersKey.CELL_TOWER) && <CellTowerLayer />}
          {showLayer(filtersKey.OPTICAL_FIBER) && (<OpticalFiberLayer computedZoom={computedZoom}/>)}
          {showLayer(filtersKey.OLD_MARKET_BUILDABLE_LOTS_IDF) && (<OldMarketBuildableLotsIdfLayer />)}
          {showLayer(filtersKey.OLD_MARKET_DVF) && (<OldMarketDvfLayer computedZoom={computedZoom}/>)}
          {showLayer(filtersKey.NEW_MARKET_PROGRAMS) && (<NewMarketPricesLayer />)}
          {showLayer(filtersKey.PREEMPTION) && <PreemptionLayer />}
          {showLayer(filtersKey.EPF_LAND_CONTROL) && <EPFLandControlLayer />}
          {showLayer(filtersKey.EPF_LAND_WATCH) && <EPFLandWatchLayer />}
          {showLayer(filtersKey.EPF_LAND_CONTROL_AND_WATCH) && (<EPFLandControlAndWatchLayer />)}
          {showLayer(filtersKey.REQUALIFICATION_OPERATION) && (<EPFOrcodinLayer />)}
          {showLayer(filtersKey.INCREASED_REAL_ESTATE_TAXES) && (<IncreasedRealEstateTaxesLayer />)}
          {showLayer(filtersKey.LANDSCAPING_PROJECTS) && (<LandscapingProjectsLayer />)}
          {showLayer(filtersKey.ZAE) && <ZAELayer />}
          {showLayer(filtersKey.OIN) && <OINLayer />}
          {showLayer(filtersKey.ZAC) && <ZACLayer />}
          {showLayer(filtersKey.HEATING_NETWORK) && <HeatingNetworkLayer />}
          {showLayer(ROAD_RAILWAY_FILTERS) && <RoadRailwayNoiseLayer />}
          {showLayer(filtersKey.SENSITIVE_ARCHEOLOGICAL_AREA) && (<SensitiveArcheologicalAreaLayer />)}
          {showLayer(filtersKey.PRESUMED_ARCHEOLOGICAL_AREA) && (<PresumedArcheologicalAreaLayer />)}
          {showLayer(filtersKey.POTENTIALLY_CONTAMINATED_SITES_AND_SOILS) && (<PotentiallyContaminatedSitesAndSoilsLayer />)}
          {showLayer(filtersKey.UNDERGROUND_CAVITIES) && (<UndergroundCavitiesLayer />)}
          {showLayer(filtersKey.STONE_QUARRY) && <StoneQuarryLayer />}
          {showLayer(filtersKey.SHRINKAGE_SWELLING_OF_CLAYS) && (<ShrinkageSwellingOfClaysLayer />)}
          {showLayer(filtersKey.TERRITORIAL_DEVELOPMENT_AGREEMENT) && (<TerritorialDevelopmentAgreementLayer />)}
          {showLayer(filtersKey.ROAD_PROJECT_HIGHWAY) && (<RoadProjectHighwayLayer />)}
          {showLayer(filtersKey.ROAD_PROJECT_MULTIMODALE) && (<RoadProjectMultimodaleLayer />)}
          {showLayer(filtersKey.ROAD_PROJECT_CROSSING) && (<RoadProjectCrossingLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_POTENTIAL_LIMIT) && (<RegionalPlanPotentialLimitLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_NEW_URBAN_AREA) && (<RegionalPlanNewUrbanAreaLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_TRANSPORT_INFRASTRUCTURE) && (<RegionalPlanTransportInfrastructureLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_URBAN_FRONT) && (<RegionalPlanUrbanFrontLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_GREEN_AREA) && (<RegionalPlanGreenAreaLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_URBAN_AREA) && (<RegionalPlanUrbanAreaLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_WOODLAND_AREA) && (<RegionalPlanWoodlandAreaLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_CONTINUITIES) && (<RegionalPlanContinuitiesLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_WATER_AREA) && (<RegionalPlanWaterAreaLayer />)}
          {showLayer(filtersKey.REGIONAL_PLAN_LOGISTIC_FRAME) && (<RegionalPlanLogisticFrameLayer />)}
          {showLayer(filtersKey.ECONOMICAL_SCOT) && <EconomicalScotLayer />}
          {showLayer(filtersKey.URBAN_SCOT) && <UrbanScotLayer />}
          {showLayer(filtersKey.PADDUC_NO_BUILDING_ZONE) && (<PadducNoBuildingLayer />)}
          {showLayer(filtersKey.PADDUC_LIMITED_BUILDING_ZONE) && (<PadducLimitedBuildingZoneLayer />)}
          {showLayer(filtersKey.PADDUC_VARIOUS_REQUIREMENTS) && (<PadducVariousRequirementLayer />)}
          {showLayer(filtersKey.PINEL_BRETAGNE) && <PinelBretagneLayer />}
          {showLayer(filtersKey.HEART_OF_TOWN) && <HeartOfTownLayer />}
          {showLayer(filtersKey.PPR_ICPE) && <ICPELayer />}
          {showLayer(filtersKey.ORT) && <OrtLayer />}
          {showLayer(filtersKey.NPNRU) && <NPNRULayer />}
          {showLayer(filtersKey.PPR_EARTHQUAKE_HAZARD) && (<PPREarthquakeHazardLayer />)}
          {showLayer(filtersKey.SCOT) && <ScotLayer />}
          {showLayer(filtersKey.SRCE) && <SRCELayer />}
          {showLayer(filtersKey.PPR_FIRE_HYDRANTS) && <PPRFireHydrantsLayer />}
          {showLayer(filtersKey.PARKING_SPACE_EXEMPTION_CURRENT) && (<ParkingSpaceCurrentExemptionLayer />)}
          {showLayer(filtersKey.PARKING_SPACE_EXEMPTION_FUTURE) && (<ParkingSpaceFutureExemptionLayer />)}
          {showLayer(filtersKey.PPR_LANDSLIDE) && <PPRLandslideLayer />}
          {showLayer(filtersKey.PPR_FLOODING_STRONG_HAZARD) && (<PPRFloodingStrongHazardLayer />)}
          {showLayer(filtersKey.PPR_FLOODING_MEDIUM_HAZARD) && (<PPRFloodingMediumHazardLayer />)}
          {showLayer(filtersKey.PPR_FLOODING_LOW_HAZARD) && (<PPRFloodingLowHazardLayer />)}
          {showLayer(filtersKey.PPR_FLOODING_LOW_CONTINGENCY_BUILDING_ZONE) && (<PPRFloodingLowContingencyBuildingZoneLayer />)}
          {showLayer(filtersKey.PPR_FLOODING_LIMITED_BUILDING_ZONE) && (<PPRFloodingLimitedBuildingZoneLayer />)}
          {showLayer(filtersKey.PPR_FLOODING_NO_BUILDING_ZONE) && (<PPRFloodingNoBuildingZoneLayer />)}
          {showLayer(filtersKey.RAIN_ZONE) && <RainZoneLayer />}
          {showLayer(filtersKey.LIMITED_BUILDING_ZONE_RESOURCES_AND_FACILITIES) && <LimitedBuildingZoneResourcesAndFacilitiesLayer />}
          {showLayer(filtersKey.NO_BUILDING_ZONE_RESOURCES_AND_FACILITIES) && (<NoBuildingZoneResourcesAndFacilitiesLayer />)}
          {showLayer(filtersKey.RISING_WATER_TABLE_AREA) && (<RisingWaterTableAreaLayer />)}
          {showLayer(filtersKey.ZNIEFF_TYPE2) && <ZnieffType2Layer />}
          {showLayer(filtersKey.WETLAND_AREA) && <WetlandAreaLayer />}
          {showLayer(filtersKey.WETLAND_AREA_POTENTIALLY) && (<WetlandAreaPotentiallyLayers />)}
          {showLayer(filtersKey.NO_BUILDING_AREA_ENVIRONMENT) && (<NoBuildingAreaEnvironmentLayer />)}
          {showLayer(filtersKey.LIMITED_BUILDING_AREA_ENVIRONMENT) && (<LimitedBuildingAreaEnvironmentLayer />)}
          {showLayer(filtersKey.BIOLOGICAL_RESERVE) && (<BiologicalReserveLayer />)}
          {showLayer(filtersKey.BIOTOP_PROTECTION) && <BiotopProtectionLayer />}
          {showLayer(filtersKey.HUNTING_RESERVE) && <HuntingReserveLayer />}
          {showLayer(filtersKey.NATIONAL_RESERVE) && <NationalReserveLayer />}
          {showLayer(filtersKey.NATURA_2000_SIC) && <Natura2000SICLayer />}
          {showLayer(filtersKey.NATURA_2000_ZPS) && <Natura2000ZPSLayer />}
          {showLayer(filtersKey.REGIONAL_RESERVE) && <RegionalReserveLayer />}
          {showLayer(filtersKey.NATIONAL_PARK) && <NationalParkLayer />}
          {showLayer(filtersKey.MARIN_PARK) && <MarinParkLayer />}
          {showLayer(filtersKey.REGIONAL_PARK) && <RegionalParkLayer />}
          {showLayer(filtersKey.BIOSPHERE_RESERVE) && <BiosphereReserveLayer />}
          {showLayer(filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE) && (<RegionalNaturalSpaceConservatoireLayer />)}
          {showLayer(filtersKey.COASTLINE_CONSERVATOIRE) && (<CoastlineConservatoireLayer />)}
          {showLayer(filtersKey.SENSITIVE_NATURAL_SPACE) && (<SensitiveNaturalSpaceLayer />)}
          {showLayer(filtersKey.NO_BUILDING_AREA_ENVIRONMENT_OTHER) && (<NoBuildingAreaEnvironmentOtherLayer />)}
          {showLayer(filtersKey.QUARRY_OR_WASTE) && <QuarryOrWasteLayer />}
          {showLayer(filtersKey.ECOLOGICAL_CONTINUUM) && (<EcologicalContinuumLayer />)}
          {showLayer(filtersKey.FLOOD_ZONE) && <FloodZoneLayer />}
          {showLayer(filtersKey.EASEMENT) && <EasementLayer />}
          {showLayer(filtersKey.MISC_EASEMENTS) && <MiscEasementsLayer />}
          {showLayer(filtersKey.SPECIFIC_SETTING) && <SpecificSettingLayer />}
          {showLayer(filtersKey.PARKING) && <ParkingLayer />}
          {showLayer(filtersKey.TRAM_FOOTPRINT) && <TramFootprintLayer />}
          {showLayer(filtersKey.VOLUMETRY) && <VolumetryLayer />}
          {showLayer(filtersKey.RECONSTRUCTION_OR_DEMOLITION_PROVISION) && (<ReconstructionOrDemolitionProvisionLayer />)}
          {showLayer(filtersKey.PRINCIPLE_OF_ACCESS) && (<PrincipleOfAccessLayer />)}
          {showLayer(filtersKey.PRESERVED_LANDSCAPE) && (<PreservedLandscapeLayer />)}
          {showLayer(filtersKey.SETBACK) && <SetbackLayer />}
          {showLayer(filtersKey.PROTECTED_WOODLAND) && (<ProtectedWoodlandLayer />)}
          {showLayer(filtersKey.MAXIMUM_FOOTPRINT) && <MaximumFootprintLayer />}
          {showLayer(filtersKey.MAXIMUM_HEIGHT) && <MaximumHeightLayer />}
          {showLayer(filtersKey.GREEN_SPACE_COEFF) && <GreenSpaceCoeffLayer />}
          {showLayer(filtersKey.MISC_LIMITATIONS) && <MiscLimitationsLayer />}
          {showLayer(filtersKey.LIMITED_SIZE_AND_ACCOMMODATION_CAPACITY_AREA) && <LimitedSizeAndAccommodationCapacityAreaLayer />}
          {showLayer(filtersKey.STAY_OF_PROCEEDINGS) && (<StayOfProceedingsLayer />)}
          {showLayer(filtersKey.TOWNS) && <TownsLayer />}
          {showLayer(filtersKey.TOWN) && (<TownLayer selectedTown={selectedTown}/>)}
          {showLayer(filtersKey.ZNIEFF_TYPE1) && <ZnieffType1Layer />}
          {showLayer(filtersKey.SOCIAL_MIXING) && <SocialMixingLayer />}
          {showLayer(filtersKey.LAYOUT_ORIENTATION) && (<LayoutOrientationLayer />)}
          {showLayer(filtersKey.PATRIMONY) && <PatrimonyLayer />}
          {showLayer(filtersKey.RESERVED_AREA) && <ReservedAreaLayer />}
          {showLayer(filtersKey.RIGHT_OF_FIRST_REFUSAL) && (<RightOfFirstRefusalLayer />)}
          {showLayer(filtersKey.OVERALL_DEVELOPMENT_PLAN) && (<OverallDevelopmentPlanLayer />)}
          {showLayer(filtersKey.PARTNERSHIP_URBAN_PROJECT) && (<PartnershipUrbanProjectLayer />)}
          {showLayer(filtersKey.VIEW_ANGLES_PROTECTION) && (<ViewAnglesProtectionLayer />)}
          {showLayer(filtersKey.HAZMAT_TRANSPORT_PIPELINES) && (<HazmatTransportPipelinesLayer />)}
          {showLayer(filtersKey.TERMITES) && <TermitesLayer />}
          {showLayer(filtersKey.RADIOACTIVE_GAS) && <RadioactiveGasLayer />}
          {showLayer(filtersKey.NEIGHBORHOODS) && <NeighborhoodsLayer />}
          {showLayer(filtersKey.TOWNS_LIMITS) && <TownsLimitsLayer />}
          {showLayer(filtersKey.EPCI) && <EPCILayer />}
          {showLayer(filtersKey.VOTE_PLACE) && <VotePlaceLayer />}
          {showLayer(filtersKey.ALIGNMENT) && <AlignmentLayer />}
          {showLayer(filtersKey.SENSITIVE_NATURAL_AREA) && (<SensitiveNaturalAreaLayer />)}
          {showLayer(filtersKey.INSTALLATION_LIMIT) && (<InstallationLimitLayer />)}
          {showLayer(filtersKey.ROAD_EASEMENT) && <RoadEasementLayer />}
          {showLayer(filtersKey.STUDY_PERIMETER) && <StudyPerimeterLayer />}
          {showLayer(filtersKey.URBAN_SEQUENCE_PROTECTION) && (<UrbanSequenceProtectionLayer />)}
          {showLayer(filtersKey.PROJECT_AREA) && <ProjectAreaLayer />}
          {showLayer(filtersKey.NON_AEDIFICANDI_AREA) && (<NonAedificandiAreaLayer />)}
          {showLayer(filtersKey.PEDESTRIAN_CONNECTIONS) && (<PedestrianConnectionsLayer />)}
          {showLayer(filtersKey.LAND_MOVEMENT) && <LandMovementLayer />}
          {showLayer(filtersKey.COASTAL_PROTECTION) && (<CoastalProtectionLayer />)}
          {showLayer(filtersKey.TECHNOLOGICAL_RISK) && (<TechnologicalRiskLayer />)}
          {showLayer(filtersKey.EASEMENT_FOR_NATURAL_AND_URBAN_SITES_PROTECTION) && <EasementForNaturalAndUrbanSitesProtectionLayer />}
          {showLayer(filtersKey.SUBMERSIBLE_EASEMENT) && (<SubmersibleEasementLayer />)}
          {showLayer(filtersKey.TUNNEL_EASEMENT) && <TunnelEasementLayer />}
          {showLayer(filtersKey.MISC_EASEMENT) && <MiscEasementLayer />}
          {showLayer(filtersKey.OTHER_EASEMENT) && <OtherEasementLayer />}
          {showLayer(filtersKey.NOISE_AREA) && <NoiseAreaLayer />}
          {showLayer(filtersKey.ADVERTISING_AREA) && <AdvertisingAreaLayer />}
          {showLayer(filtersKey.FLOODING_AREA) && <FloodingAreaLayer />}
          {showLayer(filtersKey.NATURAL_AREA_OF_ECOLOGICAL_INTEREST) && (<NaturalAreaOfEcologicalInterestLayer />)}
          {showLayer(filtersKey.WET_ZONE) && <WetZoneLayer />}
          {showLayer(filtersKey.PROHIBITED_CONSTRUCTION) && (<ProhibitedConstructionLayer />)}
          {showLayer(filtersKey.ABF_DOC_URBA) && <ABFDocUrbaLayer />}
          {showLayer(filtersKey.CONSTRUCTABLE_STRIP) && (<ConstructableStripLayer />)}
          {showLayer(filtersKey.ECOLOGICAL_CONTINUITY_ELEMENTS) && (<EcologicalContinuityElementsLayer />)}
          {showLayer(filtersKey.NATURAL_PERI_URBAN_AREA_PROTECTION_PERIMETER) && <NaturalPeriUrbanAreaProtectionPerimeterLayer />}
          {showLayer(filtersKey.STAY_OF_PROCEEDINGS_PERIMETER) && (<StayOfProceedingsPerimeterLayer />)}
          {showLayer(filtersKey.RICH_SOIL_PROTECTED_AREA) && (<RichSoilProtectedAreaLayer />)}
          {showLayer(filtersKey.FROST_PERIMETER) && <FrostPerimeterLayer />}
          {showLayer(filtersKey.WATER_PIPELINE) && <WaterPipelineLayer />}
          {showLayer(filtersKey.WOODLAND_STRIP) && <WoodlandStripLayer />}
          {showLayer(filtersKey.ARCHITECTURAL_DISCIPLINE) && (<ArchitecturalDisciplineLayer />)}
          {showLayer(filtersKey.WOODLAND_AREA) && <WoodlandAreaLayer />}
          {showLayer(filtersKey.CONSTRUCTABILITY_LIMIT) && (<ConstructabilityLimitLayer />)}
          {showLayer(filtersKey.TEN_YEARS_OLD_HOUSING_WITH_RETAINED_REGULATION) && <TenYearsOldHousingWithRetainedRegulationLayer />}
          {showLayer(filtersKey.NOISE_EXPOSURE_PLAN) && (<NoiseExposurePlanLayer />)}
          {showLayer(filtersKey.SPECIAL_ARCHITECTURAL_REQUIREMENTS) && (<SpecialArchitecturalRequirementsLayer />)}
          {showLayer(filtersKey.POLLUTION_RISKS) && <PollutionRisksLayer />}
          {showLayer(filtersKey.FLOODING_RISKS) && <FloodingRisksLayer />}
          {showLayer(filtersKey.PVR_AREAS) && <PVRAreasLayer />}
          {showLayer(filtersKey.AGRICULTURAL_USE_RESERVED_AREAS) && (<AgriculturalUseReservedAreasLayer />)}
          {showLayer(filtersKey.AGRICULTURAL_USE_RESERVED_AREAS_BIS) && (<AgriculturalUseReservedAreasBisLayer />)}
          {showLayer(filtersKey.PROTECTED_OR_AT_RISK_SITES) && (<ProtectedOrAtRiskSitesLayer />)}
          {showLayer(filtersKey.SEWERAGE_ZONING) && <SewerageZoningLayer />}
          {showLayer(filtersKey.EXTERNAL_ASPECT) && <ExternalAspectLayer />}
          {showLayer(filtersKey.ENERGY_PERFORMANCE_AREA) && (<EnergyPerformanceAreaLayer />)}
          {showLayer(filtersKey.PUBLIC_ELECTRICITY_NETWORKS) && (<PublicElectricityNetworksLayer />)}
          {showLayer(filtersKey.INCREASE_IN_BUILDABLE_VOLUMES) && (<IncreaseInBuildableVolumesLayer />)}
          {showLayer(filtersKey.DECLARATION_OF_PUBLIC_INTEREST) && (<DeclarationOfPublicInterestLayer />)}
          {showLayer(filtersKey.PLOT_UPDATED_BUILDING_POTENTIAL) && (<PlotUpdatedBuildingPotentialLayer />)}
          {showLayer(filtersKey.ALLOWED_LEVELS_DECREASED) && (<AllowedLevelsDecreasedLayer />)}
          {showLayer(filtersKey.PLOT_ELEVATION_POSSIBLE) && (<PlotElevationPossibleLayer />)}
          {showLayer(filtersKey.PRELOCATION) && <PrelocationLayer />}
          {showLayer(filtersKey.FIRE_HAZARD) && <FireHazardLayer />}
          {showLayer(filtersKey.PROTECTED_CULTIVATED_LAND) && (<ProtectedCultivatedLandLayer />)}
          {showLayer(filtersKey.ORFEL) && <OrfelLayer />}
          {showLayer(filtersKey.WASTELAND) && <WastelandLayer />}
          {showLayer(filtersKey.USER_POIS) && <UserPOIsLayer />}

          {showLayer(filtersKey.BANKRUPT_COMPANIES_PLOTS) && (<BankruptCompaniesPlotsLayer />)}
          {showLayer(filtersKey.COMPANY_OWNED_PLOTS) && (<CompanyOwnedPlotsLayer />)}
          {showLayer(RETAIL_FILTERS) && <RetailLayer />}
          {showLayer(filtersKey.CDAC) && <CDACLayer />}
          {showLayer(filtersKey.LEVEL_CURVE) && <LevelCurveLayer />}
          {showLayer(filtersKey.DPE_OLD) && <DPEOldLayer />}
          {showLayer(filtersKey.DPE_NEW) && <DPENewLayer />}
          {showLayer(filtersKey.DPE_TERTIARY) && <DPETertiaryLayer />}
          {showLayer(filtersKey.BODACC_BUSINESS) && <BODACCBusinessLayer />}
          {showLayer(filtersKey.NATIONAL_MAJOR_PROJECT) && (<NationalMajorProjectLayer />)}
          {showLayer(filtersKey.MAP_PLOTS) && <MapPlotsLayer />}
          {showLayer(filtersKey.PLOT_SOURCE_POST) && <PlotSourcePostLayer />}
        </>
      </>
    </>);
};
export default Layers;
