import i18n from 'i18n';
export const SITE_ID_ENERGY = '130';
export const SITE_ID_GESTION = '137';
export const SITE_ID_SUISSE = '38';
export const SITE_ID_PV = '134';
export const STORAGE_LANG_KEY = 'lang'; // a changer dans i18n.ts (conflit car i18n est appelé dans constante.tsx, on ne peut pas appelé constant dans i18n.tsx)
import { FlagArchived, FlagFavorite, FlagNegotiation, FlagSigned, FlagStandBy, FlagToContact, } from 'images/flags';
export const HEIGHT_LEGEND_ICON = '1.8rem';
export const DEFAULT_TEMPLATE_FILE_COURIER = '/model/2024_Modele_KelFoncier_de_courrier_de_publipostage.docx';
export const PLOT_STATUS_PRIORITY = 1;
export const FROM_PLOT = 'from_plot';
export const FROM_CONTACT = 'from_contact';
export const FROM_COURIER = 'from_courier';
export const FROM_FILTER = 'from_filter';
export const FROM_PLOT_RULES = 'from_plot_rules';
export const FROM_GOOGLE = 'google';
export const FROM_CLICK = 'click';
export const FROM_LAND_POINT = 'land_point';
export const FROM_PLOT_SUMMARY = 'plot_summary';
export const ACTION_CREATE = 'create';
export const ACTION_EDIT = 'edit';
export const SPACING_PANEL = 6;
export const PLOT_STATUS_DEFAULT = 1;
export const PLOT_STATUS_VALUES = {
    1: { label: 'plot.status.favorite', color: '#F58220', legendOrder: 4 },
    2: { label: 'plot.status.to_contact', color: '#FECA0A', legendOrder: 3 },
    3: { label: 'plot.status.negotiation', color: '#7FC241', legendOrder: 2 },
    4: { label: 'plot.status.signed', color: '#008B4C', legendOrder: 1 },
    5: { label: 'plot.status.archived', color: '#C4C6D7', legendOrder: 6 },
    6: { label: 'plot.status.transferred', color: '#C4C6D7', legendOrder: 7 },
    7: { label: 'plot.status.standby', color: '#EF3E22', legendOrder: 5 },
    8: { label: 'plot.status.in_progress', color: '#C4C6D7', legendOrder: 8 },
};
export const PLOT_STATUS_VALUES_WITH_NONE = {
    0: { label: 'plot.status.none', color: '#FFFFFF', legendOrder: 9 },
    ...PLOT_STATUS_VALUES,
};
export const PLOT_STATUS_OPTIONS = Object.keys(PLOT_STATUS_VALUES)
    .map((key) => ({
    value: parseInt(key),
    label: i18n.t(PLOT_STATUS_VALUES[key].label),
    color: PLOT_STATUS_VALUES[key].color,
    legendOrder: PLOT_STATUS_VALUES[key].legendOrder,
}))
    .sort((a, b) => (a.legendOrder > b.legendOrder ? 1 : -1));
export const PROJECT_STATUS_DEFAULT = 1;
// See Project.php
export const PROJECT_STATUS_VALUES = {
    1: {
        label: 'tabs.project.status_list.negotiation',
        color: '#F58220',
        legendOrder: 6,
        image: FlagFavorite,
    },
    2: {
        label: 'tabs.project.status_list.valid_development',
        color: '#FECA0A',
        legendOrder: 5,
        image: FlagToContact,
    },
    3: {
        label: 'tabs.project.status_list.commitment',
        color: '#7FC241',
        legendOrder: 4,
        image: FlagNegotiation,
    },
    4: {
        label: 'tabs.project.status_list.deposit_permit',
        color: '#008B4C',
        legendOrder: 3,
        image: FlagSigned,
    },
    5: {
        label: 'tabs.project.status_list.allowed_permit',
        color: '#008B4C',
        legendOrder: 2,
        image: FlagSigned,
    },
    6: {
        label: 'tabs.project.status_list.purged_permit',
        color: '#008B4C',
        legendOrder: 1,
        image: FlagSigned,
    },
    7: {
        label: 'tabs.project.status_list.archived',
        color: '#C4C6D7',
        legendOrder: 9,
        image: FlagArchived,
    },
    8: {
        label: 'tabs.project.status_list.standby',
        color: '#EF3E22',
        legendOrder: 7,
        image: FlagStandBy,
    },
    9: {
        label: 'tabs.project.status_list.commitment_dev_refused',
        color: '#C4C6D7',
        legendOrder: 10,
        image: FlagArchived,
    },
    10: {
        label: 'tabs.project.status_list.commitment_refused',
        color: '#C4C6D7',
        legendOrder: 11,
        image: FlagArchived,
    },
    11: {
        label: 'tabs.project.status_list.signed_by_competitor',
        color: '#C4C6D7',
        legendOrder: 8,
        image: FlagArchived,
    },
};
export const PROJECT_STATUS_VALUES_WITH_NONE = {
    0: {
        label: 'tabs.project.status_list.none',
        color: '#FFFFFF',
        legendOrder: 11,
    },
    ...PROJECT_STATUS_VALUES,
};
export const PROJECT_STATUS_OPTIONS = Object.keys(PROJECT_STATUS_VALUES)
    .map((key) => ({
    value: parseInt(key),
    label: i18n.t(PROJECT_STATUS_VALUES[key].label),
    color: PROJECT_STATUS_VALUES[key].color,
    legendOrder: PROJECT_STATUS_VALUES[key].legendOrder,
}))
    .sort((a, b) => (a.legendOrder > b.legendOrder ? 1 : -1));
export const PLOT_CONTACT_STATUS_VALUES = {
    0: { label: 'contact.status.none', color: '#FFFFFF', legendOrder: 9 },
    1: { label: 'contact.status.identified', color: '#F58220', legendOrder: 5 },
    2: { label: 'contact.status.contacted', color: '#FECA0A', legendOrder: 4 },
    3: { label: 'contact.status.meet_owner', color: '#7FC241', legendOrder: 3 },
    4: { label: 'contact.status.offer_issued', color: '#7FC241', legendOrder: 2 },
    5: {
        label: 'contact.status.promise_signed',
        color: '#008B4C',
        legendOrder: 1,
    },
    6: { label: 'contact.status.not_seller', color: '#C4C6D7', legendOrder: 7 },
    7: { label: 'contact.status.abandoned', color: '#C4C6D7', legendOrder: 8 },
    8: { label: 'contact.status.standby', color: '#EF3E22', legendOrder: 6 },
};
export const PROJECT_ESTATE_VALUE = {
    0: { label: 'tabs.project.estate_list.prospecting' },
    1: { label: 'tabs.project.estate_list.tender' },
    2: { label: 'tabs.project.estate_list.relationship_management' },
    3: { label: 'tabs.project.estate_list.business_provider' },
    4: { label: 'tabs.project.estate_list.other' },
};
export const PROJECT_DESTINATION_HOUSING = 1;
export const PROJECT_DESTINATION_VALUES = {
    [PROJECT_DESTINATION_HOUSING]: {
        label: 'tabs.project.destination_list.housing',
        legendOrder: 1,
    },
    2: {
        label: 'tabs.project.destination_list.offices',
        legendOrder: 2,
    },
    3: {
        label: 'tabs.project.destination_list.trade',
        legendOrder: 3,
    },
    4: {
        label: 'tabs.project.destination_list.tourism_residence',
        legendOrder: 4,
    },
    5: {
        label: 'tabs.project.destination_list.senior_residence',
        legendOrder: 5,
    },
    6: {
        label: 'tabs.project.destination_list.student_residence',
        legendOrder: 6,
    },
    7: {
        label: 'tabs.project.destination_list.building_land',
        legendOrder: 7,
    },
    8: {
        label: 'tabs.project.destination_list.solar_power',
        legendOrder: 8,
    },
    9: {
        label: 'tabs.project.destination_list.parking_shades',
        legendOrder: 10,
    },
    10: {
        label: 'tabs.project.destination_list.photovoltaic_roof',
        legendOrder: 11,
    },
    11: {
        label: 'tabs.project.destination_list.wind',
        legendOrder: 12,
    },
    12: {
        label: 'tabs.project.destination_list.other',
        legendOrder: 13,
    },
};
export const PROJECT_DESTINATION_OPTIONS = Object.keys(PROJECT_DESTINATION_VALUES)
    .map((key) => ({
    value: parseInt(key),
    label: i18n.t(PROJECT_DESTINATION_VALUES[key].label),
    legendOrder: PROJECT_DESTINATION_VALUES[key].legendOrder,
}))
    .sort((a, b) => (a.legendOrder > b.legendOrder ? 1 : -1));
export const PROJECT_ADD_PLOT = 'add';
export const PROJECT_DELETE_PLOT = 'remove';
export const PLOT_CONTACT_STATUS_OPTIONS = Object.keys(PLOT_CONTACT_STATUS_VALUES)
    .map((key) => ({
    value: parseInt(key),
    label: i18n.t(PLOT_CONTACT_STATUS_VALUES[key].label),
    color: PLOT_CONTACT_STATUS_VALUES[key].color,
    legendOrder: PLOT_CONTACT_STATUS_VALUES[key].legendOrder,
}))
    .sort((a, b) => (a.legendOrder > b.legendOrder ? 1 : -1));
export const CONTACT_FILTER_WITH_POSTALCODE = 0;
export const CONTACT_FILTER_WITH_PHONE_OR_EMAIL = 1;
export const CONTACT_FILTER_MORALE_PERSON = 2;
export const CONTACT_FILTER_OWNER_SELLER = 3;
export const CONTACT_FILTER_WITH_ACTIVITY = 4;
export const CONTACT_FILTER_NO_CONTACT_NPAI = 5;
export const CONTACT_FILTER_NO_USUFRUCTUARY = 6;
export const CONTACT_FILTER_WITHOUT_CONTACT = 7;
export const CONTACT_FILTERS_OPTIONS = [
    {
        label: i18n.t('plot.contact.filters.without_contact'),
        value: CONTACT_FILTER_WITHOUT_CONTACT,
    },
    {
        label: i18n.t('plot.contact.filters.with_postalCode'),
        value: CONTACT_FILTER_WITH_POSTALCODE,
    },
    {
        label: i18n.t('plot.contact.filters.with_phone_or_email'),
        value: CONTACT_FILTER_WITH_PHONE_OR_EMAIL,
    },
    {
        label: i18n.t('plot.contact.filters.morale_person'),
        value: CONTACT_FILTER_MORALE_PERSON,
    },
    {
        label: i18n.t('plot.contact.filters.owner'),
        value: CONTACT_FILTER_OWNER_SELLER,
    },
    {
        label: i18n.t('plot.contact.filters.with_activity'),
        value: CONTACT_FILTER_WITH_ACTIVITY,
    },
    {
        label: i18n.t('plot.contact.filters.no_contact_npai'),
        value: CONTACT_FILTER_NO_CONTACT_NPAI,
    },
    {
        label: i18n.t('plot.contact.filters.no_usufructuary'),
        value: CONTACT_FILTER_NO_USUFRUCTUARY,
    },
];
export const AGENDA_ACTIVITY_TYPE_UNDEFINED = 0;
export const AGENDA_ACTIVITY_TYPE_EVENT = 1;
export const AGENDA_ACTIVITY_TYPE_TASK = 2;
export const AGENDA_ACTIVITY_TYPE_REMINDER = 3;
export const AGENDA_ACTIVITY_TYPE_LAND_POINT = 4;
export const AGENDA_ACTIVITY_TYPES = {
    event: AGENDA_ACTIVITY_TYPE_EVENT,
    task: AGENDA_ACTIVITY_TYPE_TASK,
    reminder: AGENDA_ACTIVITY_TYPE_REMINDER,
    land_point: AGENDA_ACTIVITY_TYPE_LAND_POINT,
};
export const AGENDA_ACTIVITY_TYPE_VALUES = Object.fromEntries(Object.entries(AGENDA_ACTIVITY_TYPES).map(([key, value]) => [value, key]));
export const ACTIVITY_RECURRENCE_NO_RECURRENCE = 0;
export const ACTIVITY_RECURRENCE_ONCE = 1;
export const ACTIVITY_RECURRENCE_EVERY_DAYS = 2;
export const ACTIVITY_RECURRENCE_EVERY_WEEKS = 3;
export const ACTIVITY_RECURRENCE_EVERY_MONTHS = 4;
export const ACTIVITY_RECURRENCE_EVERY_YEARS = 5;
export const ACTIVITY_RECURRENCE_VALUES = {
    [ACTIVITY_RECURRENCE_ONCE]: 'once',
    [ACTIVITY_RECURRENCE_EVERY_DAYS]: 'every_days',
    [ACTIVITY_RECURRENCE_EVERY_WEEKS]: 'every_weeks',
    [ACTIVITY_RECURRENCE_EVERY_MONTHS]: 'every_months',
    [ACTIVITY_RECURRENCE_EVERY_YEARS]: 'every_years',
};
export const ACTIVITY_RECURRENCE_OPTIONS = Object.keys(ACTIVITY_RECURRENCE_VALUES).map((key) => ({
    value: parseInt(key),
    label: `activity.fields.reminder.recurrence.${ACTIVITY_RECURRENCE_VALUES[key]}`,
}));
export const CONTACT_TYPE_OWNER = 1;
export const CONTACT_TYPE_PARTNER = 2;
export const CONTACT_TYPE_NOTARY = 3;
export const CONTACT_TYPE_ARCHITECT = 4;
export const CONTACT_TYPE_TENANT = 5;
export const CONTACT_TYPE_OTHER = 6;
export const CONTACT_TYPE_BARE_OWNER = 7;
export const CONTACT_TYPE_USUFRUCTARY = 8;
export const CONTACT_TYPE_UNDIVIDED = 9;
export const CONTACT_TYPE_MANAGER = 10;
export const CONTACT_TYPE_REPRESENTATIVE = 11;
export const CONTACT_TYPES = {
    [CONTACT_TYPE_OWNER]: {
        label: 'contact.fields.type.options.owner',
        legendOrder: 1,
    },
    [CONTACT_TYPE_PARTNER]: {
        label: 'contact.fields.type.options.partner',
        legendOrder: 2,
    },
    [CONTACT_TYPE_NOTARY]: {
        label: 'contact.fields.type.options.notary',
        legendOrder: 3,
    },
    [CONTACT_TYPE_ARCHITECT]: {
        label: 'contact.fields.type.options.architect',
        legendOrder: 4,
    },
    [CONTACT_TYPE_TENANT]: {
        label: 'contact.fields.type.options.tenant',
        legendOrder: 5,
    },
    [CONTACT_TYPE_BARE_OWNER]: {
        label: 'contact.fields.type.options.bare_owner',
        legendOrder: 6,
    },
    [CONTACT_TYPE_USUFRUCTARY]: {
        label: 'contact.fields.type.options.usufructuary',
        legendOrder: 7,
    },
    [CONTACT_TYPE_UNDIVIDED]: {
        label: 'contact.fields.type.options.undivided',
        legendOrder: 8,
    },
    [CONTACT_TYPE_MANAGER]: {
        label: 'contact.fields.type.options.manager',
        legendOrder: 9,
    },
    [CONTACT_TYPE_REPRESENTATIVE]: {
        label: 'contact.fields.type.options.representative',
        legendOrder: 10,
    },
    [CONTACT_TYPE_OTHER]: {
        label: 'contact.fields.type.options.other',
        legendOrder: 11,
    },
};
export const CONTACT_TYPES_WITH_NONE = {
    0: { label: 'contact.fields.type.options.none', legendOrder: 0 },
    ...CONTACT_TYPES,
};
export const CONTACT_TYPES_OPTIONS = Object.keys(CONTACT_TYPES)
    .map((key) => ({
    value: parseInt(key),
    label: i18n.t(CONTACT_TYPES[key].label),
    legendOrder: CONTACT_TYPES[key].legendOrder,
}))
    .sort((a, b) => (a.legendOrder > b.legendOrder ? 1 : -1));
export const getProjectDestinationFromCode = (code) => i18n.t(PROJECT_DESTINATION_VALUES[code]?.label);
export const getPlotContactStatusFromCode = (code) => i18n.t(PLOT_CONTACT_STATUS_VALUES[code].label);
export const getContactTypeFromCode = (code) => i18n.t(CONTACT_TYPES_WITH_NONE[code].label);
export const getAgendaActivityTypeFromCode = (code) => AGENDA_ACTIVITY_TYPE_VALUES[code]
    ? `activity.fields.common.agenda_type.${AGENDA_ACTIVITY_TYPE_VALUES[code]}`
    : null;
const ACTIVITY_STATUS_VALUES = {
    0: { label: 'today', color: '#00C2AA' },
    1: { label: 'late', color: '#E62641' },
    2: { label: 'unknown', color: '#FB8D00' },
    3: { label: 'future', color: '#5C5B5B' },
};
const ACTIVITY_STATUS_LABELS = {
    0: { label: 'activity.fields.common.status.not_finished' },
    1: { label: 'activity.fields.common.status.finished' },
};
export const ACTIVITY_STATUS_OPTIONS = Object.keys(ACTIVITY_STATUS_LABELS).map((key) => ({
    value: parseInt(key),
    label: ACTIVITY_STATUS_LABELS[key].label,
}));
export const ACTIVITY_TYPE_OPTIONS = Object.keys(AGENDA_ACTIVITY_TYPE_VALUES).map((key) => ({
    value: parseInt(key),
    label: getAgendaActivityTypeFromCode(key),
}));
export const getActivityStatusLabelFromDelay = (delay) => {
    if (delay === 0)
        return ACTIVITY_STATUS_VALUES[0].label;
    if (delay < 0)
        return ACTIVITY_STATUS_VALUES[1].label;
    if (!delay)
        return ACTIVITY_STATUS_VALUES[2].label;
    return ACTIVITY_STATUS_VALUES[3].label;
};
export const getActivityStatusColorFromLabel = (label) => {
    const status = Object.values(ACTIVITY_STATUS_VALUES).find((status) => status.label === label);
    return status?.color;
};
export const getActivityStatusLabel = (status) => {
    return ACTIVITY_STATUS_LABELS[status].label;
};
export const RETAIL_TYPE = {
    1: { label: 'sidebar.layers.retail_hypermarket' },
    2: { label: 'sidebar.layers.retail_supermarket' },
    3: { label: 'sidebar.layers.retail_minimarket' },
    4: { label: 'sidebar.layers.retail_hard_discount' },
    5: { label: 'sidebar.layers.retail_deep_freeze' },
    6: { label: 'sidebar.layers.retail_bio' },
    7: { label: 'sidebar.layers.retail_drive' },
    8: { label: 'sidebar.layers.retail_bulk' },
};
//------------- CELL TOWER -------------/
export const CELL_TOWER_STATUS_VALUES = {
    0: { label: 'cell_tower.status.indifferent' },
    1: { label: 'cell_tower.status.service' },
    2: { label: 'cell_tower.status.project_approved' },
    3: { label: 'cell_tower.status.technically_operational' },
};
export const CELL_TOWER_STATUS_OPTIONS = Object.keys(CELL_TOWER_STATUS_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CELL_TOWER_STATUS_VALUES[key].label),
}));
export const CELL_TOWER_OPERATOR_VALUES = {
    0: { label: 'cell_tower.operator.indifferent' },
    1: { label: 'cell_tower.operator.bouygues' },
    2: { label: 'cell_tower.operator.free' },
    3: { label: 'cell_tower.operator.orange' },
    4: { label: 'cell_tower.operator.sfr' },
    5: { label: 'cell_tower.operator.other' },
};
export const CELL_TOWER_OPERATOR_OPTIONS = Object.keys(CELL_TOWER_OPERATOR_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CELL_TOWER_OPERATOR_VALUES[key].label),
}));
export const CELL_TOWER_SYSTEM_VALUES = {
    0: { label: 'cell_tower.system.indifferent' },
    1: { label: 'cell_tower.system.5g_nr_1800' },
    2: { label: 'cell_tower.system.5g_nr_2010' },
    3: { label: 'cell_tower.system.5g_nr_3500' },
    4: { label: 'cell_tower.system.5g_nr_700' },
    5: { label: 'cell_tower.system.gsm_1800' },
    6: { label: 'cell_tower.system.gsm_900' },
    7: { label: 'cell_tower.system.lte_1800' },
    8: { label: 'cell_tower.system.lte_2100' },
    9: { label: 'cell_tower.system.lte_2600' },
    10: { label: 'cell_tower.system.lte_700' },
    11: { label: 'cell_tower.system.lte_800' },
    12: { label: 'cell_tower.system.lte_900' },
    13: { label: 'cell_tower.system.umts_2100' },
    14: { label: 'cell_tower.system.umts_900' },
};
export const CELL_TOWER_SYSTEM_OPTIONS = Object.keys(CELL_TOWER_SYSTEM_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CELL_TOWER_SYSTEM_VALUES[key].label),
}));
export const CELL_TOWER_GENERATION_VALUES = {
    0: { label: 'cell_tower.generation.indifferent' },
    1: { label: 'cell_tower.generation.2G' },
    2: { label: 'cell_tower.generation.3G' },
    3: { label: 'cell_tower.generation.4G' },
    4: { label: 'cell_tower.generation.5G' },
};
export const CELL_TOWER_GENERATION_OPTIONS = Object.keys(CELL_TOWER_GENERATION_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CELL_TOWER_GENERATION_VALUES[key].label),
}));
//-------BUILDING CONSUMPTION------/
export const BUILDING_CONSUMPTION_SECTOR_VALUES = {
    0: { label: 'building_consumption.sector_choice.indifferent' },
    1: { label: 'building_consumption.sector_choice.industry' },
    2: { label: 'building_consumption.sector_choice.tertiary' },
    3: { label: 'building_consumption.sector_choice.residential' },
    4: { label: 'building_consumption.sector_choice.other' },
};
export const BUILDING_CONSUMPTION_SECTOR_OPTIONS = Object.keys(BUILDING_CONSUMPTION_SECTOR_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(BUILDING_CONSUMPTION_SECTOR_VALUES[key].label),
}));
export const getSectorFromCode = (code) => {
    switch (code) {
        case 'A':
            return i18n.t('building_consumption.sector_choice.agriculture');
        case 'I':
            return i18n.t('building_consumption.sector_choice.industry');
        case 'T':
            return i18n.t('building_consumption.sector_choice.tertiary');
        case 'X':
            return i18n.t('building_consumption.sector_choice.professional');
        case 'R':
            return i18n.t('building_consumption.sector_choice.residential');
        case '':
            return i18n.t('building_consumption.sector_choice.unknown');
    }
};
//------------- CDAC -------------/
export const CDAC_OPINION_VALUES = {
    F: { label: 'cdac.opinion.favorable' },
    D: { label: 'cdac.opinion.unfavorable' },
};
export const CDAC_ALIMENTARY_VALUES = {
    1: { label: 'cdac.alimentary.alimentary' },
    2: { label: 'cdac.alimentary.alimentary_and_no_alimentary' },
    3: { label: 'cdac.alimentary.no_alimentary' },
};
export const CDAC_ALIMENTARY_OPTIONS = Object.keys(CDAC_ALIMENTARY_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CDAC_ALIMENTARY_VALUES[key].label),
}));
export const CDAC_CREATION_VALUES = {
    1: { label: 'cdac.creation.creation' },
    2: { label: 'cdac.creation.extension' },
    3: { label: 'cdac.creation.transfert' },
};
export const CDAC_CREATION_OPTIONS = Object.keys(CDAC_CREATION_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(CDAC_CREATION_VALUES[key].label),
}));
//------------- DPE -------------/
export const DPE_GES_VALUES = {
    1: { label: 'A', value: 'A' },
    2: { label: 'B', value: 'B' },
    3: { label: 'C', value: 'C' },
    4: { label: 'D', value: 'D' },
    5: { label: 'E', value: 'E' },
    6: { label: 'F', value: 'F' },
    7: { label: 'G', value: 'G' },
};
export const DPE_GES_OPTIONS = Object.keys(DPE_GES_VALUES).map((key) => ({
    value: DPE_GES_VALUES[key].value,
    label: DPE_GES_VALUES[key].label,
}));
export const DPE_DPE_VALUES = {
    1: { label: 'A', value: 'A' },
    2: { label: 'B', value: 'B' },
    3: { label: 'C', value: 'C' },
    4: { label: 'D', value: 'D' },
    5: { label: 'E', value: 'E' },
    6: { label: 'F', value: 'F' },
    7: { label: 'G', value: 'G' },
};
export const DPE_DPE_OPTIONS = Object.keys(DPE_DPE_VALUES).map((key) => ({
    value: DPE_DPE_VALUES[key].value,
    label: DPE_DPE_VALUES[key].label,
}));
export const DPE_BUILDING_TYPE_VALUES = {
    1: { label: 'dpe.house', value: 'maison' },
    2: { label: 'dpe.apartment', value: 'appartement' },
};
export const DPE_BUILDING_TYPE_OPTIONS = Object.keys(DPE_BUILDING_TYPE_VALUES).map((key) => ({
    value: DPE_BUILDING_TYPE_VALUES[key].value,
    label: i18n.t(DPE_BUILDING_TYPE_VALUES[key].label),
}));
//------------- DEATH -------------/
export const DEATH_SEX_VALUES = {
    1: { label: 'Homme' },
    2: { label: 'Femme' },
};
//------------- MRAE -------------/
export const MRAE_TYPE_VALUES = {
    1: { label: 'mrae.type.aggrivoltaic' },
    3: { label: 'mrae.type.solar' },
};
export const MRAE_TYPE_OPTIONS = Object.keys(MRAE_TYPE_VALUES).map((key) => ({
    value: parseInt(key),
    label: i18n.t(MRAE_TYPE_VALUES[key].label),
}));
// ------ Agriculture Rearing company ------/
export const CODE_APE_VALUES = [
    { label: 'legends.code_ape.011', value: '011' },
    { label: 'legends.code_ape.0111', value: '0111' },
    { label: 'legends.code_ape.0112', value: '0112' },
    { label: 'legends.code_ape.0113', value: '0113' },
    { label: 'legends.code_ape.0114', value: '0114' },
    { label: 'legends.code_ape.0115', value: '0115' },
    { label: 'legends.code_ape.0116', value: '0116' },
    { label: 'legends.code_ape.0119', value: '0119' },
    { label: 'legends.code_ape.012', value: '012' },
    { label: 'legends.code_ape.0121', value: '0121' },
    { label: 'legends.code_ape.0122', value: '0122' },
    { label: 'legends.code_ape.0123', value: '0123' },
    { label: 'legends.code_ape.0124', value: '0124' },
    { label: 'legends.code_ape.0125', value: '0125' },
    { label: 'legends.code_ape.0126', value: '0126' },
    { label: 'legends.code_ape.0127', value: '0127' },
    { label: 'legends.code_ape.0128', value: '0128' },
    { label: 'legends.code_ape.0129', value: '0129' },
    { label: 'legends.code_ape.013', value: '013' },
    { label: 'legends.code_ape.014', value: '014' },
    { label: 'legends.code_ape.0141', value: '0141' },
    { label: 'legends.code_ape.0142', value: '0142' },
    { label: 'legends.code_ape.0143', value: '0143' },
    { label: 'legends.code_ape.0145', value: '0145' },
    { label: 'legends.code_ape.0146', value: '0146' },
    { label: 'legends.code_ape.0147', value: '0147' },
    { label: 'legends.code_ape.0149', value: '0149' },
    { label: 'legends.code_ape.015', value: '015' },
    { label: 'legends.code_ape.016', value: '016' },
    { label: 'legends.code_ape.0161', value: '0161' },
    { label: 'legends.code_ape.0162', value: '0162' },
    { label: 'legends.code_ape.0163', value: '0163' },
    { label: 'legends.code_ape.0164', value: '0164' },
    { label: 'legends.code_ape.017', value: '017' },
];
export const CODE_APE_OPTIONS = CODE_APE_VALUES.map((codeAPE) => ({
    value: codeAPE.value,
    label: i18n.t(codeAPE.label),
}));
export const COMPANY_TYPE_VALUES = [
    { label: 'company.options.company', value: '0' },
    { label: 'company.options.state', value: '1' },
    { label: 'company.options.region_department', value: '2' },
    { label: 'company.options.town', value: '4' },
    { label: 'company.options.hlm', value: '5' },
    { label: 'company.options.manager', value: '6' },
    { label: 'company.options.co_owner', value: '7' },
    { label: 'company.options.public', value: '9' },
];
export const COMPANY_TYPE_OPTIONS = Object.keys(COMPANY_TYPE_VALUES).map((key) => ({
    value: COMPANY_TYPE_VALUES[key].value,
    label: i18n.t(COMPANY_TYPE_VALUES[key].label),
}));
export const COMPANY_FORM_VALUES = [
    { label: 'AFR', value: 'AFR' },
    { label: 'AFU', value: 'AFU' },
    { label: 'ASS', value: 'ASS' },
    { label: 'BDF', value: 'BDF' },
    { label: 'CCAM', value: 'CCAM' },
    { label: 'CCAS', value: 'CCAS' },
    { label: 'CCM', value: 'CCM' },
    { label: 'CCMU', value: 'CCMU' },
    { label: 'CCOM', value: 'CCOM' },
    { label: 'CEP', value: 'CEP' },
    { label: 'CIAS', value: 'CIAS' },
    { label: 'COAG', value: 'COAG' },
    { label: 'Collectivité', value: 'Collectivité' },
    { label: 'COM', value: 'COM' },
    { label: 'COME', value: 'COME' },
    { label: 'COMU', value: 'COMU' },
    { label: 'CTOM', value: 'CTOM' },
    { label: 'CUMA', value: 'CUMA' },
    { label: 'DEPT', value: 'DEPT' },
    { label: 'DISU', value: 'DISU' },
    { label: 'EARL', value: 'EARL' },
    { label: 'EP', value: 'EP' },
    { label: 'EPA', value: 'EPA' },
    { label: 'EPIC', value: 'EPIC' },
    { label: 'Etat', value: 'Etat' },
    { label: 'FON', value: 'FON' },
    { label: 'GAEC', value: 'GAEC' },
    { label: 'GAF', value: 'GAF' },
    { label: 'GCS', value: 'GCS' },
    { label: 'GFA', value: 'GFA' },
    { label: 'GFO', value: 'GFO' },
    { label: 'GFR', value: 'GFR' },
    { label: 'GIE', value: 'GIE' },
    { label: 'GIP', value: 'GIP' },
    { label: 'GPAS', value: 'GPAS' },
    { label: 'IP', value: 'IP' },
    { label: 'IRC', value: 'IRC' },
    { label: 'MSA', value: 'MSA' },
    { label: 'MUT', value: 'MUT' },
    { label: 'OHLM', value: 'OHLM' },
    { label: 'OPRO', value: 'OPRO' },
    { label: 'PETR', value: 'PETR' },
    { label: 'REGI', value: 'REGI' },
    { label: 'SA', value: 'SA' },
    { label: 'SAM', value: 'SAM' },
    { label: 'SARL', value: 'SARL' },
    { label: 'SAS', value: 'SAS' },
    { label: 'SC', value: 'SC' },
    { label: 'SCA', value: 'SCA' },
    { label: 'SCCP', value: 'SCCP' },
    { label: 'SCEA', value: 'SCEA' },
    { label: 'SCI', value: 'SCI' },
    { label: 'SCOM', value: 'SCOM' },
    { label: 'SCOP', value: 'SCOP' },
    { label: 'SCPI', value: 'SCPI' },
    { label: 'SDI', value: 'SDI' },
    { label: 'SE', value: 'SE' },
    { label: 'SEM', value: 'SEM' },
    { label: 'SICA', value: 'SICA' },
    { label: 'SIVO', value: 'SIVO' },
    { label: 'SIVU', value: 'SIVU' },
    { label: 'SLRL', value: 'SLRL' },
    { label: 'SNC', value: 'SNC' },
    { label: 'Société', value: 'Société' },
    { label: 'SSRG', value: 'SSRG' },
    { label: 'SSRS', value: 'SSRS' },
    { label: 'SYCO', value: 'SYCO' },
    { label: 'SYMC', value: 'SYMC' },
    { label: 'SYMI', value: 'SYMI' },
];
export const COMPANY_FORM_OPTIONS = Object.keys(COMPANY_FORM_VALUES).map((key) => ({
    value: COMPANY_FORM_VALUES[key].value,
    label: i18n.t(COMPANY_FORM_VALUES[key].label),
}));
export const STAFF_TYPE_VALUES = [
    { label: 'staff.options.admin', value: '23;40' },
    { label: 'staff.options.old_manager', value: '1' },
    { label: 'staff.options.partner', value: '30;31;38;37;12' },
    { label: 'staff.options.alternate_auditor', value: '29' },
    { label: 'staff.options.regular_auditor', value: '28' },
    { label: 'staff.options.management_controller', value: '6' },
    { label: 'staff.options.account_controller', value: '7' },
    { label: 'staff.options.manager', value: '4' },
    { label: 'staff.options.manager_and_partner', value: '9;10' },
    { label: 'staff.options.manager_director', value: '15;16;17;27;53' },
    { label: 'staff.options.liquidator', value: '11' },
    { label: 'staff.options.member', value: '5' },
    { label: 'staff.options.member_supervisory_board', value: '22' },
    { label: 'staff.options.member_management_board', value: '21' },
    { label: 'staff.options.president', value: '13;14;19;20' },
    { label: 'staff.options.president_sas', value: '8' },
    { label: 'staff.options.vice_president', value: '43;44' },
    { label: 'staff.options.other', value: '3;41;39;24;25;50;32;55;54;34' },
];
export const STAFF_TYPE_OPTIONS = Object.keys(STAFF_TYPE_VALUES).map((key) => ({
    value: STAFF_TYPE_VALUES[key].value,
    label: i18n.t(STAFF_TYPE_VALUES[key].label),
}));
export const BUILDING_DESTINATION_VALUES = {
    0: { label: 'sidebar.search_plots.building_purpose_options.unknown' },
    1: { label: 'sidebar.search_plots.building_purpose_options.offices' },
    2: { label: 'sidebar.search_plots.building_purpose_options.public_services' },
    3: { label: 'sidebar.search_plots.building_purpose_options.agriculture' },
    4: { label: 'sidebar.search_plots.building_purpose_options.industry' },
    5: { label: 'sidebar.search_plots.building_purpose_options.housing' },
};
export const getBuildingDestinationFromCode = (code) => i18n.t(BUILDING_DESTINATION_VALUES[code]?.label);
