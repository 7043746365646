import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Icon, Link, Skeleton, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_MAP_STICKY, } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useIsLoadingPLUInfo, usePLUInfo } from 'hooks/contexts/useMapContext';
import DownloadIcon from 'images/icons/Download';
import { filtersKey } from 'utils/filters';
import { isSwissSite } from 'utils/site';
import LegendAccordion from './LegendAccordion';
const PLULegendSkeleton = () => {
    return (<VStack alignItems="flex-start">
      <Skeleton height="10px" width="60%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="10px" width="100%"/>
      <Skeleton height="20px" width="80%"/>
    </VStack>);
};
const PLULegendItem = ({ filtersConfig, legends, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const PLUInfo = usePLUInfo();
    const isLoadingPLUInfo = useIsLoadingPLUInfo();
    const isFilterActive = useIsFilterActive();
    const [seeMore, setSeeMore] = useState(false);
    if (!isFilterActive(filtersKey.PLU) && !PLUInfo && !isLoadingPLUInfo) {
        return null;
    }
    return (<LegendAccordion id={filtersKey.PLU} isLoading={isLoadingPLUInfo} skeleton={<PLULegendSkeleton />} legendLabel={isSwissSite() ? t('legends.plu_swiss.title') : t('legends.plu.title')} handleClose={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${t('legends.plu.title')} off légende`,
            });
            toggleFilter(filtersKey.PLU, false);
        }} filtersConfig={filtersConfig} legends={legends}>
      {PLUInfo && (<Box key={PLUInfo?.name}>
          <Text fontWeight="900">{PLUInfo?.area}</Text>
          <Text>
            {t('legends.plu.subarea')} : <b>{PLUInfo.subarea}</b>
          </Text>

          <Text display={seeMore ? 'none' : 'block'}>
            {PLUInfo.name.substring(0, 65)}
            {PLUInfo.name.length > 65 && (<Text as="span" color="blue.500" cursor="pointer" onClick={() => setSeeMore(true)}>
                ... {t('legends.more')}
              </Text>)}
          </Text>
          <Text display={seeMore ? 'block' : 'none'}>{PLUInfo.name}</Text>
          {PLUInfo.url && (<HStack alignItems="start" marginTop={1}>
              <Icon as={DownloadIcon} width={4} height={4} color="colorMode.revertBase" alignSelf="center"/>
              <Link isExternal={true} fontSize="12px" href={PLUInfo.url} color="colorMode.revertBase" onClick={() => trackEvent({
                    category: ANALYTICS_CATEGORY_DOWNLOAD,
                    action: 'Télécharger règlement carte',
                })}>
                {t('legends.plu.url')}
              </Link>
            </HStack>)}
        </Box>)}
    </LegendAccordion>);
};
export default PLULegendItem;
