import * as React from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import SirenLink from 'components/links/SirenLink';
import ContactDrawer from 'components/modals/contactModal/ContactDrawer';
import StaffModal from 'components/modals/StaffModal';
import Table from 'components/table/Table';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useChangeLayout } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { BUTTON_CONTACT_OCCUPANT } from 'hooks/crm/useContact';
import { IOStaff } from 'hooks/plot/usePlotOwnersInfo';
import { ACTION_CREATE } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { arrSearchLike } from 'utils/search';
import StaffItem from '../plots/PlotSections/staff/StaffItem';
import BankruptCompaniesOwnedPlotsButton from './BankruptCompaniesOwnedPlotsButton';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.companies.columns.${column}`)}
    </Translation>
  </Text>);
export const IOCompaniesTableData = iots.type({
    department: iots.string,
    town: iots.string,
    companyName: iots.string,
    type: iots.string,
    groupType: iots.string,
    staff: iots.array(IOStaff),
    siren: iots.string,
    countOwnedPlots: iots.number,
    countOwnedPlotsDepartment: iots.number,
});
const EXPORTED_FIELDS = ['department', 'town', 'companyName', 'siren'];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.sci.columns.${snakeCase(header)}`);
const formatExportValue = (value, field, data) => {
    if (field === 'siren') {
        return data.siren;
    }
    return value;
};
const fetchCompanies = (department) => () => get(iots.array(IOCompaniesTableData), `${department}/companies`);
const CompaniesPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { deployLayout } = useChangeLayout();
    const department = selectedTown?.department;
    const extraFilters = useExtraFilters();
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['companies', department],
        queryFn: fetchCompanies(department),
        enabled: !!department,
    });
    const [contactToCreate, setContactToCreate] = React.useState(null);
    const [columnFilters, setColumnFilters] = useState([]);
    useEffect(() => {
        deployLayout();
    }, []);
    useEffect(() => {
        const newFilters = [];
        const updatedColumnFilters = [...columnFilters];
        if (extraFilters) {
            if (extraFilters[filtersKey.COMPANIES]?.type) {
                newFilters.push({
                    id: 'groupType',
                    value: [
                        t('tabs.extra_tabs.companies.group_type.' +
                            extraFilters[filtersKey.COMPANIES].type),
                    ],
                });
            }
        }
        if (newFilters?.length) {
            setColumnFilters([...updatedColumnFilters, ...newFilters]);
        }
    }, [JSON.stringify(extraFilters)]);
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'countOwnedPlots',
                        header: () => getColumnHeader('count_owned_plots'),
                        cell: (info) => {
                            return (<BankruptCompaniesOwnedPlotsButton data={{
                                    siren: info.row.original.siren,
                                    countOwnedPlots: info.row.original.countOwnedPlots,
                                }}/>);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'countOwnedPlotsDepartment',
                        header: () => getColumnHeader('count_owned_plots_department'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'companyName',
                        header: () => getColumnHeader('company_name'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                    {
                        accessorKey: 'groupType',
                        accessorFn: (row) => {
                            if (row.groupType === '')
                                return t('table.empty_column');
                            return t('tabs.extra_tabs.companies.group_type.' + row.groupType);
                        },
                        header: () => getColumnHeader('group_type'),
                        cell: ({ row }) => {
                            if (row.original.groupType === '')
                                return '';
                            return t('tabs.extra_tabs.companies.group_type.' + row.original.groupType);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'type',
                        accessorFn: (row) => {
                            if (!row.type)
                                return t('table.empty_column');
                            return row.type;
                        },
                        header: () => getColumnHeader('type'),
                        cell: ({ row }) => row.original.type,
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'siren',
                        header: () => getColumnHeader('siren'),
                        cell: (info) => {
                            return (<SirenLink siren={info.getValue()} analyticDetail="Pappers Companies" showExternalLink/>);
                        },
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                    {
                        accessorKey: 'staff',
                        accessorFn: (row) => {
                            if (row.staff.length > 0) {
                                return row.staff[0].firstname + ' ' + row.staff[0].name;
                            }
                            else {
                                return '';
                            }
                        },
                        header: () => getColumnHeader('staff'),
                        cell: ({ row }) => {
                            if (row.original.staff.length > 0) {
                                if (row.original.staff.length === 1) {
                                    return (<StaffItem owner={{ name: '' }} staff={row.original.staff[0]} index={0} setContactToCreate={setContactToCreate} flexDirection="column" widthLabel="100%"/>);
                                }
                                else {
                                    return (<StaffModal owner={{
                                            name: row.original.companyName,
                                            siren: row.original.siren,
                                            streetNumber: '',
                                            street: '',
                                            town: row.original.town,
                                        }} setContactToCreate={setContactToCreate} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={onOpen}>
                          {row.original.staff.length} {t(`global.managers`)}
                        </Link>)} staff={row.original.staff}/>);
                                }
                            }
                            else {
                                return t(`global.ND`);
                            }
                        },
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                    {
                        accessorKey: 'department',
                        header: () => getColumnHeader('department'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        accessorFn: (row) => {
                            if (!row.town)
                                return t('table.empty_column');
                            return row.town;
                        },
                        header: () => getColumnHeader('town'),
                        cell: ({ row }) => row.original.town,
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                ],
            },
        ];
    }, []);
    const initialSort = [
        {
            id: 'countOwnedPlotsDepartment',
            desc: true,
        },
    ];
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="companies" columns={columns} data={data || []} isLoading={isLoading} customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters} initialSort={initialSort} exportTitle={t('tabs.extra_tabs.sci.tab_title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }}/>
      <ContactDrawer action={ACTION_CREATE} contact={contactToCreate} button={BUTTON_CONTACT_OCCUPANT} handleClose={() => setContactToCreate(null)} triggerItem={() => <></>}/>
    </Stack>);
};
export default memo(CompaniesPanel);
