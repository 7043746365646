import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, IconButton, Text, Tooltip, } from '@chakra-ui/react';
import { HEIGHT_LEGEND_ICON } from 'utils/constants';
import { LAYERS_WITH_OPACITY } from 'utils/tilesPositions';
import HideFilterButton from '../HideFilterButton';
import SetOpacityButton from '../SetOpacityButton';
const LegendAccordion = ({ id, legendLabel, canToggle, handleClose, children, isLoading, skeleton, filtersConfig, legends, ...props }) => {
    const { t } = useTranslation();
    const tilesPosition = LAYERS_WITH_OPACITY[id] || LAYERS_WITH_OPACITY[id.toUpperCase()];
    return (<AccordionItem key={id} borderColor="colorMode.background600" data-cy={'legend_item_' + id} className="legendItem" {...props}>
      <Heading data-cy="layer_legend">
        <AccordionButton as="div" cursor="pointer" padding="0">
          <Box flex="1" textAlign="left" display="flex" justifyContent="space-between" alignItems="center" paddingX={3}>
            <Text as="span" fontWeight={600} textStyle="paragraph" color="colorMode.font900" paddingY={1} maxWidth="100%">
              {legendLabel}
            </Text>
          </Box>
          {canToggle && (<AccordionIcon color="colorMode.font700" _hover={{
                color: 'colorMode.font900',
            }}/>)}
          <HideFilterButton filterName={id}/>
          <SetOpacityButton tilesPosition={tilesPosition} legends={legends} filterKey={id} filtersConfig={filtersConfig}/>
          {handleClose && (<Tooltip label={t('legends.close')}>
              <IconButton height={HEIGHT_LEGEND_ICON} icon={<CloseIcon width="12px" color="currentcolor"/>} aria-label="close-layer" variant="link" onClick={handleClose} color="colorMode.font700" minWidth={8} _hover={{
                color: 'red.500',
            }} borderRadius={0} borderLeft="solid 1px" borderColor="colorMode.background600" data-cy={'legend_item_' + id + '_close'}/>
            </Tooltip>)}
        </AccordionButton>
      </Heading>

      {canToggle ? (<AccordionPanel padding={2} borderTop="1px solid" borderColor="colorMode.background600">
          {isLoading ? skeleton ?? null : children}
        </AccordionPanel>) : children || isLoading ? (<Box fontSize="12px" padding={2} borderTop="1px solid" borderColor="colorMode.background600">
          {isLoading ? skeleton ?? null : children}
        </Box>) : null}
    </AccordionItem>);
};
export default LegendAccordion;
