import * as React from 'react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Translation } from 'react-i18next';
import Select from 'react-select';
import { Box, Button, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_CRM, ANALYTICS_IMPORT_PUBLIPOSTAGE, ANALYTICS_TABLE, } from 'analytics/useAnalytics';
import { t } from 'i18next';
import { snakeCase } from 'lodash';
import ContactButton from 'components/buttons/ContactButton';
import CreateButton from 'components/buttons/CreateButton';
import DeleteButton from 'components/buttons/DeleteButton';
import { ExportItemButton } from 'components/buttons/ExportButton';
import PlotButton from 'components/buttons/PlotButton';
import ProjectButton from 'components/buttons/ProjectButton';
import SeeMapButton from 'components/buttons/SeeMapButton';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import ProjectStatusSelect from 'components/inputs/ProjectStatusSelect';
import StatusSelect from 'components/inputs/StatusSelect';
import BindContactDrawer from 'components/modals/contactModal/BindContactDrawer';
import PublipostageDrawer from 'components/modals/publipostageModal/PublipostageDrawer';
import Table from 'components/table/Table';
import TableExportButton from 'components/table/TableExportButton';
import SelectRows from 'components/tabs/utils/SelectRows';
import { tableFiltersKey } from 'context/FiltersContext';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { CRM_TABS } from 'context/TabsContext';
import { useUpdateTableFilter } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown, useSetPlotSelectionMode, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useToggleLayerTab, useToggleProject, } from 'hooks/contexts/useTabsContext';
import useContact from 'hooks/crm/useContact';
import useContactList from 'hooks/crm/useContactList';
import usePlotContact from 'hooks/crm/usePlotContact';
import usePlotStatus from 'hooks/crm/usePlotStatus';
import useProject from 'hooks/crm/useProject';
import useUserPlotsList from 'hooks/crm/useUserPlotsList';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import useExportPDF, { PLOTS_CONTACTS_EXPORT_PDF, PLOTS_EXPORT_PDF, } from 'hooks/useExportPDF';
import useExportXLSX, { PLOTS_CONTACTS_EXPORT_XLSX, PLOTS_EXPORT_XLSX, } from 'hooks/useExportXLSX';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { CONTACT_FILTER_MORALE_PERSON, CONTACT_FILTER_NO_CONTACT_NPAI, CONTACT_FILTER_NO_USUFRUCTUARY, CONTACT_FILTER_OWNER_SELLER, CONTACT_FILTER_WITH_ACTIVITY, CONTACT_FILTER_WITH_PHONE_OR_EMAIL, CONTACT_FILTER_WITH_POSTALCODE, CONTACT_FILTER_WITHOUT_CONTACT, CONTACT_FILTERS_OPTIONS, CONTACT_TYPE_OWNER, CONTACT_TYPE_USUFRUCTARY, getAgendaActivityTypeFromCode, getContactTypeFromCode, getPlotContactStatusFromCode, PLOT_CONTACT_STATUS_OPTIONS, } from 'utils/constants';
import { registerTableFilters } from 'utils/filters';
import { calculateAndGetElapsedTime, formatDate } from 'utils/formatDate';
import { centerAndZoomOnCoord } from 'utils/map';
import { getPlotCodeFromStatus, getPlotStatusLabel, getPlotStatusOption, getProjectStatusLabel, } from 'utils/personalization';
import { inDateRange } from 'utils/search';
export const getColumnHeader = (column, width = null) => (<Text align="center" minWidth={width ? width + 'px' : 0}>
    <Translation>{(t) => t(`tabs.user_plots.columns.${column}`)}</Translation>
  </Text>);
const PLOT_HEADER_COLOR = '#F0FAF8';
const HEADER_COLORS = {
    cadastre_id: PLOT_HEADER_COLOR,
    plot_status: PLOT_HEADER_COLOR,
    address: PLOT_HEADER_COLOR,
    zipcode: PLOT_HEADER_COLOR,
    townLabel: PLOT_HEADER_COLOR,
    surface: PLOT_HEADER_COLOR,
    zone: PLOT_HEADER_COLOR,
    last_update: PLOT_HEADER_COLOR,
    historyDate: PLOT_HEADER_COLOR,
    history_type: PLOT_HEADER_COLOR,
    projects: PLOT_HEADER_COLOR,
    'contact.lastname': PLOT_HEADER_COLOR,
    plot_contact_status: PLOT_HEADER_COLOR,
    contactName: PLOT_HEADER_COLOR,
    plotContactStatus: PLOT_HEADER_COLOR,
    comment: PLOT_HEADER_COLOR,
    agenda_type: PLOT_HEADER_COLOR,
    activity_date: PLOT_HEADER_COLOR,
    'contact.type': PLOT_HEADER_COLOR,
};
const formatExportHeader = (t) => (header) => t(`tabs.user_plots.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'plotStatus') {
        return getPlotStatusLabel(value);
    }
    if (field === 'projects') {
        let projectsExport = '';
        value.forEach((project) => {
            if (projectsExport !== '') {
                projectsExport += ', \n';
            }
            projectsExport +=
                project.label + ' ; ' + getProjectStatusLabel(project.status);
        });
        return projectsExport;
    }
    if (field === 'plotContactStatus') {
        return getPlotContactStatusFromCode(value);
    }
    if (field === 'contactType') {
        return value
            .map((type) => {
            return getContactTypeFromCode(type);
        })
            .join(', ');
    }
    if (field === 'agendaType') {
        return t(getAgendaActivityTypeFromCode(value));
    }
    if (field === 'historyDate') {
        return formatDate(value, 'extended');
    }
    return value;
};
const initialSort = [
    {
        id: 'historyDate',
        desc: true,
    },
];
const MyPlotsPanel = () => {
    const { trackEvent } = useAnalytics();
    const { changePlotStatus } = usePlotStatus();
    const { hasFeature } = useSiteConfiguration();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const { data, isInitialLoading: isLoadingUserPlotsList, removePlots, isRefetching, } = useUserPlotsList();
    const { addProject, isAddingProject } = useProject();
    const toggleProject = useToggleProject();
    const selectedTown = useSelectedTown();
    const navigateToTown = useNavigateToTown();
    const { addContactToPlot } = useContact();
    const { isInitialLoading: isLoadingContactList } = useContactList();
    const { updatePlotContact } = usePlotContact();
    const map = useMap();
    const setPlotSelectionMode = useSetPlotSelectionMode();
    const toggleLayerTab = useToggleLayerTab();
    const updateTableFilter = useUpdateTableFilter();
    const exportXLSX = useExportXLSX();
    const exportPDF = useExportPDF();
    const selectContactInputRef = useRef(null);
    const createProject = (plot) => {
        if (isAddingProject) {
            return;
        }
        addProject({
            plotIds: [plot.plotId],
            townshipId: plot.townId,
        }, {
            onSuccess: (createdProject) => {
                if (plot.townId !== selectedTown?.id.toString()) {
                    navigateToTown({
                        lat: plot.plotLat,
                        lng: plot.plotLng,
                        precision: 2,
                        centerMapOnLat: plot.plotLat,
                        centerMapOnLng: plot.plotLng,
                    });
                }
                else {
                    const projectCenter = new google.maps.LatLng(parseFloat(plot.plotLat), parseFloat(plot.plotLng));
                    centerAndZoomOnCoord(map, projectCenter, 2);
                }
                setPlotSelectionMode(PLOT_SELECTION_PROJECT);
                setFeasibilityParentId(createdProject.id);
                toggleProject({
                    resource: {
                        id: createdProject.id,
                        label: createdProject.label,
                        townId: plot.townId.toString(),
                        lat: plot.plotLat,
                        lng: plot.plotLng,
                    },
                });
            },
        });
    };
    const handleUserPlotsNews = useCallback(() => {
        toggleLayerTab({ tabId: CRM_TABS.USER_PLOTS_NEWS });
    }, []);
    const [columnFilters, setColumnFilters] = useState([]);
    const [selectedPlots, setSelectedPlots] = useState({});
    const [initialSelect, setInitialSelect] = useState(false);
    const [noPlotsSelected, setNoPlotsSelected] = useState(true);
    const [plotContactsIds, setPlotContactsIds] = useState([]);
    const [plotsFiltered, setPlotsFiltered] = useState(data);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoPlotsSelected(isNoneSelected);
        setInitialSelect(false);
        const plots = {};
        const contactsIds = [];
        rows.forEach((row) => {
            if (row.contact)
                contactsIds.push(row.contact?.id);
            const plotKey = `${row.plotId}-${row.contact?.id}`;
            return (plots[plotKey] = {
                plotId: row.plotId,
                contactId: row.contact?.id,
            });
        });
        setSelectedPlots(plots);
        setPlotContactsIds(contactsIds);
        if (isAllSelected) {
            setNoPlotsSelected(!isAllSelected);
            trackEvent({
                category: ANALYTICS_TABLE,
                action: 'Mes parcelles - Tout sélectionner/désélectionner ',
            });
        }
    };
    const removeSelectedPlots = () => {
        if (Object.keys(selectedPlots).length) {
            removePlots({ plots: Object.values(selectedPlots) }, {
                onSuccess: () => {
                    setSelectedPlots({});
                    setInitialSelect(true);
                },
            });
        }
    };
    useEffect(() => {
        registerTableFilters({
            keysToRegister: [
                {
                    name: tableFiltersKey.PLOT_STATUS,
                    formatValue: (value) => value.map((status) => parseInt(getPlotCodeFromStatus(status))),
                },
            ],
            columnFilters,
            updateTableFilter,
        });
    }, [columnFilters]);
    const onChangeContactFilter = (options) => {
        setPlotsFiltered(plotsFiltration(options));
        trackEvent({
            category: ANALYTICS_CRM,
            action: 'Mes parcelles filtre contact',
        });
    };
    const plotsFiltration = (options) => {
        const optionsSelected = [];
        options.forEach((option) => {
            optionsSelected.push(option.value);
        });
        if (optionsSelected.length === 0) {
            return data;
        }
        return data.filter((plot) => {
            const contact = plot.contact;
            if (optionsSelected.includes(CONTACT_FILTER_WITHOUT_CONTACT) && contact) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_WITH_POSTALCODE) &&
                !contact?.address) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_WITH_PHONE_OR_EMAIL) &&
                !contact?.mobile &&
                !contact?.landline &&
                !contact?.primaryEmail) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_MORALE_PERSON) &&
                !contact?.company &&
                !contact?.siren) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_OWNER_SELLER) &&
                (!contact ||
                    (!contact?.type.includes(CONTACT_TYPE_OWNER) && contact?.vendor))) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_WITH_ACTIVITY) &&
                !contact?.hasActivity) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_NO_CONTACT_NPAI) &&
                contact &&
                contact.npai) {
                return false;
            }
            if (optionsSelected.includes(CONTACT_FILTER_NO_USUFRUCTUARY) &&
                contact?.type.includes(CONTACT_TYPE_USUFRUCTARY)) {
                return false;
            }
            return true;
        });
    };
    useEffect(() => {
        if (!data)
            return;
        const options = selectContactInputRef.current.getValue();
        setPlotsFiltered(plotsFiltration(options));
    }, [data]);
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        id: 'cadastre_id',
                        accessorKey: 'cadastreId',
                        header: ({ table }) => {
                            return (<>
                  <Stack direction="row">
                    <SelectRows {...{
                                checked: table
                                    .getFilteredRowModel()
                                    .rows.every((row) => row.getIsSelected()),
                                toggleRow: table.getToggleAllRowsSelectedHandler(),
                                setNoItemIsSelected: setNoPlotsSelected,
                            }}/>
                    <Text as="span" variant="primary">
                      {t('tabs.user_plots.title')}
                    </Text>
                  </Stack>
                </>);
                        },
                        accessorFn: (row) => {
                            if (!row.cadastreId)
                                return t('table.empty_column');
                            return row.cadastreId;
                        },
                        cell: (info) => {
                            const userPlot = info.row.original;
                            return (<Stack direction="row">
                  <SelectRows id="delete_plot_select" {...{
                                checked: info.row.getIsSelected(),
                                disabled: !info.row.getCanSelect(),
                                toggleRow: info.row.getToggleSelectedHandler(),
                            }}/>
                  <PlotButton lat={userPlot.plotLat} lng={userPlot.plotLng} townId={userPlot.townId} variant="link-primary" plotId={userPlot.plotId} analyticOrigin={ANALYTICS_CRM} analyticDetail="Ouvrir fiche parcelle mes parcelles" id="user_plots">
                    {info.row.original.cadastreId}
                  </PlotButton>
                </Stack>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'plot_status',
                        accessorKey: 'plotStatus',
                        accessorFn: (row) => {
                            if (!row.plotStatus)
                                return t('table.empty_column');
                            return getPlotStatusLabel(row.plotStatus);
                        },
                        header: () => getColumnHeader('plot_status', 100),
                        cell: (info) => {
                            return (<StatusSelect data={getPlotStatusOption()} onChange={(value) => {
                                    changePlotStatus({
                                        plotId: info.row.original.plotId,
                                        status: value.value,
                                    });
                                    trackEvent({
                                        category: ANALYTICS_CRM,
                                        action: 'Statut de la parcelle mes parcelles',
                                    });
                                }} value={info.row.original.plotStatus}/>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'address',
                        header: () => getColumnHeader('address'),
                        accessorFn: (row) => {
                            if (!row.address)
                                return t('table.empty_column');
                            return row.address;
                        },
                        cell: (info) => info.row.original.address,
                        meta: {
                            minW: '150px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'zipcode',
                        header: () => getColumnHeader('zipcode'),
                        accessorFn: (row) => {
                            if (!row.zipcode)
                                return t('table.empty_column');
                            return row.zipcode;
                        },
                        cell: (info) => info.row.original.zipcode,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'townLabel',
                        header: () => getColumnHeader('townLabel'),
                        accessorFn: (row) => {
                            if (!row.townLabel)
                                return t('table.empty_column');
                            return row.townLabel;
                        },
                        cell: (info) => info.row.original.townLabel,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'surface',
                        header: () => getColumnHeader('surface'),
                        cell: (info) => info.row.original.surface,
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'zone',
                        header: () => getColumnHeader('zone'),
                        accessorFn: (row) => {
                            if (!row.zone)
                                return t('table.empty_column');
                            return row.zone;
                        },
                        cell: (info) => info.row.original.zone,
                        filterFn: 'arrIncludesSome',
                        meta: {
                            minW: '80px',
                        },
                    },
                    {
                        accessorKey: 'historyDate',
                        header: () => getColumnHeader('history_date'),
                        cell: (info) => {
                            return (<Text textAlign="center">
                  {formatDate(info.getValue(), 'short')}
                </Text>);
                        },
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        id: 'last_update',
                        accessorKey: 'historyDate',
                        header: () => getColumnHeader('last_update'),
                        cell: (info) => {
                            const date = info.getValue();
                            const now = new Date();
                            return (<Text fontWeight="bold">
                  {calculateAndGetElapsedTime(t, date, now, true)}
                </Text>);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'history_type',
                        accessorKey: 'historyType',
                        header: () => getColumnHeader('history_type'),
                        accessorFn: (row) => {
                            if (!row.historyType)
                                return t('table.empty_column');
                            return row.historyType;
                        },
                        cell: (info) => info.row.original.historyType,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'projects',
                        header: () => getColumnHeader('projects_label'),
                        accessorFn: (row) => {
                            if (!row.projects.length)
                                return t('table.empty_column');
                            return row.projects[0].label;
                        },
                        cell: (info) => {
                            const row = info.row.original;
                            const projects = row.projects;
                            return projects.length > 0 ? (<VStack spacing={1}>
                  {Object.values(projects).map((project) => (<HStack key={project.id} align-items="center">
                      <ProjectButton projectId={project.id} projectLabel={project.label} projectTownIds={[row.townId]} lat={row.plotLat} lng={row.plotLng} minWidth="160px">
                        {project.label}
                      </ProjectButton>
                      <ProjectStatusSelect townshipId={row.townId} projectId={project.id} plotIds={[row.plotId]} status={project.status}/>
                    </HStack>))}
                </VStack>) : (<CreateButton size="sm" isDisabled={isAddingProject} onClick={() => {
                                    createProject(row);
                                }} label={'global.create_project'}/>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'contact.lastname',
                        accessorKey: 'contact.lastname',
                        header: () => getColumnHeader('contact_name'),
                        accessorFn: (row) => {
                            if (!row.contact?.lastname)
                                return t('table.empty_column');
                            return row.contact?.lastname;
                        },
                        cell: ({ row }) => {
                            return row.original.contact?.lastname ? (<ContactButton id="my_plots_contact_button" variant="link-primary" contactId={row.original.contact?.id}/>) : (<BindContactDrawer triggerItem={(onOpen) => (<CreateButton data-cy="my_plot_add_owner" size="sm" onClick={onOpen} label={'tabs.user_plots.add_owner'}/>)} onSubmit={(contactId, onClose) => {
                                    addContactToPlot({
                                        plotId: row.original.plotId,
                                        contactId: contactId,
                                    }, {
                                        onSuccess: onClose,
                                    });
                                }}/>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'plot_contact_status',
                        accessorFn: (row) => {
                            if (!row.plotContactStatus)
                                return t('table.empty_column');
                            return getPlotContactStatusFromCode(row.plotContactStatus);
                        },
                        header: () => getColumnHeader('plot_contact_status', 100),
                        cell: (info) => {
                            return info.row.original.plotContactId ? (<StatusSelect data={PLOT_CONTACT_STATUS_OPTIONS} onChange={(value) => updatePlotContact({
                                    plotId: info.row.original.plotId,
                                    plotContactId: info.row.original.plotContactId,
                                    status: value.value,
                                    comment: info.row.original.comment,
                                })} value={info.row.original.plotContactStatus}/>) : null;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'agenda_type',
                        accessorKey: 'agendaType',
                        accessorFn: (row) => {
                            if (!row.agendaType)
                                return t('table.empty_column');
                            return t(getAgendaActivityTypeFromCode(row.agendaType));
                        },
                        header: () => getColumnHeader('agenda_type'),
                        cell: ({ row }) => t(getAgendaActivityTypeFromCode(row.original.agendaType)),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'activity_date',
                        accessorKey: 'activityDate',
                        header: () => getColumnHeader('activity_date'),
                        cell: (info) => {
                            return formatDate(info.getValue());
                        },
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        id: 'contact.type',
                        accessorKey: 'contact.type',
                        accessorFn: (row) => {
                            if (!row.contact) {
                                return t('table.empty_column');
                            }
                            else {
                                return row.contact?.type
                                    .map((type) => {
                                    return getContactTypeFromCode(type);
                                })
                                    .join(', ');
                            }
                        },
                        header: () => getColumnHeader('contact_type'),
                        cell: (info) => info.row.original.contact?.type ? info.getValue() : null,
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                    },
                ],
            },
        ];
    }, [isLoadingContactList, JSON.stringify(data)]);
    const exportItems = (<>
      <ExportItemButton key="export_plot_pdf" onExport={async () => {
            await exportPDF(PLOTS_EXPORT_PDF, {}, 'post', {
                plotsIds: Object.values(selectedPlots).map((p) => p.plotId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_plots.export_title') + ' PDF',
            });
        }} id="export_plot_pdf">
        {t('table.export_pdf')}
      </ExportItemButton>
      <ExportItemButton key="export_plot_excel" onExport={async () => {
            await exportXLSX(PLOTS_EXPORT_XLSX, {}, 'post', {
                plotsIds: Object.values(selectedPlots).map((p) => p.plotId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_plots.export_title') + ' Excel',
            });
        }} id="export_plot_excel">
        {t('table.export_xlsx')}
      </ExportItemButton>
    </>);
    const exportContactsButtons = (<>
      <ExportItemButton key="export_plot_contact_pdf" onExport={async () => {
            await exportPDF(PLOTS_CONTACTS_EXPORT_PDF, {}, 'post', {
                plotsIds: Object.values(selectedPlots).map((p) => p.plotId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_plots.export_contact_action') + ' PDF',
            });
        }} id="export_plot_contact_pdf">
        {t('table.export_pdf')}
      </ExportItemButton>
      <ExportItemButton key="export_plot_contact_excel" onExport={async () => {
            await exportXLSX(PLOTS_CONTACTS_EXPORT_XLSX, {}, 'post', {
                plotsIds: Object.values(selectedPlots).map((p) => p.plotId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_plots.export_contact_action') + ' Excel',
            });
        }} id="export_plot_contact_excel">
        {t('table.export_xlsx')}
      </ExportItemButton>
    </>);
    return (<Stack paddingX={5} paddingBottom={5}>
      <Table handleRowSelection={handleRowSelection} initialSelect={initialSelect} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }} headerTitle={<Stack spacing={2} direction={'row'} flexWrap="wrap">
            <Box width="250px">
              <Select ref={selectContactInputRef} placeholder={t('plot.contact.filters.placeholder')} closeMenuOnSelect={false} isMulti options={CONTACT_FILTERS_OPTIONS ?? []} styles={multiSelectStyles} isLoading={isLoadingUserPlotsList} onChange={onChangeContactFilter} loadingMessage={() => t('global.loading')} menuPlacement="top"/>
            </Box>
            <SeeMapButton action="Mes parcelles"/>
            <Button variant="primary" size="sm" onClick={handleUserPlotsNews} data-cy="plot_news_button">
              {t('tabs.plots_news.button')}
            </Button>
            {hasFeature('publipostage') && (<PublipostageDrawer plotContactsIds={plotContactsIds} triggerItem={(onOpen) => (<Button variant="primary" size="sm" disabled={noPlotsSelected} onClick={() => {
                        onOpen();
                        trackEvent({
                            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                            action: 'Parcelle importer publipostage',
                        });
                    }}>
                    {t(`tabs.courier.publipostage`)}
                  </Button>)}/>)}
            <DeleteButton id="my_plots_delete_button" size="sm" variant="outlineDelete" handleDelete={removeSelectedPlots} disabled={noPlotsSelected} showIcon confirmText={t('tabs.user_plots.remove_selection_confirm', {
                count: Object.keys(selectedPlots)?.length,
            })} label={t('tabs.user_plots.remove_selection', {
                count: !noPlotsSelected ? Object.keys(selectedPlots).length : 0,
            })}/>
          </Stack>} id="table-plots" variant="striped" data={plotsFiltered || []} columns={columns || []} isLoading={isLoadingUserPlotsList || isRefetching} backgroundColorHeader={HEADER_COLORS} initialSort={initialSort} exportTitle={t('tabs.user_plots.export_title')} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} sheetMode="horizontal" customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters} containerHeight="auto" analytics={{
            getColumnName: (id) => t(`tabs.user_plots.columns.${id}`),
            tableName: t('tabs.extra_tabs.user_plots.tab_title'),
        }} initialColumnVisibility={{
            contactType: false,
        }} exportItems={exportItems} exportButtonTitle={t('sidebar.search_plots.count_download_results', {
            count: Object.values(selectedPlots).length || 0,
        })} footerExtend={<TableExportButton exportItems={exportContactsButtons} exportButtonTitle={t('tabs.user_plots.export_contact_button')} isLoading={isLoadingUserPlotsList || isRefetching} analyticsAction={'Exportation les contacts des parcelles'} dataFiltered={Object.values(selectedPlots)}/>}/>
    </Stack>);
};
export default memo(MyPlotsPanel);
