import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsSecondarySelectionMode, usePlotSelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useSetSecondaryPlotCoord } from 'hooks/contexts/useSecondaryPlotsContext';
import { getSwktCenter } from 'utils/map';
const useOpenPlotFromMap = () => {
    const selectedTown = useSelectedTown();
    const navigate = useNavigate();
    const [plot, setPlot] = useState();
    const setSecondaryPlotCoord = useSetSecondaryPlotCoord();
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const plotSelectionMode = usePlotSelectionMode();
    useEffect(() => {
        if (plot) {
            handlePlotOpening();
        }
    }, [plot]);
    const handlePlotOpening = () => {
        const center = getSwktCenter(plot.swkt);
        if (isSecondarySelectionMode) {
            setSecondaryPlotCoord({
                mode: plotSelectionMode,
                coord: center,
                id: plot.id,
                town: selectedTown,
            });
        }
        else {
            navigate(`/parcelle/${selectedTown?.id}/${center?.lat().toString()}/${center
                ?.lng()
                .toString()}/${plot.id}`);
        }
        setPlot(undefined);
    };
    // Map layers use event listener which cannot access plotSelection mode in real time we need to use a state + useEffect to switch between plot selection modes
    const openPlotFromMap = useCallback((plot) => {
        setPlot(plot);
    }, []);
    return openPlotFromMap;
};
export default useOpenPlotFromMap;
