import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import InfoWindowTable from 'components/infoWindow/InfoWindowTable';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOChargingStationMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        count: iots.number,
    }),
]);
const IOChargingStationInfo = iots.partial({
    developerName: iots.string,
    sign: iots.string,
    operator: iots.string,
    address: iots.string,
    station: iots.string,
    powers: iots.array(iots.number),
});
const getLegends = () => ({
    charging_station: {
        layerKey: filtersKey.CHARGING_STATION,
        label: 'charging_station',
        needsTranslate: true,
        image: getMarkerImg({ count: 'X' }),
    },
});
const fetchChargingStationLayer = (townId) => () => get(iots.array(IOChargingStationMarkerItem), `${townId}/charging-station`);
const fetchChargingStationInfo = (townId, item) => {
    return get(iots.array(IOChargingStationInfo), `charging-station/${encodeURIComponent(item.id)}`);
};
const formatInfoWindowContent = (item, t) => {
    const content = (<InfoWindowTable content={[
            {
                title: t('info_window.charging_station.developer_name'),
                data: item.developerName,
            },
            {
                title: t('info_window.charging_station.address'),
                data: item.address,
            },
            {
                title: t('info_window.charging_station.sign'),
                data: item.sign,
            },
            {
                title: t('info_window.charging_station.operator'),
                data: item.operator,
                bold: true,
            },
            {
                title: t('info_window.charging_station.station'),
                data: item.station,
                bold: true,
            },
            {
                title: t('info_window.charging_station.nb_terminal'),
                data: item.powers.length,
            },
            {
                title: t('info_window.charging_station.powers'),
                data: item.powers.length > 0
                    ? [...new Set(item.powers)].join(' kW, ') + ' kW'
                    : '',
                bold: true,
            },
        ]}/>);
    return ReactDOMServer.renderToStaticMarkup(content);
};
const getMarkerImg = (marker) => {
    return `/api/public/get-charging-station-image/${marker.count}`;
};
const useChargingStationLayers = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = marker.id;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const ChargingStationInfo = await fetchChargingStationInfo(selectedTown?.id, marker);
        const content = formatInfoWindowContent(ChargingStationInfo, t);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchChargingStationLayer,
        markerImg: getMarkerImg,
        legends: getLegends(),
        getInfoWindowContent,
    })(filtersKey.CHARGING_STATION);
};
const ChargingStationLayers = () => {
    useChargingStationLayers();
    return null;
};
export default ChargingStationLayers;
