import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import StaffItem from 'components/tabs/plots/PlotSections/staff/StaffItem';
const OwnerStaff = ({ owner, setContactToCreate, }) => {
    const { t } = useTranslation();
    const [seeMore, setSeeMore] = useState(false);
    const { trackEvent } = useAnalytics();
    if (!owner.staff || owner.staff?.length === 0) {
        return;
    }
    return (<>
      <Box height={seeMore ? 'auto' : '52px'} overflow="hidden">
        {owner.staff.map((staff, index) => {
            return (<StaffItem key={index} owner={owner} staff={staff} index={index} setContactToCreate={setContactToCreate}/>);
        })}
      </Box>
      {owner.staff.length > 1 && (<Text as="span" textStyle="paragraph" textDecoration="underline" _hover={{ color: 'colorMode.font900' }} whiteSpace="normal" cursor="pointer" onClick={() => {
                trackEvent({
                    category: ANALYTICS_PLOT_TAB,
                    action: 'Voir plus de dirigeants',
                });
                if (seeMore) {
                    setSeeMore(false);
                }
                else {
                    setSeeMore(true);
                }
            }}>
          {seeMore
                ? t('tabs.plots.section.owner.see_less_manager')
                : t('tabs.plots.section.owner.see_more_manager')}
        </Text>)}
    </>);
};
export default OwnerStaff;
