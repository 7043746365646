import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsIcon } from '@chakra-ui/icons';
import { Box, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tooltip, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useUpdateFilterConfig } from 'hooks/contexts/useFiltersContext';
import { useSetLayersOpacity } from 'hooks/contexts/useMapContext';
import { HEIGHT_LEGEND_ICON } from 'utils/constants';
const SetOpacityButton = ({ tilesPosition, filterKey, filtersConfig, legends, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const updateFilterConfig = useUpdateFilterConfig();
    const config = filtersConfig?.[filterKey];
    const setLayersOpacity = useSetLayersOpacity();
    const filterOpacity = useMemo(() => {
        if (config?.opacity) {
            return config.opacity;
        }
        if (tilesPosition) {
            return 1;
        }
        if (!legends) {
            return null;
        }
        const colorLegends = Object.values(legends).filter((legend) => 'color' in legend);
        // If the legend only contains markers, we don't want to display the opacity button
        if (colorLegends.length === 0) {
            return null;
        }
        return colorLegends[0].opacity ?? 1;
    }, [config, tilesPosition, legends]);
    const defaultOpacity = (filterOpacity ?? 1) * 100;
    const [sliderValue, setSliderValue] = useState(Number(defaultOpacity.toFixed(2)));
    if (filterOpacity === null) {
        return null;
    }
    return (<Popover>
      <Tooltip label={t('legends.opacity')}>
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton variant="link" icon={<SettingsIcon width="12px" color="currentcolor"/>} aria-label="settings-layer" color="colorMode.font700" height={HEIGHT_LEGEND_ICON} minWidth={6} _hover={{
            color: 'colorMode.font900',
        }} borderRadius={0} onClick={(e) => {
            e.stopPropagation();
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: 'Changement opacité',
            });
        }}/>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent w={150}>
        <PopoverArrow />
        <PopoverBody>
          <Slider defaultValue={defaultOpacity} min={0} max={100} colorScheme="red.500" onChange={(v) => {
            setSliderValue(v);
            setLayersOpacity(filterKey, v / 100, tilesPosition);
            updateFilterConfig([filterKey], { opacity: v / 100 });
        }} onClick={(e) => {
            e.stopPropagation();
        }}>
            <SliderMark value={sliderValue} textAlign="center" bg="red.500" color="white" mt="-6" ml="-6" w="10" fontSize="12">
              {sliderValue}%
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack bgColor="red.500"/>
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </PopoverBody>
      </PopoverContent>
    </Popover>);
};
export default SetOpacityButton;
