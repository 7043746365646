import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, patch, post } from 'api/client';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOEmpty, IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { drawingToSwkt, getType } from 'utils/map';
export const USER_DRAWINGS_QUERY_KEY = ['layer', filtersKey.USER_DRAWINGS];
export const COLOR = '#A1400A';
export const OPACITY = 0.2;
const IOUserDrawingLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        note: iots.union([iots.string, iots.null]),
        showTextMarker: iots.boolean,
    }),
], 'IOUserDrawingLayerItem');
export const fetchUserDrawings = () => () => get(iots.array(IOUserDrawingLayerItem), `user-drawings`);
const createUserDrawingRequest = ({ drawing, showTextMarker, }) => post(IOUserDrawingLayerItem, 'user-drawings', {
    geom: drawingToSwkt(drawing),
    note: drawing instanceof google.maps.marker.AdvancedMarkerElement
        ? drawing.title
        : '',
    showTextMarker,
});
const updateUserDrawingRequest = ({ id, drawing, showTextMarker, }) => patch(IOUserDrawingLayerItem, `user-drawings/${id}`, {
    id: id,
    geom: drawingToSwkt(drawing),
    note: drawing instanceof google.maps.marker.AdvancedMarkerElement
        ? drawing.title
        : null,
    showTextMarker,
});
const deleteUserDrawingRequest = (id) => del(IOEmpty, `user-drawings/${id}`);
const getNewId = (drawings) => {
    let drawingId = 1;
    drawings.forEach((d) => {
        if (parseInt(d.id, 10) >= drawingId) {
            drawingId = parseInt(d.id, 10) + 1;
        }
    });
    return drawingId.toString();
};
const getDataUpdater = (id, drawing, showTextMarker) => (drawings) => {
    const existingDrawing = id ? drawings.find((d) => d.id === id) : {};
    const newData = [...drawings.filter((d) => !id || d.id !== id)];
    const newId = getNewId(drawings);
    const hasTextMarker = drawing instanceof google.maps.marker.AdvancedMarkerElement;
    const text = hasTextMarker ? drawing.title : '';
    const swkt = drawingToSwkt(drawing);
    let labelClass = null;
    if (hasTextMarker) {
        labelClass = 'user-drawing-text';
        if (showTextMarker) {
            labelClass += ' advanced-marker-label--with-marker';
        }
    }
    const newDrawing = {
        layerKey: filtersKey.USER_DRAWINGS,
        color: COLOR,
        opacity: OPACITY,
        type: drawing instanceof google.maps.marker.AdvancedMarkerElement
            ? 'MARKER'
            : getType(swkt),
        ...(existingDrawing ?? {}),
        id: newId,
        swkt: swkt,
        note: text,
        label: text,
        labelClickable: hasTextMarker,
        labelClass,
        showTextMarker,
    };
    newData.push(newDrawing);
    return newData;
};
const useUserDrawing = (onSaveDrawing, onRemoveDrawing) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const onErrorCallback = useOnError();
    const invalidateQuery = async () => {
        await queryClient.invalidateQueries({
            queryKey: USER_DRAWINGS_QUERY_KEY,
        });
    };
    const cancelFetch = async () => {
        await queryClient.cancelQueries({ queryKey: ['todos'] });
    };
    const userDrawings = queryClient.getQueryData(USER_DRAWINGS_QUERY_KEY);
    const { mutate: createUserDrawing } = useMutation({
        mutationFn: createUserDrawingRequest,
        onMutate: async ({ drawing, showTextMarker }) => {
            await cancelFetch();
            const previousData = userDrawings;
            queryClient.setQueryData(USER_DRAWINGS_QUERY_KEY, getDataUpdater(null, drawing, showTextMarker));
            return { previousData };
        },
        onSettled: () => {
            onSaveDrawing && onSaveDrawing();
            invalidateQuery();
        },
        onError: (err, _data, context) => {
            // @ts-expect-error previousData is added by onMutate
            queryClient.setQueryData(USER_DRAWINGS_QUERY_KEY, context.previousData);
            return onErrorCallback(err);
        },
        onSuccess: () => {
            getSuccessToast({
                title: t('toolbar.free_draw.created'),
                id: 'toolbar_free_draw_saved',
            });
        },
    });
    const { mutate: updateUserDrawing } = useMutation({
        mutationFn: updateUserDrawingRequest,
        onMutate: async ({ id, drawing, showTextMarker }) => {
            await cancelFetch();
            const previousData = userDrawings;
            queryClient.setQueryData(USER_DRAWINGS_QUERY_KEY, getDataUpdater(id, drawing, showTextMarker));
            return { previousData };
        },
        onSettled: () => {
            onSaveDrawing && onSaveDrawing();
        },
        onError: (err, _data, context) => {
            // @ts-expect-error previousData is added by onMutate
            queryClient.setQueryData(USER_DRAWINGS_QUERY_KEY, context.previousData);
            return onErrorCallback(err);
        },
        onSuccess: () => { },
    });
    const { mutate: removeUserDrawing } = useMutation({
        mutationFn: deleteUserDrawingRequest,
        onMutate: async (id) => {
            await cancelFetch();
            onRemoveDrawing && onRemoveDrawing();
            const previousData = userDrawings;
            queryClient.setQueriesData({ queryKey: USER_DRAWINGS_QUERY_KEY }, (oldData) => {
                return [...oldData].filter((d) => d.id !== id);
            });
            return { previousData };
        },
        onSettled: () => {
            invalidateQuery();
        },
        onError: (err) => {
            // @ts-expect-error previousData is added by onMutate
            queryClient.setQueryData(USER_DRAWINGS_QUERY_KEY, context.previousData);
            onErrorCallback(err);
        },
        onSuccess: (_data) => {
            getSuccessToast({
                title: t('toolbar.free_draw.deleted'),
                id: 'toolbar_free_draw_deleted',
            });
        },
    });
    return {
        userDrawings,
        updateUserDrawing,
        createUserDrawing,
        removeUserDrawing,
    };
};
export default useUserDrawing;
