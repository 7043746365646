import * as React from 'react';
import { createContext } from 'use-context-selector';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import { useSecondaryLayers } from 'hooks/contexts/useLayersTreeContext';
import { filtersKey, getFlattenedFilters, hideCategory, isFilterChecked, } from 'utils/filters';
const initiallyOpenedAccordions = [
    'regulatory',
    filtersKey.PLU,
    filtersKey.IMPACTED_PLOTS,
    filtersKey.PLU_ADDITIONAL,
];
const isObject = (el) => typeof el === 'object' && el !== null;
export const getOpenedIndices = (tree, openedAccordions) => {
    const openedIndices = [];
    const visibleTreeCategories = Object.keys(tree).filter((category) => {
        const filterContent = tree[category];
        const isHidden = !!filterContent?.hidden;
        return !isHidden && !hideCategory(filterContent);
    });
    visibleTreeCategories.map((category, index) => {
        if (openedAccordions?.includes(category)) {
            openedIndices.push(index);
        }
    });
    return openedIndices;
};
export const getAllAccordions = (tree) => {
    const accordions = [];
    Object.keys(tree).map((el) => {
        accordions.push(el);
        if (isObject(tree[el])) {
            accordions.push(getAllAccordions(tree[el]));
        }
    });
    return accordions
        .flat()
        .filter((el) => isNaN(el) &&
        ![
            'layers',
            'hidden',
            'id',
            'title',
            'content',
            'category',
            'needsTranslate',
            'documents',
            'color',
            'prefix',
            'hide_when_empty',
            'project_empty',
            'count',
            'find_out_more',
            'endpoint',
            'radioName',
            'subtitle',
            'feasibility',
        ]?.includes(el));
};
const getAccordionsWithCheckedFilters = (filters, tree) => {
    const openedAccordions = [];
    Object.keys(tree).map((el) => {
        const childrenFilters = getFlattenedFilters(tree[el]) ?? [];
        const hasAtLeastOneFilterChecked = childrenFilters.some((filter) => isFilterChecked(filter, filters));
        if (hasAtLeastOneFilterChecked) {
            openedAccordions.push(el);
            if (isObject(tree[el])) {
                openedAccordions.push(getAccordionsWithCheckedFilters(filters, tree[el]));
            }
        }
    });
    return openedAccordions.flat();
};
export const FiltersAccordionContext = createContext(null);
export const FiltersAccordionProvider = ({ children, }) => {
    const filters = useFilters();
    const [openedAccordions, setOpenedAccordions] = React.useState(initiallyOpenedAccordions);
    const [searchLayer, setSearchLayer] = React.useState('');
    const secondaryLayers = useSecondaryLayers();
    const allAccordions = getAllAccordions(secondaryLayers);
    const [isAllOpened, setIsAllOpened] = React.useState(false);
    const openAllAccordions = () => {
        setOpenedAccordions(allAccordions);
    };
    const closeAllAccordions = (keepOpenedFilters) => {
        let openedAccordions = [];
        if (keepOpenedFilters) {
            openedAccordions = [
                ...initiallyOpenedAccordions,
                ...getAccordionsWithCheckedFilters(filters, secondaryLayers),
            ];
        }
        setOpenedAccordions(openedAccordions);
    };
    const toggleAllOpened = () => {
        if (isAllOpened) {
            // Close all
            closeAllAccordions(false);
            setIsAllOpened(false);
        }
        else {
            openAllAccordions();
            setIsAllOpened(true);
        }
        setIsAllOpened(!isAllOpened);
    };
    const resetOpenedAccordions = () => {
        setOpenedAccordions(initiallyOpenedAccordions);
        setIsAllOpened(false);
    };
    const updateAccordions = ({ valuesToAdd = [], valuesToRemove = [], }) => {
        const newValues = [...openedAccordions, ...valuesToAdd].filter((el) => !valuesToRemove?.includes(el));
        setOpenedAccordions(newValues);
    };
    return (<FiltersAccordionContext.Provider value={{
            isAllOpened,
            setIsAllOpened,
            toggleAllOpened,
            openedAccordions,
            resetOpenedAccordions,
            updateAccordions,
            openAllAccordions,
            closeAllAccordions,
            searchLayer,
            setSearchLayer,
        }}>
      {children}
    </FiltersAccordionContext.Provider>);
};
export default FiltersAccordionContext;
