import snakeCase from 'lodash/snakeCase';
import { getFeasibilityRows } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { BUILDING_PLOT_TAB, FEASIBILITY_PLOT_TAB, NEIGHBORHOOD_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_PLOT_TAB, PLOT_RULES_PLOT_TAB, PLU_REGULATION_PLOT_TAB, SALES_PLOT_TAB, } from 'types/api';
import { getPlotExportName } from 'utils/plots';
import { formatBuildingData, getBuildingsFieldName } from './buildingsFormatter';
import { formatFeasibilityData, getFeasibilityFieldName, } from './feasibilityFormatter';
import { formatNeighborhoodData } from './neighborhoodFormatter';
import { formatOccupantCellValue, formatOccupantData, getOccupantFieldName, } from './occupantFormatter';
import { formatOwnersCellValue, formatOwnersData, getOwnersFieldName, } from './ownersFormatter';
import { formatPlotCellValue, formatPlotData } from './plotFormatter';
import { formatPluRegulation, formatPluRegulationCellValue, getPluRegulationFieldName, } from './pluRegulationFormatter';
import { formatSalesCellValue, formatSalesData } from './salesFormatter';
export const EXPORTED_FIELDS = {
    feasibility: getFeasibilityRows(),
};
export const HORIZONTAL_SECTIONS = [
    BUILDING_PLOT_TAB,
    SALES_PLOT_TAB,
    OWNER_PLOT_TAB,
    OCCUPANT_PLOT_TAB,
];
export const MULTI_SHEETS_SECTIONS = [NEIGHBORHOOD_PLOT_TAB];
export const getFieldName = ({ activeSection, t }) => (fieldName) => {
    switch (activeSection) {
        case PLU_REGULATION_PLOT_TAB:
            return getPluRegulationFieldName({ fieldName });
        case BUILDING_PLOT_TAB:
            return getBuildingsFieldName({ fieldName, t });
        case FEASIBILITY_PLOT_TAB:
            return getFeasibilityFieldName({ fieldName, t });
        case OWNER_PLOT_TAB:
            return getOwnersFieldName({ fieldName, t });
        case OCCUPANT_PLOT_TAB:
            return getOccupantFieldName({ fieldName, t });
    }
    return t(`tabs.plots.export.header.${snakeCase(fieldName)}`, fieldName);
};
export const getCellValue = ({ activeSection, t }) => (cellValue, fieldName) => {
    switch (activeSection) {
        case PLU_REGULATION_PLOT_TAB:
            return formatPluRegulationCellValue({ cellValue, fieldName });
        case PLOT_PLOT_TAB:
            return formatPlotCellValue({ cellValue, fieldName });
        case PLOT_RULES_PLOT_TAB:
            return formatPlotCellValue({ cellValue, fieldName }); // for rules export same as plotInfo
        case SALES_PLOT_TAB:
            return formatSalesCellValue({ cellValue, fieldName, t });
        case OWNER_PLOT_TAB:
            return formatOwnersCellValue({ cellValue, fieldName, t });
        case OCCUPANT_PLOT_TAB:
            return formatOccupantCellValue({ cellValue, fieldName });
        default:
            return cellValue;
    }
};
export const getSectionPlotData = (plotData) => {
    const { activeSection, occupant } = plotData;
    switch (activeSection) {
        case PLOT_PLOT_TAB:
            return formatPlotData(plotData);
        case PLOT_RULES_PLOT_TAB:
            return formatPlotData(plotData); // for rules export same as plotInfo
        case FEASIBILITY_PLOT_TAB:
            return formatFeasibilityData(plotData);
        case PLU_REGULATION_PLOT_TAB:
            return formatPluRegulation(plotData);
        case OWNER_PLOT_TAB:
            return formatOwnersData(plotData);
        case BUILDING_PLOT_TAB:
            return formatBuildingData(plotData);
        case SALES_PLOT_TAB:
            return formatSalesData(plotData);
        case NEIGHBORHOOD_PLOT_TAB:
            return formatNeighborhoodData(plotData);
        case OCCUPANT_PLOT_TAB:
            return formatOccupantData(occupant);
        default:
            return null;
    }
};
export const getSingleSectionSheets = (plotData) => {
    const { t, activeSection } = plotData;
    const getData = () => getSectionPlotData(plotData);
    return MULTI_SHEETS_SECTIONS.includes(activeSection)
        ? getData()
        : [
            {
                getData,
                sheetTitle: t(`tabs.plots.export.title.${activeSection}`) +
                    ' - ' +
                    getPlotExportName(plotData.plot, plotData.selectedTown.name)
                        .split('_')
                        .join(' '),
                formatHeader: getFieldName({ activeSection, t }),
                formatValue: getCellValue({ activeSection, t }),
                mode: HORIZONTAL_SECTIONS.includes(activeSection)
                    ? 'horizontal'
                    : 'vertical',
            },
        ];
};
export const getAllSectionsSheets = (sectionFiltered, plotData) => {
    return sectionFiltered.reduce((acc, section) => {
        const sectionsSheets = getSingleSectionSheets({
            ...plotData,
            activeSection: section,
        });
        return [...acc, ...sectionsSheets];
    }, []);
};
