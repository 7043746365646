import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Input, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { debounce } from 'lodash';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { BUILDING_CONSUMPTION_SECTOR_OPTIONS } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const BuildingConsumptionSidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const [consumptionValue, setConsumptionValue] = React.useState(extraFilters?.[filtersKey.BUILDING_CONSUMPTION]?.consumption || '');
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const sectorSelectedOption = extraFilters?.[filtersKey.BUILDING_CONSUMPTION]?.sector || 0;
    const sectorDisplayOptions = BUILDING_CONSUMPTION_SECTOR_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const debounceHandleConsumptionChange = useMemo(() => debounce((value) => {
        updateExtraFilter(`${filtersKey.BUILDING_CONSUMPTION}.consumption`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.BUILDING_CONSUMPTION}`)} ${t('sidebar_content.building_consumption.consumption.label')}`,
        });
    }, 500), []);
    const handleConsumptionChange = (e) => {
        const value = e.target.value;
        setConsumptionValue(value);
    };
    const handleSectorChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.BUILDING_CONSUMPTION}.sector`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.BUILDING_CONSUMPTION}}`)} ${t('sidebar_content.building_consumption.sector.label')}`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.building_consumption.consumption.label')}&nbsp;:
        </FormLabel>
        <Input size="sm" placeholder={t('sidebar_content.building_consumption.consumption.placeholder')} onChange={(e) => {
            handleConsumptionChange(e);
            debounceHandleConsumptionChange(e.target.value);
        }} value={consumptionValue} min={0} type="number"/>
      </FormControl>
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.building_consumption.sector.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" onChange={handleSectorChange} value={sectorSelectedOption}>
          {sectorDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default BuildingConsumptionSidebarContent;
