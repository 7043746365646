import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Heading, HStack, Input, Select, Spinner, Text, VStack, } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useRealEstateTaxCalculationInfo from 'hooks/useRealEstateTaxCalculationInfo';
import { formatNumberToLocale } from 'utils/formatNumber';
import CalculatedData from './CalculatedData';
import InputWrapper from './InputWrapper';
const headingProps = {
    variant: 'h4',
    width: 'full',
    paddingTop: 5,
    fontWeight: 800,
};
const resolver = async (values) => {
    return {
        values: values,
        errors: {},
    };
};
const initialDefaultValues = {
    communalTax: null,
    departmentalTax: null,
    regionalTax: null,
    IDFValue: null,
    photovoltaicPanelsSurface: null,
    numberWindTurbines: null,
    powerIfer: null,
};
const FormEnergy = ({ defaultValues, getDataToExport, }) => {
    const { t, i18n } = useTranslation();
    const selectedTown = useSelectedTown();
    const { data, isInitialLoading: isLoading } = useRealEstateTaxCalculationInfo(selectedTown?.id);
    const { register, watch, reset, setValue } = useForm({
        defaultValues: initialDefaultValues,
        resolver,
    });
    useEffect(() => {
        if (data) {
            const initialCommunalTax = data?.communalTaxesByTown && data?.communalTaxesByTown.length > 0
                ? data?.communalTaxesByTown[0].tax
                : data?.communalTax;
            reset({
                ...initialDefaultValues,
                ...defaultValues,
                IDFValue: data?.IDFValue,
                communalTax: initialCommunalTax,
                departmentalTax: data?.departmentTax,
                regionalTax: data?.regionalTax || null,
            });
        }
    }, [data]);
    const { communalTax, departmentalTax, regionalTax, IDFValue, photovoltaicPanelsSurface, numberWindTurbines, powerIfer, } = watch();
    const formatNumber = (value) => {
        const roundedValue = Math.round(value);
        return isUndefined(roundedValue) || isNaN(roundedValue)
            ? '-'
            : formatNumberToLocale(roundedValue, i18n.language);
    };
    const PHOTOVOLTAIC_PANELS_FORFAIT = 10;
    const WIND_TURBINES_FORFAIT = 3000;
    const photovoltaicPanelsTax = (photovoltaicPanelsSurface || 0) * PHOTOVOLTAIC_PANELS_FORFAIT;
    const windTurbinesTax = (numberWindTurbines || 0) * WIND_TURBINES_FORFAIT;
    const totalRealEstateTaxIfer = powerIfer * 10;
    const getFacilitiesTotalTax = (tax) => {
        return Math.round((photovoltaicPanelsTax + windTurbinesTax) * (tax / 100));
    };
    const communalSubtotalFacilities = getFacilitiesTotalTax(communalTax || 0);
    const departmentalSubtotalFacilities = getFacilitiesTotalTax(departmentalTax || 0);
    const regionalSubtotalFacilities = getFacilitiesTotalTax(regionalTax || 0);
    const totalRealEstateTaxFacilities = communalSubtotalFacilities +
        departmentalSubtotalFacilities +
        regionalSubtotalFacilities;
    // TOTALS CALCULATIONS
    const projectTotalPrice = totalRealEstateTaxFacilities + totalRealEstateTaxIfer;
    useEffect(() => {
        getDataToExport({
            communal_tax: `${communalTax || 0} %`,
            departmental_tax: `${departmentalTax || 0} %`,
            regional_tax: `${regionalTax || 0} %`,
            IDF_value: `${IDFValue || 0} €`,
            photovoltaic_panels_surface: `${photovoltaicPanelsSurface || 0} m²`,
            number_wind_turbines: numberWindTurbines || 0,
            power_ifer: powerIfer || 0,
            total_real_estate_tax_facilities: `${formatNumber(totalRealEstateTaxFacilities)} €`,
            total_real_estate_tax_ifer: `${formatNumber(totalRealEstateTaxIfer)} €`,
        });
    }, [JSON.stringify(watch())]);
    if (isLoading) {
        return (<Box>
        <Spinner />
      </Box>);
    }
    return (<form>
      <VStack paddingBottom={5} alignItems="flex-start">
        <>
          {/* calc_final_total */}
          <CalculatedData label="project_total_price" value={Math.round(projectTotalPrice)}/>
        </>

        {/* Taux officiels en vigueur */}
        <>
          <Divider />
          <HStack width="full" paddingTop={5} justifyContent="space-between">
            <Heading as="h4" variant="h4" fontWeight={800}>
              {t('modals.real_estate_tax.official_rates')}
            </Heading>
            {data?.communalTaxesByTown && (<Text textStyle="paragraph">
                {t('modals.real_estate_tax.type_rate')}
              </Text>)}
          </HStack>
          {/* param_ta_comm */}
          <InputWrapper label="communal_tax" measureUnit="%" extraInput={data?.communalTaxesByTown && (<Select size="sm" value={communalTax || ''} onChange={(event) => setValue('communalTax', parseInt(event.target.value))}>
                  {data?.communalTaxesByTown &&
                data.communalTaxesByTown.map(({ name, tax }) => (<option key={name} value={tax}>
                        {name}
                      </option>))}
                </Select>)}>
            <Input textAlign="right" placeholder="0" type="number" {...register('communalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_ta_dept */}
          <InputWrapper label="departmental_tax" measureUnit="%">
            <Input textAlign="right" placeholder="0" type="number" {...register('departmentalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_ta_reg */}
          <InputWrapper label="regional_tax" measureUnit="%">
            <Input textAlign="right" placeholder="0" type="number" {...register('regionalTax', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* param_val_forfait */}
          <InputWrapper label="IDF_value" labelParams={{
            link: 'https://entreprendre.service-public.fr/vosdroits/F23263',
        }} measureUnit="€">
            <Input textAlign="right" placeholder="0" type="number" {...register('IDFValue', { valueAsNumber: true })}/>
          </InputWrapper>
        </>

        <>
          <Divider />
          <Heading as="h4" {...headingProps}>
            {t('modals.real_estate_tax.add_facilities')}
          </Heading>
          {/* in_surf_photovolta */}
          <InputWrapper label="photovoltaic_panels_surface">
            <Input textAlign="right" placeholder="0" type="number" {...register('photovoltaicPanelsSurface', {
        valueAsNumber: true,
    })}/>
          </InputWrapper>
          {/* calc_nb_eol */}
          <InputWrapper label="number_wind_turbines" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('numberWindTurbines', { valueAsNumber: true })}/>
          </InputWrapper>
          <InputWrapper label="power_ifer" measureUnit="">
            <Input textAlign="right" placeholder="0" type="number" {...register('powerIfer', { valueAsNumber: true })}/>
          </InputWrapper>
          {/* calc_ta_installation_total */}
          <CalculatedData label="total_real_estate_tax_facilities" value={Math.round(totalRealEstateTaxFacilities)}/>
          <CalculatedData label="total_real_estate_tax_ifer" value={Math.round(totalRealEstateTaxIfer)}/>
        </>
      </VStack>
    </form>);
};
export default FormEnergy;
