import { initReactI18next } from 'react-i18next';
import * as i18n from 'i18next';
import * as translationDe from './locales/de/translation.json';
import * as translationEn from './locales/en/translation.json';
import * as translationFr from './locales/fr/translation.json';
import * as translationIt from './locales/it/translation.json';
import { localStorageSetGet } from './utils/localStorage';
export const resources = {
    en: {
        translation: translationEn,
    },
    fr: {
        translation: translationFr,
    },
    de: {
        translation: translationDe,
    },
    it: {
        translation: translationIt,
    },
};
i18n.use(initReactI18next).init({
    resources,
    lng: localStorageSetGet('lang').get('fr'),
    fallbackLng: 'fr',
    returnNull: false,
});
export default i18n;
