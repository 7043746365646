import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { filtersKey } from 'utils/filters';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
export const FilterResultsTabKeys = [
    filtersKey.BUILDING_PERMIT_HOUSING,
    filtersKey.BUILDING_PERMIT_OFFICES,
    filtersKey.BUILDING_PERMIT_PLANNING_PERMIT,
    filtersKey.BANKRUPT_COMPANIES_PLOTS,
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.COMPANIES,
    filtersKey.COMPANY_OWNED_MANAGER,
];
const FilterResultTabItem = (props) => {
    const { t } = useTranslation();
    const { tab } = props;
    const extraFilters = useExtraFilters();
    const sirens = extraFilters[filtersKey.COMPANY_OWNED_PLOTS]?.sirens;
    const manager = extraFilters[filtersKey.COMPANY_OWNED_MANAGER]
        ?.manager;
    const selectedTown = useSelectedTown();
    const getTabDetail = () => {
        if (tab.id === filtersKey.BUILDING_PERMIT_HOUSING ||
            tab.id === filtersKey.BUILDING_PERMIT_OFFICES ||
            tab.id === filtersKey.BUILDING_PERMIT_PLANNING_PERMIT ||
            tab.id === filtersKey.BANKRUPT_COMPANIES_PLOTS ||
            tab.id === filtersKey.BANKRUPT_COMPANIES ||
            tab.id === filtersKey.COMPANIES) {
            return (t('tabs.department') +
                ' ' +
                selectedTown?.departmentName +
                ' (' +
                selectedTown?.department +
                ')');
        }
        if (tab.id === filtersKey.COMPANY_OWNED_PLOTS) {
            return sirens ? sirens[0] : '';
        }
        if (tab.id === filtersKey.COMPANY_OWNED_MANAGER) {
            return manager.firstname + ' ' + manager.name;
        }
        return '';
    };
    const title = t(`tabs.extra_tabs.${tab.id}.tab_title`);
    const detail = getTabDetail();
    return (<TabItemWrapper {...props}>
      <TabItemTitle title={title} detail={detail}/>
    </TabItemWrapper>);
};
export default FilterResultTabItem;
