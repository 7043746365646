import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Heading, Text, } from '@chakra-ui/react';
import ActualBuildingSearch, { actualBuildingSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/ActualBuildingSearch';
import BaseSearch, { baseSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/BaseSearch';
import OwnerSearch, { ownerSearchFields, } from 'components/sidebar/PlotSearchSideBar/form/OwnerSearch';
import OwnerSearchOptional from 'components/sidebar/PlotSearchSideBar/form/OwnerSearchOptional';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useCurrentSite from 'hooks/useCurrentSite';
import usePlotSearchFilters from 'hooks/usePlotSearchFilters';
import { accordionArrowStyle, accordionBoxShadow, } from 'utils/accordionArrowStyle';
import CompanySearch, { companySearchFields } from './CompanySearch';
import FeasibilitySearch, { feasibilitySearchFields } from './FeasibilitySearch';
import PlotTypeSearch, { plotTypeSearchFields } from './PlotTypeSearch';
import RulesSearch, { rulesSearchFields } from './RulesSearch';
const filterBackgroundColor = {
    0: 'colorMode.background900',
    1: 'colorMode.sidebar600', // background
    2: 'colorMode.background700', // hover
    3: 'colorMode.background600', // borderBottom
    4: 'colorMode.background500',
};
export const subFilterBackgroundColor = {
    0: 'gray.100', // background
    1: 'gray.200', // hover
};
export const PADDING = 4;
const PlotsSearchFormFields = ({ isLoadingLayersTree, isLoadingSearchData, hasSearchField, }) => {
    const { t } = useTranslation();
    const form = useFormContext();
    const isLoading = isLoadingSearchData || isLoadingLayersTree;
    const { currentSite } = useCurrentSite();
    const selectedTown = useSelectedTown();
    const { filters } = usePlotSearchFilters({ townshipId: selectedTown?.id });
    const hasSearchFields = (fieldNames) => fieldNames.some(hasSearchField);
    const hasFilter = (filter) => (filters ? filters[filter] : false);
    return (<Accordion allowMultiple border="none" maxWidth="400px" defaultIndex={[0]}>
      {hasSearchFields(baseSearchFields) && (<AccordionItem borderBottom="none" data-cy="plot_search_base_search_panel">
          <Heading marginTop="-1px" as="h2" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" fontWeight={500}>
                  {t(`sidebar.search_plots.search_plots`)}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <BaseSearch form={form} isLoadingSearchData={isLoadingSearchData} hasSearchField={hasSearchField}/>
          </AccordionPanel>
        </AccordionItem>)}

      {hasSearchFields(feasibilitySearchFields) &&
            selectedTown.hasFeasibility && (<AccordionItem borderBottom="none" data-cy="plot_search_feasibility_search_panel">
            <Heading marginTop="-1px" as="h2" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
              <AccordionButton paddingY="12px" alignItems="center">
                <Box flex={1} textAlign="left">
                  <Text textStyle="paragraph" fontWeight={500}>
                    {selectedTown?.additionalPLU
                ? t('sidebar.search_plots.feasibility_decided')
                : t('sidebar.search_plots.feasibility')}
                  </Text>
                </Box>
                <AccordionIcon color="colorMode.font700"/>
              </AccordionButton>
            </Heading>
            <AccordionPanel padding={0}>
              <FeasibilitySearch form={form} isLoadingSearchData={isLoadingSearchData} hasSearchField={hasSearchField} hasFilter={hasFilter}/>
            </AccordionPanel>
          </AccordionItem>)}

      {hasSearchFields(actualBuildingSearchFields) && (<AccordionItem borderBottom="none" data-cy="plot_search_advanced_search_panel">
          <Heading marginTop="-1px" as="h1" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" textOverflow="ellipsis" fontWeight={500}>
                  {t('sidebar.search_plots.search_actual_building')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <ActualBuildingSearch isLoading={isLoading} hasSearchField={hasSearchField}/>
          </AccordionPanel>
        </AccordionItem>)}

      {hasSearchFields(plotTypeSearchFields) && (<AccordionItem borderBottom="none">
          <Heading marginTop="-1px" as="h1" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" textOverflow="ellipsis" fontWeight={500}>
                  {t('sidebar.search_plots.search_plot_type')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <PlotTypeSearch isLoading={isLoading} hasSearchField={hasSearchField} form={form}/>
          </AccordionPanel>
        </AccordionItem>)}

      {hasSearchFields(rulesSearchFields) && (<AccordionItem borderBottom="none">
          <Heading marginTop="-1px" as="h1" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" textOverflow="ellipsis" fontWeight={500}>
                  {t('sidebar.search_plots.search_rules')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <RulesSearch isLoading={isLoading} hasSearchField={hasSearchField}/>
          </AccordionPanel>
        </AccordionItem>)}

      {(currentSite.ownerPublicity ||
            (currentSite.ownerSubscription &&
                hasSearchFields(ownerSearchFields))) && (<AccordionItem borderBottom="none">
          <Heading marginTop="-1px" as="h1" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" fontWeight={500}>
                  {t('sidebar.search_plots.search_by_owner')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>

          <AccordionPanel padding={0} data-cy="plot_search_owner_panel">
            {currentSite.ownerPublicity && (<OwnerSearchOptional boxShadow={accordionBoxShadow} padding={4}/>)}
            {currentSite.ownerSubscription && (<OwnerSearch form={form} hasSearchField={hasSearchField} isLoading={isLoading}/>)}
          </AccordionPanel>
        </AccordionItem>)}

      {hasSearchFields(companySearchFields) && (<AccordionItem borderBottom="none">
          <Heading marginTop="-1px" as="h1" backgroundColor={filterBackgroundColor[1]} borderBottomWidth={1} borderBottomColor={filterBackgroundColor[3]} position="relative" _after={{
                ...accordionArrowStyle,
                borderTopColor: filterBackgroundColor[1],
            }} _hover={{
                _after: {
                    borderTopColor: filterBackgroundColor[2],
                },
            }} boxShadow={accordionBoxShadow}>
            <AccordionButton paddingY="12px" alignItems="center">
              <Box flex={1} textAlign="left">
                <Text textStyle="paragraph" textOverflow="ellipsis" fontWeight={500}>
                  {t('sidebar.search_plots.search_company')}
                </Text>
              </Box>
              <AccordionIcon color="colorMode.font700"/>
            </AccordionButton>
          </Heading>
          <AccordionPanel padding={0}>
            <CompanySearch isLoading={isLoading} hasSearchField={hasSearchField}/>
          </AccordionPanel>
        </AccordionItem>)}
    </Accordion>);
};
export default PlotsSearchFormFields;
