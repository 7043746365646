import { useCallback, useMemo } from 'react';
import { useActiveUserDrawing, useDrawingMode, useSetActiveUserDrawing, } from 'hooks/contexts/useLocationContext';
import { COLOR, fetchUserDrawings, OPACITY, } from 'hooks/crm/useUserDrawing';
import createLayersHook from 'hooks/layers/createLayersHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
export const DRAWING_MARKER_ICON_PATH = 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
export const getDrawingMarkerIcon = (color) => ({
    path: DRAWING_MARKER_ICON_PATH,
    fillOpacity: 1,
    fillColor: color,
    strokeWeight: 1,
    strokeColor: 'white',
    scale: 2,
});
const getMarkerImage = (item) => {
    if (item.note && !item.showTextMarker) {
        return '';
    }
    return getDrawingMarkerIcon(COLOR);
};
const getLegends = () => {
    const legends = {};
    legends.user_drawing_marker = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_marker',
        needsTranslate: true,
        image: getDrawingMarkerIcon(COLOR),
    };
    legends.user_drawing_line = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_line',
        needsTranslate: true,
        color: COLOR,
        opacity: OPACITY,
        width: 20,
        borderWidth: 4,
        polygonType: VECTOR_TYPES.polyline,
    };
    legends.user_drawing_polygon = {
        layerKey: filtersKey.USER_DRAWINGS,
        label: 'user_drawing_polygon',
        needsTranslate: true,
        color: COLOR,
        opacity: OPACITY,
    };
    return legends;
};
const useUserDrawingsLayer = () => {
    const activeUserDrawing = useActiveUserDrawing();
    const setActiveUserDrawing = useSetActiveUserDrawing();
    const drawingMode = useDrawingMode();
    const showDrawing = useCallback((drawing) => {
        return !activeUserDrawing || activeUserDrawing.id !== drawing.id;
    }, [activeUserDrawing]);
    const handleClick = useMemo(() => {
        if (!drawingMode || (drawingMode === 'free_edit' && !activeUserDrawing)) {
            return setActiveUserDrawing;
        }
        return undefined;
    }, [setActiveUserDrawing, activeUserDrawing, drawingMode]);
    createLayersHook({
        fetch: fetchUserDrawings,
        alwaysEnabled: true,
        availableWithoutTown: true,
        onClick: handleClick,
        markerImg: getMarkerImage,
        legends: getLegends(),
        allowLayer: showDrawing,
    })(filtersKey.USER_DRAWINGS);
};
const UserDrawingsLayer = () => {
    useUserDrawingsLayer();
    return null;
};
export default UserDrawingsLayer;
