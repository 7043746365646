import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { IconButton, Tooltip } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { useIsHiddenFilter, useToggleHiddenFilter, } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { HEIGHT_LEGEND_ICON } from 'utils/constants';
const NO_HIDE_FILTER = ['plot_legend'];
const HideFilterButton = ({ filterName }) => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const isHiddenFilter = useIsHiddenFilter();
    const toggleHiddenFilter = useToggleHiddenFilter();
    const label = t(`legends.visibility.${isHiddenFilter([filterName]) ? 'show' : 'hide'}`);
    if (NO_HIDE_FILTER?.includes(filterName))
        return null;
    React.useEffect(() => {
        toggleHiddenFilter({ hideFilter: false, filterName });
    }, []);
    return (<Tooltip label={label}>
      <IconButton variant="link" onClick={(e) => {
            e.stopPropagation();
            trackEvent({
                category: ANALYTICS_MAP_BUTTONS,
                action: label,
            });
            toggleHiddenFilter({ filterName });
        }} color="colorMode.font700" icon={isHiddenFilter([filterName]) ? (<ViewOffIcon opacity="0.6" color="currentcolor"/>) : (<ViewIcon color="currentcolor"/>)} aria-label={label} hidden={!selectedTown || !selectedTown?.hasPLU} _hover={{
            color: 'colorMode.font900',
        }} height={HEIGHT_LEGEND_ICON} minWidth={6}/>
    </Tooltip>);
};
export default HideFilterButton;
