import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, FormControl } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_FILTER_RPG } from 'analytics/useAnalytics';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import { useFilters, useToggleFilter, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useRPG from 'hooks/filter/useRPG';
import { filtersKey } from 'utils/filters';
const PhotovoltaicNotProneAreaRPGSidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { farmingList, isLoadingFarming } = useRPG(selectedTown?.id);
    const toggleFilter = useToggleFilter();
    const filters = useFilters();
    const farmingIdDisplayOptions = farmingList?.map((farming) => ({
        value: farming.id,
        label: farming.name,
    }));
    const handleFarmingChange = (inputText) => {
        const values = [];
        const text = [];
        inputText.forEach((option) => {
            if (option.value !== '') {
                values.push(option.value);
                text.push(option.label);
            }
        });
        // Si on renseigne une valeur on coche le filtre
        if (values.length > 0 &&
            !filters[filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]) {
            toggleFilter(filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST, true);
        }
        updateExtraFilter(`${filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST}.farmingIds`, values);
        trackEvent({
            category: ANALYTICS_FILTER_RPG,
            action: text.join(' ; '),
        });
    };
    const methods = useForm();
    return (<Box width="full">
      <FormProvider {...methods}>
        <form>
          <FormControl display="flex" alignItems="center">
            <Select placeholder={t('global.indifferent')} noOptionsMessage={() => t('sidebar_content.rpg.no_data_farming')} isMulti options={farmingIdDisplayOptions} styles={{
            ...multiSelectStyles,
            menuList: (provided) => ({
                ...provided,
                backgroundColor: 'var(--colorModeBase)',
                maxHeight: '150px',
            }),
        }} isLoading={isLoadingFarming} onChange={handleFarmingChange} closeMenuOnSelect={false}/>
          </FormControl>
        </form>
      </FormProvider>
    </Box>);
};
export default PhotovoltaicNotProneAreaRPGSidebarContent;
