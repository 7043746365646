import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Spinner, VStack } from '@chakra-ui/react';
import useUserStatisticsCRM from 'hooks/crm/useUserStatisticsCRM';
import { getProjectStatusOption } from 'utils/personalization';
import { isAvailable } from '../TeamStatisticsCRMPanel';
import UserStatisticsStatusItem from './UserStatisticsStatusItem';
const UserStatisticsProjectsPanel = ({ setDataProject, filters, }) => {
    const { t } = useTranslation();
    const [dataFormatted, setDataFormatted] = useState({
        status: {},
        percent: {},
    });
    const { statsProjects, isLoadingStatsProjects } = useUserStatisticsCRM();
    useEffect(() => {
        if (statsProjects) {
            const data = { status: {}, percent: {} };
            let turnover = 0;
            let lots = 0;
            let surface = 0;
            getProjectStatusOption().map((option) => {
                data.status[option.value] = 0;
                data.percent[option.value] = 0;
            });
            const nbProjects = statsProjects.length;
            statsProjects.forEach((project) => {
                const date = new Date(project.updatedAt);
                if (!isAvailable({ filters, date })) {
                    return;
                }
                turnover += project.turnover;
                lots += project.lots;
                surface += project.rightToBuild;
                data.status[project.status] += 1;
                const percent = (data.status[project.status] / nbProjects) * 100;
                data.percent[project.status] = percent.toFixed(2);
            });
            setDataProject({ turnover, lots, surface });
            setDataFormatted(data);
        }
    }, [statsProjects, filters]);
    return (<Box flex={1}>
      <Heading as="h3" variant="h3" marginBottom={2}>
        {t('tabs.my_statistics_crm.my_projects')}
      </Heading>
      <VStack spacing={1} alignItems="flex-start" position="relative">
        {getProjectStatusOption().map((option) => {
            return (<UserStatisticsStatusItem key={option.value} option={option} data={dataFormatted}/>);
        })}
        {isLoadingStatsProjects && (<Box display="flex" alignItems="center" justifyContent="center" position="absolute" top={0} left={0} width="100%" height="100%" backgroundColor="rgba(251, 250, 249, 0.6)">
            <Spinner />
          </Box>)}
      </VStack>
    </Box>);
};
export default memo(UserStatisticsProjectsPanel);
