import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePersistentContext from 'hooks/usePersistentContext';
import { PLOT_STATUS_OPTIONS, PLOT_STATUS_VALUES, PLOT_STATUS_VALUES_WITH_NONE, PROJECT_STATUS_OPTIONS, PROJECT_STATUS_VALUES, PROJECT_STATUS_VALUES_WITH_NONE, } from 'utils/constants';
import usePersonalizationPlot from './usePersonalizationPlot';
import usePersonalizationProject from './usePersonalizationProject';
export const PERSONALIZATION_KEY = 'personalization';
const usePersonalization = () => {
    const { t } = useTranslation();
    const [personalization, setPersonalization] = usePersistentContext(PERSONALIZATION_KEY);
    const { personalizationPlotStatus } = usePersonalizationPlot();
    const { personalizationProjectStatus } = usePersonalizationProject();
    useEffect(() => {
        const perso = personalization || {};
        const persoPlot = {
            plotStatusValues: {},
            plotStatusValuesWithNone: {},
            plotStatusOptions: [],
        };
        const persoProject = {
            projectStatusValues: {},
            projectStatusValuesWithNone: {},
            projectStatusOptions: [],
        };
        if (personalizationPlotStatus &&
            personalizationPlotStatus.status.length > 0) {
            personalizationPlotStatus.status.forEach((plotStatus) => {
                persoPlot.plotStatusValues[plotStatus.statusId] = {
                    label: plotStatus.label,
                    color: plotStatus.color,
                    legendOrder: plotStatus.order,
                };
                persoPlot.plotStatusOptions.push({
                    value: plotStatus.statusId,
                    label: plotStatus.label,
                    color: plotStatus.color,
                    legendOrder: plotStatus.order,
                });
            });
            persoPlot.plotStatusValuesWithNone = {
                0: { label: t('plot.status.none'), color: '#FFFFFF', legendOrder: 9 },
                ...persoPlot.plotStatusValues,
            };
        }
        else {
            persoPlot.plotStatusValues = Object.keys(PLOT_STATUS_VALUES).map((key) => {
                return {
                    ...PLOT_STATUS_VALUES[key],
                    label: t(PLOT_STATUS_VALUES[key].label),
                };
            });
            persoPlot.plotStatusValuesWithNone = Object.keys(PLOT_STATUS_VALUES_WITH_NONE).map((key) => {
                return {
                    ...PLOT_STATUS_VALUES_WITH_NONE[key],
                    label: t(PLOT_STATUS_VALUES_WITH_NONE[key].label),
                };
            });
            persoPlot.plotStatusOptions = Object.keys(PLOT_STATUS_OPTIONS).map((key) => {
                return {
                    ...PLOT_STATUS_OPTIONS[key],
                    label: t(PLOT_STATUS_OPTIONS[key].label),
                };
            });
        }
        if (personalizationProjectStatus &&
            personalizationProjectStatus.status.length > 0) {
            personalizationProjectStatus.status.forEach((projectStatus) => {
                persoProject.projectStatusValues[projectStatus.statusId] = {
                    label: projectStatus.label,
                    color: projectStatus.color,
                    legendOrder: projectStatus.order,
                };
                persoProject.projectStatusOptions.push({
                    value: projectStatus.statusId,
                    label: projectStatus.label,
                    color: projectStatus.color,
                    legendOrder: projectStatus.order,
                });
            });
            persoProject.projectStatusValuesWithNone = {
                0: {
                    label: t('project.status.none'),
                    color: '#FFFFFF',
                    legendOrder: 9,
                },
                ...persoProject.projectStatusValues,
            };
        }
        else {
            persoProject.projectStatusValues = Object.keys(PROJECT_STATUS_VALUES).map((key) => {
                return {
                    ...PROJECT_STATUS_VALUES[key],
                    label: t(PROJECT_STATUS_VALUES[key].label),
                };
            });
            persoProject.projectStatusValuesWithNone = Object.keys(PROJECT_STATUS_VALUES_WITH_NONE).map((key) => {
                return {
                    ...PROJECT_STATUS_VALUES_WITH_NONE[key],
                    label: t(PROJECT_STATUS_VALUES_WITH_NONE[key].label),
                };
            });
            persoProject.projectStatusOptions = Object.keys(PROJECT_STATUS_OPTIONS).map((key) => {
                return {
                    ...PROJECT_STATUS_OPTIONS[key],
                    label: t(PROJECT_STATUS_OPTIONS[key].label),
                };
            });
        }
        perso.project = persoProject;
        perso.plot = persoPlot;
        setPersonalization(perso);
    }, [personalizationPlotStatus, personalizationProjectStatus]);
    return {
        personalization,
        setPersonalization,
    };
};
export default usePersonalization;
