import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel, HStack, NumberInput, NumberInputField, Stack, } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import SearchCheckbox from 'components/inputs/SearchCheckbox';
import SelectInput from 'components/inputs/SelectInput';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { FARMING_KEY, REDUCED_VAT_ZONE_KEY, } from 'utils/plotsSearch';
import { getPlotSelectOptions } from './ActualBuildingSearch';
export const ELEVATION_OPTIONS_KEY = {
    1: 'sidebar.search_plots.possible_elevations_option.level1',
    2: 'sidebar.search_plots.possible_elevations_option.level2',
    3: 'sidebar.search_plots.possible_elevations_option.level3',
};
export const rulesSearchFields = [
    'reducedVATZone',
    'farming',
    'excludeFloodProneAreas',
    'socialMix',
];
const RulesSearch = ({ isLoading, hasSearchField, }) => {
    const { t } = useTranslation();
    const { control, register } = useFormContext();
    const selectedTown = useSelectedTown();
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2}>
      {hasSearchField('reducedVATZone') && (<SelectInput size="sm" variant="filled" isLoading={isLoading} name="reducedVATZone" label={t('sidebar.search_plots.reduced_vat_zone')} options={getPlotSelectOptions(REDUCED_VAT_ZONE_KEY, 'reduced_vat_zone', t)}/>)}

      {hasSearchField('farming') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.farming')}
          </FormLabel>
          <HStack>
            <Controller name="farming" control={control} render={({ field }) => (<Select {...field} placeholder={t('global.indifferente')} closeMenuOnSelect={false} options={getPlotSelectOptions(FARMING_KEY, 'farming', t, true)} isMulti styles={multiSelectStyles}/>)}/>
          </HStack>
        </FormControl>)}

      {hasSearchField('excludeFloodProneAreas') && (<FormControl display="flex" isDisabled={isLoading}>
          <SearchCheckbox name="excludeFloodProneAreas"/>
          <FormLabel margin="0" paddingLeft={3} cursor="pointer">
            {t('sidebar.search_plots.exclude_flood_prone_areas')}
          </FormLabel>
        </FormControl>)}

      {selectedTown?.hasRegulationAnalysis && hasSearchField('socialMix') && (<>
          <FormControl isDisabled={isLoading}>
            <FormLabel htmlFor="socialMix" marginBottom={1}>
              {t('sidebar.search_plots.social_mix')}
            </FormLabel>
            <HStack>
              <AreaInputWrapper rightElementLabel="%">
                <NumberInput size="sm" min={0} max={100}>
                  <NumberInputField placeholder="Min" maxLength={5} {...register('socialMixMin', { valueAsNumber: true })} data-cy="min_social_mix_input"/>
                </NumberInput>
              </AreaInputWrapper>
              <AreaInputWrapper rightElementLabel="%">
                <NumberInput size="sm" min={0} max={100}>
                  <NumberInputField placeholder="Max" maxLength={5} {...register('socialMixMax', { valueAsNumber: true })} data-cy="max_social_mix_input"/>
                </NumberInput>
              </AreaInputWrapper>
            </HStack>
          </FormControl>
          <FormControl display="flex" isDisabled={isLoading}>
            <SearchCheckbox name="socialMix"/>
            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
              {t('sidebar.search_plots.social_mix_remove')}
            </FormLabel>
          </FormControl>
        </>)}
    </Stack>);
};
export default RulesSearch;
