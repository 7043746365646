import { useEffect, useRef } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, Checkbox, CloseButton, HStack, Icon, Input, Portal, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import PopoverMenu from 'components/map/Toolbar/PopoverMenu';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useMap, useRefMap } from 'hooks/contexts/useMapContext';
import useUserDrawing from 'hooks/crm/useUserDrawing';
import PencilIcon from 'images/icons/Pencil';
import { useDrawing } from 'utils/drawing';
import { filtersKey } from 'utils/filters';
const DrawingTool = () => {
    const { t } = useTranslation();
    const map = useMap();
    const refMap = useRefMap();
    const { trackEvent } = useAnalytics();
    const isFilterActive = useIsFilterActive();
    const toggleFilter = useToggleFilter();
    const keyDownListener = useRef(null);
    const { drawingMode, hasCurrentDrawing, activeUserDrawing, saveActiveDrawing, deleteActiveDrawing, updateDrawingText, isFreeDrawingActive, toggleDrawingMode, } = useDrawing(map);
    const { userDrawings } = useUserDrawing();
    const hasTextBox = (drawingMode === 'free_text' ||
        (drawingMode === 'free_edit' && activeUserDrawing?.note)) &&
        hasCurrentDrawing;
    const hasButtons = hasCurrentDrawing &&
        (drawingMode === 'free_text' || drawingMode === 'free_edit');
    const { handleSubmit, register, formState: { isSubmitting }, getValues, setValue, setFocus, reset, } = useForm();
    const { ref: textInputCallbackRef, onChange: onChangeText, ...registerText } = register('text');
    const { ref: showTextMarkerInputCallbackRef, onChange: onChangeShowTextMarker, ...registerShowTextMarker } = register('showTextMarker');
    useEffect(() => {
        const note = activeUserDrawing?.note;
        setValue('text', note || '');
        setValue('showTextMarker', activeUserDrawing?.showTextMarker || false);
        if (note) {
            setFocus('text', { shouldSelect: true });
        }
    }, [activeUserDrawing]);
    useEffect(() => {
        if (hasTextBox) {
            setFocus('text', { shouldSelect: true });
        }
    }, [hasTextBox]);
    useEffect(() => {
        if (!userDrawings?.length) {
            toggleFilter(filtersKey.USER_DRAWINGS, false);
        }
    }, [userDrawings]);
    // Toggle mode off when pressing escape
    useEffect(() => {
        document.removeEventListener('keydown', keyDownListener.current);
        keyDownListener.current = (e) => {
            if (drawingMode !== null && e.key === 'Escape') {
                toggleDrawingMode(null);
            }
            if (drawingMode === 'free_edit' && e.key === 'Delete') {
                deleteActiveDrawing();
            }
        };
        document.addEventListener('keydown', keyDownListener.current);
        return () => {
            if (keyDownListener.current) {
                document.removeEventListener('keydown', keyDownListener.current);
            }
        };
    }, [drawingMode, deleteActiveDrawing]);
    const getMenuItems = () => {
        const items = [
            {
                label: t('toolbar.free_draw.see_drawing'),
                isBold: true,
                isActive: isFilterActive(filtersKey.USER_DRAWINGS),
                handleClick: () => {
                    saveActiveDrawing();
                    reset({ text: '', showTextMarker: false });
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Afficher mes dessins',
                    });
                    toggleFilter(filtersKey.USER_DRAWINGS);
                },
            },
            {
                label: t('toolbar.free_draw.polygon'),
                isActive: drawingMode === 'free_polygon',
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Dessiner un polygone sur la carte',
                    });
                    toggleDrawingMode('free_polygon');
                    toggleFilter(filtersKey.USER_DRAWINGS, true);
                },
            },
            {
                label: t('toolbar.free_draw.polyline'),
                isActive: drawingMode === 'free_line',
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Dessiner une ligne sur la carte',
                    });
                    toggleDrawingMode('free_line');
                    toggleFilter(filtersKey.USER_DRAWINGS, true);
                },
            },
            {
                label: t('toolbar.free_draw.point'),
                isActive: drawingMode === 'free_point',
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Dessiner un point sur la carte',
                    });
                    toggleDrawingMode('free_point');
                    toggleFilter(filtersKey.USER_DRAWINGS, true);
                },
            },
            {
                label: t('toolbar.free_draw.text'),
                isActive: drawingMode === 'free_text',
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Ecrire sur la carte',
                    });
                    toggleDrawingMode('free_text');
                    toggleFilter(filtersKey.USER_DRAWINGS, true);
                },
            },
        ];
        if (userDrawings?.length === 0) {
            // On supprime le premier element du tableau car pas de dessin
            items.shift();
        }
        return items;
    };
    return (<>
      <PopoverMenu id="drawing" width="100%" label={<HStack justifyContent="center" alignItems="center">
            <Icon as={PencilIcon} width={4} height={4} fill="currentColor"/>
            <Text>{t('toolbar.free_draw.title')}</Text>
          </HStack>} isActive={isFreeDrawingActive} showCheck={true} menuItems={getMenuItems()}/>
      {isFreeDrawingActive && (<Portal containerRef={refMap}>
          <Card className="user-drawing-actions">
            <CardBody padding={1}>
              <HStack justify="space-between" alignItems="start">
                <Box as="form" onSubmit={handleSubmit(() => {
                saveActiveDrawing();
                reset({ text: '', showTextMarker: false });
            })}>
                  <VStack spacing={3}>
                    {hasTextBox && (<Box width="100%">
                        <Input {...registerText} ref={textInputCallbackRef} type="text" size="sm" onFocus={(e) => e.target.select()} defaultValue={t('toolbar.free_draw.placeholder')} onChange={(e) => {
                    onChangeText(e);
                    const value = e.target.value;
                    if (!value || value.trim() === '') {
                        return;
                    }
                    updateDrawingText(value, getValues('showTextMarker'));
                }}/>
                        <Checkbox {...registerShowTextMarker} ref={showTextMarkerInputCallbackRef} size="md" marginTop={2} onChange={(e) => {
                    onChangeShowTextMarker(e);
                    const value = e.target.checked;
                    updateDrawingText(getValues('text'), value);
                }}>
                          <Text fontSize="sm">
                            {t('toolbar.free_draw.actions.show_on_marker')}
                          </Text>
                        </Checkbox>
                      </Box>)}
                    {!hasCurrentDrawing && drawingMode !== 'free_edit' && (<Text fontSize="sm">
                        {t('toolbar.free_draw.actions.title_start_drawing')}
                      </Text>)}
                    {!activeUserDrawing && drawingMode === 'free_edit' && (<Text fontSize="sm">
                        {t('toolbar.free_draw.actions.title_no_selection')}
                      </Text>)}
                    {hasCurrentDrawing &&
                ['free_polygon', 'free_line'].includes(drawingMode) && (<Text fontSize="sm">
                          {t('toolbar.free_draw.actions.double_click_to_save')}
                        </Text>)}
                    {hasButtons && (<HStack justify="space-between" width="100%">
                        <Button variant="primary" size="xs" type="submit" leftIcon={<CheckIcon />} isDisabled={isSubmitting}>
                          {t('toolbar.free_draw.actions.save')}
                        </Button>
                        {!!activeUserDrawing && (<Button variant="outlineDelete" size="xs" onClick={() => {
                        deleteActiveDrawing();
                    }} leftIcon={<DeleteIcon />}>
                            {t('toolbar.free_draw.actions.delete')}
                          </Button>)}
                        <Box>
                          <CloseButton isDisabled={isSubmitting} size="sm" type="submit" title={t('toolbar.free_draw.actions.close')}/>
                        </Box>
                      </HStack>)}
                  </VStack>
                </Box>
              </HStack>
            </CardBody>
          </Card>
        </Portal>)}
    </>);
};
export default DrawingTool;
