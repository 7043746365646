import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import SearchInputWrapper from 'components/inputs/SearchInputWrapper';
import { useCloseAllAccordions, useIsAllOpened, useOpenAllAccordions, useSearchLayer, useSetSearchLayer, } from 'hooks/contexts/useFiltersAccordionContext';
import { useUpdateFiltersSearch } from 'hooks/contexts/useFiltersContext';
const FiltersSearchInput = ({ isFilterSideBarLoading, isMainLayersLoading, }) => {
    const isAllOpened = useIsAllOpened();
    const openAllAccordions = useOpenAllAccordions();
    const closeAllAccordions = useCloseAllAccordions();
    const searchLayer = useSearchLayer();
    const setSearchLayer = useSetSearchLayer();
    const { t } = useTranslation();
    const inputSearchRef = useRef(null);
    const updateFiltersSearch = useUpdateFiltersSearch();
    const debouncedSearchChange = useMemo(() => debounce(updateFiltersSearch, 600), []);
    return (<SearchInputWrapper>
      <Input ref={inputSearchRef} disabled={isFilterSideBarLoading || isMainLayersLoading} onChange={(e) => {
            const value = e.target.value;
            const formattedValue = value.trim() !== '' ? value.trim() : null;
            debouncedSearchChange(formattedValue);
            // On ouvre tout si nécessaire, seulement pour le premier caractère (pour éviter d'ouvrir à chaque caractère)
            if (formattedValue && !isAllOpened && formattedValue.length === 1) {
                openAllAccordions();
            }
            if (formattedValue === null && !isAllOpened) {
                closeAllAccordions(true);
            }
            setSearchLayer(value);
        }} type="search" width="full" placeholder={t('sidebar.search_layers.placeholder')} value={searchLayer} data-cy="filters_search_input"/>
    </SearchInputWrapper>);
};
export default FiltersSearchInput;
