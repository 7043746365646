import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import SelectInput from 'components/inputs/SelectInput';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { BUILDING_PURPOSE_KEY } from 'utils/plotsSearch';
export const ELEVATION_OPTIONS_KEY = {
    1: 'sidebar.search_plots.possible_elevations_option.level1',
    2: 'sidebar.search_plots.possible_elevations_option.level2',
    3: 'sidebar.search_plots.possible_elevations_option.level3',
};
export const actualBuildingSearchFields = [
    'buildingFloorArea',
    'currentBuildingFootprint',
    'numberOfLevels',
    'currentBuildingFootprint',
    'buildingPurpose',
    'constructionYear',
    'eaveHeight',
];
export const getPlotSelectOptions = (optionDetails, fieldName, t, withSorting = false) => {
    const options = [];
    for (const option in optionDetails) {
        options.push({
            value: option.replace('#', ''),
            label: t(`sidebar.search_plots.${fieldName}_options.${optionDetails[option]}`),
        });
    }
    if (withSorting)
        options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
};
export const getSelectedOptionsLabel = (optionDetails, fieldName, option, t) => {
    return t(`sidebar.search_plots.${fieldName}_options.${optionDetails[option]}`);
};
const ActualBuildingSearch = ({ isLoading, hasSearchField, }) => {
    const { t } = useTranslation();
    const { register } = useFormContext();
    const hasSearchFields = (fieldNames) => fieldNames.some(hasSearchField);
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2}>
      {hasSearchFields([
            'buildingFloorArea',
            'currentBuildingFootprint',
            'numberOfLevels',
            'currentBuildingFootprint',
            'buildingPurpose',
            'constructionYear',
            'eaveHeight',
        ]) && (<>
          {hasSearchField('buildingFloorArea') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.building_floor_area')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Min" type="number" {...register('buildingFloorAreaMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="m²">
                  <Input size="sm" placeholder="Max" type="number" {...register('buildingFloorAreaMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('numberOfLevels') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.number_of_levels')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Min" type="number" {...register('numberOfLevelsMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Max" type="number" {...register('numberOfLevelsMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('currentBuildingFootprint') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.current_building_footprint')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="%">
                  <Input size="sm" placeholder="Min" type="number" {...register('currentBuildingFootprintMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="%">
                  <Input size="sm" placeholder="Max" type="number" {...register('currentBuildingFootprintMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('buildingPurpose') && (<SelectInput size="sm" isLoading={isLoading} name="buildingPurpose" label={t('sidebar.search_plots.building_purpose')} options={getPlotSelectOptions(BUILDING_PURPOSE_KEY, 'building_purpose', t)}/>)}

          {hasSearchField('constructionYear') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.construction_year')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Min" type="number" {...register('constructionYearMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper>
                  <Input size="sm" placeholder="Max" type="number" {...register('constructionYearMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}

          {hasSearchField('eaveHeight') && (<FormControl isDisabled={isLoading}>
              <FormLabel marginBottom={1}>
                {t('sidebar.search_plots.eave_height')}
              </FormLabel>
              <HStack>
                <AreaInputWrapper rightElementLabel="m">
                  <Input size="sm" placeholder="Min" type="number" {...register('eaveHeightMin')}/>
                </AreaInputWrapper>
                <AreaInputWrapper rightElementLabel="m">
                  <Input size="sm" placeholder="Max" type="number" {...register('eaveHeightMax')}/>
                </AreaInputWrapper>
              </HStack>
            </FormControl>)}
        </>)}
    </Stack>);
};
export default ActualBuildingSearch;
