import { useEffect } from 'react';
import * as React from 'react';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import StaffItem from 'components/tabs/plots/PlotSections/staff/StaffItem';
const StaffModal = ({ occupant, owner, triggerItem, staff, setContactToCreate, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { trackEvent } = useAnalytics();
    useEffect(() => {
        if (isOpen) {
            trackEvent({
                category: ANALYTICS_PLOT_TAB,
                action: 'Occupant - Voir les dirigeants',
            });
        }
    }, [isOpen]);
    return (<>
      {triggerItem(onOpen)}
      <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered variant="wide">
        <ModalOverlay />
        <ModalContent padding={5} alignItems="center">
          <ModalCloseButton />
          {staff.map((item, index) => {
            return (<StaffItem key={index} occupant={occupant} owner={owner} staff={item} index={index} onClick={onClose} setContactToCreate={setContactToCreate}/>);
        })}
        </ModalContent>
      </Modal>
    </>);
};
export default StaffModal;
