import * as React from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Divider, HStack, Icon, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Spinner, Text, useDisclosure, } from '@chakra-ui/react';
import { useOutsideClick } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TABLE } from 'analytics/useAnalytics';
import moment from 'moment';
import ResetButton from 'components/buttons/ResetButton';
import FilterIcon from 'images/icons/Filter';
import TriOnIcon from 'images/icons/TriOn';
import { STANDARD_DATE_FORMAT, STANDARD_HOURS_FORMAT } from 'utils/formatDate';
import FilterForm from './FilterForm';
const isDate = (date) => {
    const parsedDate = Date.parse(date);
    return (!isNaN(parsedDate) &&
        (moment(date, STANDARD_DATE_FORMAT, true).isValid() ||
            moment(date, `${STANDARD_DATE_FORMAT} ${STANDARD_HOURS_FORMAT}`, true).isValid()));
};
export const displayValue = (value) => {
    if (!value) {
        return '';
    }
    if (typeof value === 'string' && isDate(value)) {
        return new Date(value).toLocaleDateString('fr');
    }
    return value;
};
export const FILTER_NUMBER_TYPE = 'number';
export const FILTER_STRING_TYPE = 'string';
export const FILTER_DATE_TYPE = 'date';
const FilterDropdown = ({ column, filters = [], canFilter, canSort, currentSorting, isLoading, analytics, filterFn, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [query, setQuery] = useState(null);
    const { trackEvent } = useAnalytics();
    const uniqueValues = Array.from(column.getFacetedUniqueValues()?.keys() ?? []);
    const popoverRef = useRef(null);
    useOutsideClick({
        ref: popoverRef,
        handler: onClose,
    });
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    useEffect(() => {
        setIsOpenPopover(isOpen);
    }, [isOpen]); // Réagit uniquement lorsque isOpen change
    const firstValue = uniqueValues.find((value) => value !== null);
    const filterType = typeof firstValue === 'number' &&
        !uniqueValues.find((value) => typeof value !== 'number')
        ? FILTER_NUMBER_TYPE
        : isDate(firstValue)
            ? FILTER_DATE_TYPE
            : FILTER_STRING_TYPE;
    return (<>
      <Popover isLazy isOpen={isOpen}>
        <PopoverTrigger>
          <Box onClick={() => {
            if (!isOpenPopover) {
                //setIsOpenPopover(true)
                onOpen();
                if (analytics) {
                    trackEvent({
                        category: ANALYTICS_TABLE,
                        action: `${analytics?.tableName} ${analytics?.getColumnName(column.id)}`,
                    });
                }
            }
            else {
                setIsOpenPopover(false);
            }
        }}>
            {currentSorting === 'desc' && (<IconButton backgroundColor="blue.200" color="blue.500" icon={<TriOnIcon width={14} height={14} fill="currentColor" style={{
                    transform: 'rotate(180deg)',
                }}/>} aria-label={'btn-filter'} size="xs" marginLeft={2}/>)}
            {currentSorting === 'asc' && (<IconButton backgroundColor="blue.200" color="blue.500" icon={<TriOnIcon width={14} height={14} fill="currentColor"/>} aria-label={'btn-filter'} size="xs" marginLeft={2}/>)}
            {!currentSorting && (<IconButton backgroundColor="blue.200" color="blue.500" icon={<FilterIcon width={14} height={14} fill="currentColor"/>} aria-label={'btn-filter'} size="xs" marginLeft={2}/>)}
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent ref={popoverRef} borderBottomRadius="md" backgroundColor="colorMode.background900" flexDirection="column" boxShadow="lg">
            <PopoverBody backgroundColor="colorMode.base" padding="0">
              {isLoading && (<Center margin={4}>
                  <Spinner />
                </Center>)}
              {!isLoading && canSort && (<>
                  <HStack padding={4} flex={1} onClick={() => {
                column.toggleSorting(false);
                if (analytics) {
                    trackEvent({
                        category: ANALYTICS_TABLE,
                        action: `${analytics?.tableName} croissant ${analytics?.getColumnName(column.id)}`,
                    });
                }
            }} cursor="pointer" color="colorMode.revertBase" _hover={{
                color: 'white',
                backgroundColor: 'blue.500',
            }}>
                    <Icon as={TriOnIcon} backgroundColor="blue.200" fill="blue.500" borderRadius={8} width={6} height={6} padding={1}/>
                    <Text textStyle="small" color="currentColor" fontWeight={500}>
                      {t('table.order_desc')}
                    </Text>
                  </HStack>
                  <Divider />
                  <HStack padding={4} flex={1} onClick={() => {
                column.toggleSorting(true);
                if (analytics) {
                    trackEvent({
                        category: ANALYTICS_TABLE,
                        action: `${analytics?.tableName} décroissant ${analytics?.getColumnName(column.id)}`,
                    });
                }
            }} cursor="pointer" color="colorMode.revertBase" _hover={{
                color: 'white',
                backgroundColor: 'blue.500',
            }}>
                    <Icon as={TriOnIcon} backgroundColor="blue.200" borderRadius={8} fill="blue.500" style={{
                transform: 'rotate(180deg)',
            }} width={6} height={6} padding={1}/>
                    <Text textStyle="small" color="currentColor" fontWeight={500}>
                      {t('table.order_asc')}
                    </Text>
                  </HStack>
                  <Divider />
                </>)}

              {!isLoading && canFilter && (<>
                  <HStack padding={4} justifyContent="space-between">
                    <HStack color="blue.500">
                      <Icon as={FilterIcon} backgroundColor="blue.200" fill="currentColor" borderRadius={8} width={6} height={6} padding={1}/>
                      <Text textStyle="small" color="colorMode.revertBase">
                        {t('table.filter')}
                        {FILTER_STRING_TYPE === filterType && (<Text marginLeft={2} as="span" backgroundColor="blue.500" color="white" paddingX={3} paddingY={1} borderRadius="full" textStyle="small" fontWeight={700}>
                            {filters.filter((f) => !!f && f !== '').length}
                          </Text>)}
                      </Text>
                    </HStack>

                    <HStack>
                      <ResetButton label={t('table.clear_filter')} onClick={() => {
                column.setFilterValue([]);
                setQuery(null);
            }} marginBottom={3}/>
                    </HStack>
                  </HStack>

                  <FilterForm column={column} query={query} setQuery={setQuery} canFilter={canFilter} filters={filters} filterType={filterType} filterFn={filterFn} analytics={analytics}/>
                </>)}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {canFilter && Array.isArray(filters) && filters.length > 0 && (<Text as="span" backgroundColor="blue.500" color="white" marginLeft={2} paddingX={3} paddingY={1} textStyle="small" borderRadius="full" fontWeight={700}>
          {filterType === FILTER_STRING_TYPE && filters.length}
          {(filterType === FILTER_NUMBER_TYPE ||
                filterType === FILTER_DATE_TYPE) &&
                1}
        </Text>)}
    </>);
};
export default memo(FilterDropdown);
