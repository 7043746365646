import { useCallback, useMemo } from 'react';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import { useSelectedProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { filtersKey } from 'utils/filters';
const useProjectPlotsLayer = ({ preSelectedProject = null, }) => {
    const baseSelectedProject = useSelectedProject();
    const selectedProject = preSelectedProject ?? baseSelectedProject;
    const { projectPlots } = useProject(selectedProject?.townId, selectedProject?.id);
    const openPlotFromMap = useOpenPlotFromMap();
    const openPlotModal = useOpenPlotModal();
    const providedData = useMemo(() => {
        return projectPlots ? Object.values(projectPlots) : null;
    }, [projectPlots]);
    const handleClick = useCallback((item) => {
        if (!preSelectedProject) {
            openPlotFromMap(item);
        }
        else {
            openPlotModal({
                townshipId: item.townId,
                plotId: item.id,
            });
        }
    }, [preSelectedProject]);
    // La gestion de l'affichage en fonction du zoom est directement gérer en css
    // (voir #googleMap .zoom-0 .marker-label-plot)
    createLayersHook({
        providedData,
        onClick: handleClick,
    })(filtersKey.PROJECT_PLOTS);
};
const ProjectPlotsLayer = ({ selectedProject = null, }) => {
    useProjectPlotsLayer({ preSelectedProject: selectedProject });
    return null;
};
export default ProjectPlotsLayer;
