import * as React from 'react';
import { ColorPicker, ColorService, useColor, } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, HStack, Input, Spinner, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import ArrowLeftIcon from 'images/icons/ArrowLeft';
import 'react-color-palette/css';
const PersonalizationStatus = ({ personalization, analyticsActionType, isLoading, statusDefault, currentPersonalization, setCurrentPersonalization, setPersonalization, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const [selectedColor, setSelectedColor] = useColor('#561ecb');
    const chooseColor = (index, color) => {
        if (currentPersonalization === index) {
            setCurrentPersonalization(null);
        }
        else {
            setCurrentPersonalization(index);
        }
        const rgb = ColorService.hex2rgb(color);
        setSelectedColor({
            hex: color,
            rgb: ColorService.hex2rgb(color),
            hsv: ColorService.rgb2hsv(rgb),
        });
    };
    const setColor = (color) => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Changement de la couleur ' + analyticsActionType,
        });
        const perso = [...personalization];
        perso[currentPersonalization].color = color.hex;
        setSelectedColor(color);
        setPersonalization(perso);
    };
    const setLabel = (event, index) => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Changement du label ' + analyticsActionType,
        });
        const perso = [...personalization];
        perso[index].label = event.target.value;
        setPersonalization(perso);
    };
    return (<VStack spacing={1} marginBottom={2}>
      {isLoading ? (<Spinner />) : (personalization.map((perso, index) => {
            return (<HStack spacing={2} key={index} width="420px">
              <Input name="name[]" size="sm" onChange={(input) => setLabel(input, index)} value={perso.label} maxLength={64} width="250px"/>
              <HStack cursor="pointer" position="relative">
                <Box backgroundColor={perso.color} minWidth="25px" minHeight="25px" maxWidth="25px" maxHeight="25px" onClick={() => chooseColor(index, perso.color)} border={currentPersonalization === index
                    ? '2px solid black'
                    : 'none'}/>
                {perso.statusId === statusDefault && (<Text fontSize="12px">
                    {t('tabs.personalization.section.plot_status.default')}
                  </Text>)}
                <Box display={currentPersonalization === index ? 'block' : 'none'} width="350px" position="absolute" top="-2px" left="45px" boxShadow="md" borderRadius="md" backgroundColor="#FFFFFF" padding={2}>
                  <Box position="relative">
                    <Box position="absolute" right="-20px" top="-18px" backgroundColor="#FFFFFF" padding="6px" boxShadow="3px 1px 5px rgba(0,0,0,0.1)" borderRadius="50%" lineHeight="0.55">
                      <CloseIcon fontSize="12px" onClick={() => setCurrentPersonalization(null)} _hover={{ color: 'colorMode.font900' }}/>
                    </Box>
                    <Box position="absolute" left="-29px" top="-6px">
                      <ArrowLeftIcon />
                    </Box>
                    <ColorPicker height={150} hideInput={['hsv']} hideAlpha={true} color={selectedColor} onChange={setSelectedColor} onChangeComplete={setColor}/>
                  </Box>
                </Box>
              </HStack>
            </HStack>);
        }))}
    </VStack>);
};
export default PersonalizationStatus;
