import { PERSONALIZATION_KEY } from 'hooks/personalization/usePersonalization';
// -------------- PLOT ----------------
export const getPlotStatusLabel = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return (personalization.plot.plotStatusValuesWithNone[statusId]?.label ?? 'Aucun');
};
export const getPlotCodeFromStatus = (status) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return Object.keys(personalization.plot.plotStatusValuesWithNone).find((key) => personalization[key].label === status);
};
export const hasPlotStatus = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return !!personalization.plot.plotStatusValues[statusId];
};
export const getPlotStatusColor = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusValues[statusId]?.color ?? '#DDDDDD';
};
export const getPlotStatusOption = () => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusOptions.sort((a, b) => a.legendOrder > b.legendOrder ? 1 : -1);
};
export const getPlotStatusOrder = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.plot.plotStatusValues[statusId]?.legendOrder ?? 0;
};
// -------------- PROJECT ----------------
export const getProjectStatus = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.project.projectStatusValuesWithNone[statusId];
};
export const getProjectStatusLabel = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return (personalization.project.projectStatusValuesWithNone[statusId]?.label ??
        'Aucun');
};
export const getProjectStatusColor = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return (personalization.project.projectStatusValues[statusId]?.color ?? '#DDDDDD');
};
export const getProjectStatusOption = () => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.project.projectStatusOptions.sort((a, b) => a.legendOrder > b.legendOrder ? 1 : -1);
};
export const getProjetStatusOrder = (statusId) => {
    const personalization = JSON.parse(localStorage.getItem(PERSONALIZATION_KEY));
    return personalization.project.projectStatusValues[statusId]?.legendOrder ?? 0;
};
