import * as React from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, } from '@chakra-ui/react';
import { format } from 'date-fns';
import NotRequired from './NotRequired';
import 'react-datepicker/dist/react-datepicker.css';
const TIME_FORMAT = 'H:mm';
const DATE_FORMAT = 'dd/MM/yyyy';
const DATE_TIME_FORMAT = 'dd/MM/yyyy H:mm';
const DateTimePickerInput = ({ label, name, required, width = 'full', size = 'md', withTime = false, timeOnly = false, maxDate = null, minDate = new Date(), isClearable = false, onChange = () => { }, direction = 'column', placeholder = null, popperPlacement = 'bottom', }) => {
    const { setValue, watch, getFieldState } = useFormContext();
    const { t, i18n } = useTranslation();
    const { error } = getFieldState(name);
    const showTime = withTime || timeOnly;
    const dateFormat = showTime
        ? timeOnly
            ? TIME_FORMAT
            : DATE_TIME_FORMAT
        : DATE_FORMAT;
    const placeholderInput = placeholder ?? t(`global.fields.${timeOnly ? 'time' : 'date'}.placeholder`);
    return (<FormControl display={direction === 'row' ? 'flex' : 'block'} alignItems="center" width={width} isInvalid={Boolean(error)}>
      {label && (<FormLabel>
          {label}
          {!required && <NotRequired />}
        </FormLabel>)}
      <DatePicker selected={watch(name)} locale={i18n.language === 'fr' ? 'fr' : undefined} minDate={minDate} onChange={(date) => {
            setValue(name, date);
            onChange && onChange(date);
        }} showTimeSelect={showTime} showTimeSelectOnly={timeOnly} timeIntervals={15} timeCaption={t('global.fields.date.time_caption')} dateFormat={dateFormat} maxDate={maxDate} isClearable={isClearable} popperPlacement={popperPlacement} customInput={<InputGroup size={size}>
            <Input placeholder={placeholderInput} cursor="pointer" value={watch(name) ? format(watch(name), dateFormat) : ''} readOnly/>
          </InputGroup>}/>
      {Boolean(error) && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>);
};
export default DateTimePickerInput;
